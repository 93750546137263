import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/close-icon.svg'


const _hoisted_1 = { class: "popup-modal" }
const _hoisted_2 = { class: "popup-modal-content rounded-xl max-h-[85vh] overflow-y-auto" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 mx-auto" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = { class: "flex justify-between" }
const _hoisted_10 = { class: "inline-flex items-center space-x-2" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "inline-flex items-center space-x-2" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "inline-flex items-center space-x-2" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_17 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_18 = {
  id: "file",
  ref: "foodMenuFile",
  type: "file",
  accept: "application/pdf",
  placeholder: "Choose a file or drop it here...",
  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
}
const _hoisted_19 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_20 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_21 = {
  id: "file",
  ref: "drinksMenuFile",
  type: "file",
  accept: "application/pdf",
  placeholder: "Choose a file or drop it here...",
  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
}
const _hoisted_22 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_23 = {
  key: 2,
  class: "mb-6"
}
const _hoisted_24 = {
  id: "file",
  ref: "foodDrinksMenuFile",
  type: "file",
  accept: "application/pdf",
  placeholder: "Choose a file or drop it here...",
  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
}
const _hoisted_25 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_26 = {
  key: 3,
  class: "w-full bg-[#424242] dark:bg-neutral-600"
}
const _hoisted_27 = { class: "w-full flex lg:justify-end justify-start items-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "float-right"
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "w-[0.8rem] mt-1",
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Add Food or Drink Menu ", -1)),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Realm *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                id: "realm",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.realm) = $event)),
                required: ""
              }, [
                _cache[10] || (_cache[10] = _createElementVNode("option", { value: "" }, "Select Realm", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.realmslist, (realmItem) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: realmItem.id,
                    value: realmItem.id
                  }, _toDisplayString(realmItem.name), 9, _hoisted_6))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.realm]
              ]),
              (_ctx.missingFields.includes('Realm'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, " Realm field is required "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.foodChecked) = $event)),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCheckboxChange('foodChecked'))),
                    disabled: _ctx.foodDrinksChecked,
                    class: "form-checkbox text-indigo-600 h-5 w-5"
                  }, null, 40, _hoisted_11), [
                    [_vModelCheckbox, _ctx.foodChecked]
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-gray-700" }, "Food Menu", -1))
                ]),
                _createElementVNode("label", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.drinksChecked) = $event)),
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleCheckboxChange('drinksChecked'))),
                    disabled: _ctx.foodDrinksChecked,
                    class: "form-checkbox text-indigo-600 h-5 w-5"
                  }, null, 40, _hoisted_13), [
                    [_vModelCheckbox, _ctx.drinksChecked]
                  ]),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-gray-700" }, "Drinks Menu", -1))
                ]),
                _createElementVNode("label", _hoisted_14, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.foodDrinksChecked) = $event)),
                    onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleCheckboxChange('foodDrinksChecked'))),
                    disabled: _ctx.foodChecked || _ctx.drinksChecked,
                    class: "form-checkbox text-indigo-600 h-5 w-5"
                  }, null, 40, _hoisted_15), [
                    [_vModelCheckbox, _ctx.foodDrinksChecked]
                  ]),
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-gray-700" }, "Food & Drinks Menu", -1))
                ])
              ]),
              (_ctx.missingFields.includes('Menu Type'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_16, " Menu Type field is required "))
                : _createCommentVNode("", true)
            ]),
            (_ctx.foodChecked && !_ctx.foodDrinksChecked)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", {
                    for: "small-input",
                    class: "block mb-2 text-sm font-medium text-gray-90"
                  }, "Food Menu File *", -1)),
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Upload a PDF file of the food menu that will be accessed or downloaded ", -1)),
                  _createElementVNode("input", _hoisted_18, null, 512),
                  (_ctx.missingFields.includes('Food Menu File'))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_19, " Food Menu File is required "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.drinksChecked && !_ctx.foodDrinksChecked)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", {
                    for: "small-input",
                    class: "block mb-2 text-sm font-medium text-gray-90"
                  }, "Drinks Menu File *", -1)),
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Upload a PDF file of the drinks menu that will be accessed or downloaded ", -1)),
                  _createElementVNode("input", _hoisted_21, null, 512),
                  (_ctx.missingFields.includes('Drinks Menu File'))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_22, " Drinks Menu File is required "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.foodDrinksChecked)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _cache[19] || (_cache[19] = _createElementVNode("label", {
                    for: "small-input",
                    class: "block mb-2 text-sm font-medium text-gray-90"
                  }, "Food & Drinks Menu File *", -1)),
                  _cache[20] || (_cache[20] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Upload a PDF file of the food & drinks menu that will be accessed or downloaded ", -1)),
                  _createElementVNode("input", _hoisted_24, null, 512),
                  (_ctx.missingFields.includes('Food & Drinks Menu File'))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_25, " Food & Drinks Menu File is required "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createElementVNode("div", {
                    class: "bg-primary p-0.5 text-center text-xs font-medium leading-none text-white animated",
                    style: _normalizeStyle({ width: `${_ctx.uploadProgress}%` })
                  }, _toDisplayString(_ctx.uploadProgress) + "/% ", 5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.uploadMenu && _ctx.uploadMenu(...args))),
                type: "button",
                class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center"
              }, [
                _cache[21] || (_cache[21] = _createTextVNode(" Upload ")),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.loading })
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}