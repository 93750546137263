<template>
  <div class="w-full lg:flex justify-between">
    <div class="lg:w-[50%] h-100vh md:overflow-auto lg:py-10">
      <div class="flex flex-col justify-center items-center relative">
        <div class="max-form w-full lg:py-0 py-10">
          <img
            src="../../assets/ared-logo.svg"
            class="w-[8rem] lg:hidden block m-auto mb-5"
            alt="right view"
          />
          <div class="bg-white rounded-[0.432rem] lg:p-0 p-4">
            <h1 class="font-['poppins'] font-bold text-[26px] text-center">
              Sign Up
            </h1>
            <h1
              class="font-poppins lg:font-bold font-normal lg:text-[19px] text-[15px] mt-2 tracking-widest text-primary text-center"
            >
              ARED SME Platform
            </h1>
            <form class="mt-10">
              <TextField
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                name="smeName"
                placeholder="Name"
                type="text"
                v-model="v$.smeName.$model"
                :showErrors="v$.smeName.$errors.length ? true : false"
                :errors="v$.smeName.$errors"
              />

              <small v-if="responseErrors.name" class="text-[#ff0000] mb-5">{{
                responseErrors.name[0]
              }}</small>

              <TextField
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                name="smeCity"
                placeholder="City"
                type="text"
                v-model="v$.city.$model"
                :showErrors="v$.city.$errors.length ? true : false"
                :errors="v$.city.$errors"
              />

              <small
                v-if="responseErrors.location"
                class="text-[#ff0000] mb-5"
                >{{ responseErrors.location[0] }}</small
              >

              <SelectField
                name="country"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                label="Select Country"
                v-model="v$.country.$model"
                :showErrors="v$.country.$errors.length ? true : false"
                :errors="v$.country.$errors"
                :data="countries"
              />

              <TextField
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                name="smeAdminName"
                placeholder="Admin Name"
                type="text"
                v-model="v$.adminName.$model"
                :showErrors="v$.adminName.$errors.length ? true : false"
                :errors="v$.adminName.$errors"
              />

              <small
                v-if="responseErrors.admin_name"
                class="text-[#ff0000] mb-5"
                >{{ responseErrors.admin_name[0] }}</small
              >

              <TextField
                name="smeEmail"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Admin Email"
                type="email"
                v-model="v$.email.$model"
                :showErrors="v$.email.$errors.length ? true : false"
                :errors="v$.email.$errors"
              />

              <small
                v-if="responseErrors.admin_email"
                class="text-[#ff0000] mb-5"
                >{{ responseErrors.admin_email[0] }}</small
              >

              <TextField
                name="phone"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Phone Number"
                type="text"
                v-model="v$.phone.$model"
                :showErrors="v$.phone.$errors.length ? true : false"
                :errors="v$.phone.$errors"
              />

              <!-- <vue-tel-input v-model="v$.phone.$model"></vue-tel-input> -->
              <small
                v-if="responseErrors.admin_phone"
                class="text-[#ff0000] mb-5"
                >{{ responseErrors.admin_phone[0] }}</small
              >

              <small
                v-if="responseErrors.admin_phone"
                class="text-[#ff0000] mb-5"
                >{{ responseErrors.admin_phone[0] }}</small
              >

              <SelectField
                name="type"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                label="Select SME Type"
                v-model="v$.smeType.$model"
                :showErrors="v$.smeType.$errors.length ? true : false"
                :errors="v$.smeType.$errors"
                :data="smeTypes"
              />

              <SelectField
                name="partner"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                label="Select Partner"
                v-model="v$.partner.$model"
                :showErrors="v$.partner.$errors.length ? true : false"
                :errors="v$.partner.$errors"
                :data="partners"
                @value-selected="onSelectedPartner"
              />

              <SelectField
                name="subscription"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                label="Select Subscription"
                v-model="v$.subscription.$model"
                :showErrors="v$.subscription.$errors.length ? true : false"
                :errors="v$.subscription.$errors"
                :data="subscriptions"
              />
              <p class="text-xs text-primary mb-6">
                Subscription will be applied after 30 days of free trial
              </p>
              <button
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
                @click="handleSignup"
                :disabled="v$.$invalid"
                v-if="!loading"
              >
                Register
              </button>
              <button
                v-else
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
              >
                Processing...
              </button>
              <div class="w-full flex justify-center items-center my-6">
                <span
                  class="font-poppins font-normal text-[#838282] text-[12.9px]"
                  >Already have an account?
                  <button
                    type="button"
                    class="text-success font-semibold lg:text-[#4D99FF] text-[#4E8D6E]"
                    @click="handleSignIn"
                  >
                    Sign In
                  </button></span
                >
              </div>
            </form>
          </div>
        </div>

        <!-- form footer -->
        <div
          class="max-form w-full bottom-5 lg:flex hidden justify-between items-center"
        >
          <!-- <div class="flex">
            <img
              src="../../assets/usa-flag.png"
              class="w-[20px] h-[20px] rounded-full mr-1"
              alt=""
            />
            <select class="border-none text-[#464E5F] text-[12px] font-medium">
              <option value="">English</option>
            </select>
          </div> -->

          <ul class="flex">
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem] mr-4"
                ><a href="javascript:void(0)">Terms</a></span
              >
            </li>
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem] mr-4"
                ><a href="javascript:void(0)">Plans</a></span
              >
            </li>
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem]"
                ><a href="javascript:void(0)">Contact Us</a></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="lg:w-[50%]">
      <div class="flex justify-center items-center">
        <auth-right-image />
      </div>
      <SmeSnackbar
        v-if="showSnackbar"
        message="Successfully registered as sme, kindly use credentials sent to your email to login to your dashboard account."
        @buttonClick="handleCloseSnackbar"
      />
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import { defineComponent, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';

import instance from '@/axios-interceptor';
import countriesData from '../../helpers/stub_data/countries';
import TextField from '../../components/reusable/TextField.vue';
import SmeSnackbar from '../../components/reusable/Snackbar.vue';
import SelectField from '../../components/reusable/SelectField.vue';
import AuthRightImage from '../../components/markup/AuthRightImage.vue';
import { required, email } from '@vuelidate/validators';

export default defineComponent({
  name: 'SignupPage',
  components: {
    AuthRightImage,
    TextField,
    SmeSnackbar,
    SelectField,
  },
  setup() {
    const formData = ref({
      email: '',
      phone: '',
      partner: '',
      subscription: '',
      smeType: '',
      adminName: '',
      smeName: '',
      city: '',
      country: '',
    });

    const partners = ref([]) as any;

    const subscriptions = ref([]);

    const smeTypes = ref([
      { value: 'Hotels', name: 'Hotels' },
      { value: 'Restaurants', name: 'Restaurants' },
      { value: 'Outdoor Kiosk', name: 'Outdoor Kiosk' },
      { value: 'Coworking space', name: 'Coworking space' },
      { value: 'Universities', name: 'Universities' },
      {
        value: 'Retail Malls & Shopping Centers',
        name: 'Retail Malls & Shopping Centers',
      },
      { value: 'Smart Cities', name: 'Smart Cities' },
      { value: 'Salons & Spas', name: 'Salons & Spas' },
      { value: 'Hospitals', name: 'Hospitals' },
      { value: 'Public Location', name: 'Public Location' },
      { value: 'Others', name: 'Others' },
    ]);

    const countries = countriesData;

    const responseErrors = {} as any;

    const showSnackbar = ref(false);
    const loading = ref(false);
    const rules = {
      adminName: { required },
      email: { required, email },
      phone: { required },
      partner: { required },
      subscription: { required },
      smeType: { required },
      smeName: { required },
      city: { required },
      country: { required },
    };

    const v$ = useVuelidate(rules, formData);

    return {
      formData,
      showSnackbar,
      v$,
      partners,
      subscriptions,
      loading,
      smeTypes,
      responseErrors,
      countries,
    };
  },
  methods: {
    getPartners() {
      instance.get('partners').then((response) => {
        this.partners = response.data.results;
      });
    },
    handleSignup() {
      const data = {
        country: this.formData.country,
        partner: this.formData.partner,
        name: this.formData.smeName,
        admin_name: this.formData.adminName,
        admin_phone: this.formData.phone,
        admin_email: this.formData.email,
        location: this.formData.city,
        subscription: this.formData.subscription,
        type: this.formData.smeType,
      };

      this.loading = true;
      instance
        .post('smes/', data)
        .then(() => {
          this.loading = false;
          this.$toast.success(
            'Registered successfully. Check your email for login credentials',
            {
              position: 'top-right',
            }
          );
          router.push({ path: '/' });
        })
        .catch((error) => {
          this.responseErrors = error.response.data;
          this.loading = false;
          this.$toast.error('Could not register at this time', {
            position: 'top-right',
          });
        });
    },
    handleCloseSnackbar() {
      this.showSnackbar = false;
    },
    handleSignIn() {
      router.push({ path: '/' });
    },
    onSelectedPartner(data: any) {
      const partner = this.partners.filter(
        (partner: any) => partner.id === Number(data.value)
      )[0];
      this.subscriptions = partner.subscriptions;
    },
  },
  created() {
    this.getPartners();
  },
});
</script>

<style scoped>
@media screen and (max-width: 1023px) {
  .max-form {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background: linear-gradient(180deg, #4c8a6c 0%, #0b1c14 100%);
  }
}
</style>