export const dashboard_routes = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/dashboard',
    icon: require('../../assets/dashboard.svg'),
  },
  {
    id: 2,
    title: 'Routers',
    path: '/routers',
    icon: require('../../assets/router.svg'),
  },
  {
    id: 3,
    title: 'Vouchers',
    path: '/vouchers',
    icon: require('../../assets/voucher-sidemenu.svg'),
  },
  {
    id: 4,
    title: 'Landing Page Builder',
    path: '/captive-portal',
    icon: require('../../assets/captive-portal-icon.svg'),
  },
  {
    id: 8,
    title: 'Content Management',
    path: '/routers',
    icon: 'fa-solid fa-book',
    subMenus: [
      {
        id: 13,
        title: 'Adverts',
        path: '/adverts',
        icon: 'fa-solid fa-folder',
      },
      {
        id: 14,
        title: 'Offline Files',
        path: '/offline-files',
        icon: 'fa-solid fa-folder-open',
        childSubMenus: [],
      },
    ],
  },
  // {
  //   id: 5,
  //   title: 'Shiriki Eye',
  //   path: '/shiriki-eye',
  //   icon: require('../../assets/shiriki-eye.svg'),
  // },
  // {
  //   id: 6,
  //   title: 'Shiriki File',
  //   path: '/shiriki-file',
  //   icon: require('../../assets/shiriki-file.svg'),
  // },
  {
    id: 19,
    title: 'Shiriki Add-On',
    path: '/shiriki-add-on',
    icon: 'fa-solid fa-puzzle-piece',
  },
  {
    id: 7,
    title: 'Billing',
    path: 'billings',
    icon: require('../../assets/billing.png'),
  },
  {
    id: 11,
    title: 'Support',
    path: '/support-tickets',
    icon: require('../../assets/settings.png'),
  },

  {
    id: 15,
    title: 'User Management',
    path: '/system-admin',
    icon: 'fa-solid fa-users',
    subMenus: [
      {
        id: 16,
        title: 'Register User',
        path: '/user-add',
        icon: 'fa-solid fa-user-plus',
      },
      {
        id: 17,
        title: 'System Users',
        path: '/system-users',
        icon: 'fa-solid fa-users',
      },
      {
        id: 18,
        title: 'My Profile',
        path: '/account-info',
        icon: 'fa-solid fa-user',
      },
    ],
  },
];
