import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_2 = { class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white mt-6" }
const _hoisted_3 = { class: "w-full lg:flex my-6 justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_TokenAnalyticsChart = _resolveComponent("TokenAnalyticsChart")!
  const _component_SmeAnalytics = _resolveComponent("SmeAnalytics")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Dashboard",
      icon: _ctx.icon.dashboard
    }, {
      aside: _withCtx(() => _cache[0] || (_cache[0] = [])),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TokenAnalyticsChart, {
              data: _ctx.tokenAnalyticsData,
              onFilterChange: _ctx.fetchTokenAnalyticsData,
              currentTimeRange: _ctx.currentTimeRange,
              source: "homepage"
            }, null, 8, ["data", "onFilterChange", "currentTimeRange"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SmeAnalytics, {
              weekly_data: _ctx.weeklyUsers,
              monthly_data: _ctx.monthlyUsers,
              yearly_data: _ctx.yearlyUsers
            }, null, 8, ["weekly_data", "monthly_data", "yearly_data"]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-white lg:w-[49%] min-h-[21.432rem] rounded-[0.938rem] pt-4 pb-6 px-6 shadow lg:mb-0 mb-5" }, [
              _createElementVNode("span", { class: "font-poppins font-semibold text-[0.9rem] text-[#212121]" }, "Map"),
              _createElementVNode("div", {
                class: "w-full h-[400px] mt-3",
                id: "map"
              })
            ], -1))
          ])
        ]))
  ], 64))
}