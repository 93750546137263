<template>
    <span class="mx-auto">{{ file.folderName }}</span>
    <audio controls class="mx-auto">
        <source :src="file.url" type="audio/mpeg" />
        Your browser does not support the audio element.
    </audio>
</template>
<script>
export default{
    name: 'AudioPlayer',
    props: {
        file: Object,
    }
}
</script>
