<template>
  <div v-if="!isGridLayout">
    <!-- vertical file list -->
    <v-data-table
    :items="folders">
      <template v-slot:item="{ item, index }" >
        <tr
          class="hover:bg-grey-500"
        >
            <td>
              <input
                type="checkbox"
                v-model="item.checked"
                @change="updateSelectAllStatus"
                class="hover:cursor-pointer"
              />
            </td>
            <td>
              <v-avatar color="white lighten-1" v-if="item.isFolder">
                <i class="fa-solid fa-folder fa-2xl" style="color: #0c6a2d"></i>
              </v-avatar>
              <v-avatar color="white lighten-1" v-else>
                <img :src="item.thumbnail" alt="File Icon" />
              </v-avatar>
            </td>
            <td class="text-gray-400 text-lg font-semibold hover:cursor-pointer" @click="viewFolder(item, index)">
              {{ item.folderName }}
            </td>
            <td>
              <i
                v-if="item.favourite"
                class="fas fa-star fa-lg p-4"
                style="color: #d1d411"
              ></i>
              <span v-else></span>
            </td>
            <td>
              <FolderMiniMenu
                :folder="item"
                :index="index"
                @delete="deleteItem(index)"
                @openDialog="openDialog(index)"
                @favourite="favouriteItem(index)"
              />
              <v-dialog v-model="dialogVisible" max-width="400">
                <!-- for file and folder renaming -->
                <v-card>
                  <v-card-title> Rename </v-card-title>
                  <v-card-text>
                    <input
                      class="shadow border-primary rounded w-full py-2 px-3"
                      placeholder="New name"
                      v-model="newName"
                      @keydown.enter="renameFolder"
                    />
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions class="inline-flex justify-end space-x-4">
                    <v-btn color="red" @click="closeDialog">Cancel</v-btn>
                    <v-btn
                      class="p-2 bg-primary rounded-lg relative"
                      @click="renameFolder(renamingIndex)"
                      >Rename</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </td>
        </tr>
      </template>
    </v-data-table>
  </div>

  <div class="grid grid-cols-6 gap-1" v-else>
    <!-- horizontal list of files -->
      <div
        v-for="(folder, index) in folders"
        :key="index"
        class="bg-white-400 p-2 w-40"
      >
        <div class="bg-white-400 hover:shadow-lg rounded-lg" @click="viewFolder(folder,  index)">
          <!-- Checkbox -->
          <div class="block w-full text-left flex justify-between items-center">
            <input type="checkbox" class="relative" v-model="folder.checked" @change="updateSelectAllStatus">

            <!-- Favourite -->
            <i v-if="folder.favourite" class="fas fa-star fa-lg justify-end" style="color: #d1d411;"></i>
          </div>

          <!-- File Icon -->
          <!-- Conditional Rendering for a file and foder -->
          <img
            v-if="folder.isFolder"
            src="../../../assets/folder.jpeg"
            alt="Folder Icon"
            class="w-13 h-13 mx-auto mt-2"
          >
          <img
            v-else
            :src="folder.thumbnail"
            alt="File Icon"
            class="w-13 h-13 mx-auto mt-2"
          >
          <!-- File Name -->
          <div class="flex justify-between items-center px-4 text-gray-400 text-lg font-semibold text-lg text-center">
            {{ folder.folderName }} 
             <FolderMiniMenu :folder="folder" :index="index"
             @delete="deleteItem(index)" 
             @openDialog="openDialog"
             @favourite="favouriteItem(index)" />
             <v-dialog v-model="dialogVisible" max-width="400">
                <!-- for file and folder renaming -->
                <v-card>
                  <v-card-title>
                    Rename
                  </v-card-title>
                  <v-card-text>
                    <input class="shadow border-primary rounded w-full py-2 px-3" 
                    placeholder="New name"
                    v-model="newName" 
                    @keydown.enter="renameFolder"
                     />
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions class="inline-flex justify-end space-x-4">
                    <v-btn color="red" @click="closeDialog">Cancel</v-btn>
                    <v-btn class="p-2 bg-primary rounded-lg relative" @click="renameFolder(renamingIndex)">Rename</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import FolderMiniMenu from '../Buttons/FolderMiniMenu.vue'

export default {
  name: 'FileList',
  props: {
    folders: Object, // The file object containing name, thumbnail, etc.
    isGridLayout: Boolean,
  },
  components: {
    FolderMiniMenu,
  },
  data() {
    return {
      dialogVisible: false,
      renamingIndex: null,
      newName: '',
    };
  },
  methods: {
    deleteItem(index) {
      this.$emit('delete', index);
    },
    favouriteItem(index) {
      this.$emit('favourite', index)
    },
    openDialog(index) {
      this.renamingIndex = index,
      this.dialogVisible = true;
    },
    closeDialog() {
      this.newName=null;
      this.dialogVisible = false;
    },
    renameFolder(renamingIndex) {
      const newName = this.newName.trim();
      if (newName !== '') {
        this.$emit("renameFolder", renamingIndex, newName)
      }
      this.closeDialog();
    },
    viewFolder(folder, index) {
      this.$emit("viewFolder", folder, index);
    }
  },
};
</script>

<style scoped>
</style>