import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%]" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center h-full relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = { class: "w-full flex lg:justify-end justify-start items-center -mt-3 mb-6" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  type: "button",
  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
}
const _hoisted_10 = { class: "lg:w-[50%]" }
const _hoisted_11 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, " OTP Sent! ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-poppins font-normal text-[18px] mt-2 text-[#4E8D6E] text-center" }, " Enter the One Time Password token sent to your email. ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                name: "otp",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "OTP",
                type: "text",
                modelValue: _ctx.v$.otp.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.otp.$model) = $event)),
                showErrors: _ctx.v$.otp.$errors.length ? true : false,
                errors: _ctx.v$.otp.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleResend && _ctx.handleResend(...args)))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "font-poppins font-medium text-[0.778rem] lg:text-[#4D99FF] text-[#4E8D6E]" }, "Resend OTP", -1)
                ]))
              ]),
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    disabled: _ctx.v$.$invalid,
                    type: "button",
                    class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)))
                  }, " Confirm ", 8, _hoisted_8))
                : (_openBlock(), _createElementBlock("button", _hoisted_9, " Processing... "))
            ])
          ])
        ]),
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"max-form w-full absolute bottom-5 lg:flex hidden justify-between items-center\" data-v-8a946e92><ul class=\"flex\" data-v-8a946e92><li data-v-8a946e92><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-8a946e92><a href=\"javascript:void(0)\" data-v-8a946e92>Terms</a></span></li><li data-v-8a946e92><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-8a946e92><a href=\"javascript:void(0)\" data-v-8a946e92>Plans</a></span></li><li data-v-8a946e92><span class=\"text-success font-poppins font-medium text-[0.778rem]\" data-v-8a946e92><a href=\"javascript:void(0)\" data-v-8a946e92>Contact Us</a></span></li></ul></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_auth_right_image)
      ])
    ])
  ]))
}