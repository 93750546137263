<template>
  <dashboard-header
    title="Accoutn Info"
    icon="fa-regular fa-user"
    iconType="class"
  >
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[0.8rem] h-[0.8rem] rounded ml-1"
        />
      </div>
    </template>
  </dashboard-header>

  <suspended-page-message
    v-if="!loggedInUser.sme?.is_active"
  ></suspended-page-message>

  <div>
    <div class="lg:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16">
      <div
        class="lg:basis-2/6 relative overflow-x-auto pb-4 sm:mx-0 block sm:overflow-visible shadow-lg px-5 pt-5 pb-6 shadow-gray-900/5 bg-white rounded-2xl lg:mb-0 mb-6"
      >
        <div
          class="user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto"
        >
          <img
            src="../../assets/user-img.svg"
            class="w-full h-full"
            alt="user"
          />
        </div>

        <div class="text-center pt-2">
          <h3 class="font-bold font-lg">{{ first_name }} {{ last_name }}</h3>
          <span class="text-sm">Role: {{ role }}</span>
        </div>
        <div class="edit-profile flex justify-center pt-3">
          <button
            type="button"
            @click="editDetails"
            class="bg-primary font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
          >
            Edit Profile
          </button>
          <button
            v-if="status === 'active' && loggedInUser.id !== Number(userId)"
            @click="disableUser"
            type="button"
            class="bg-[#d7191c] font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
          >
            Suspend
          </button>
          <button
            v-if="status !== 'active' && loggedInUser.id !== Number(userId)"
            @click="activateUser"
            type="button"
            class="bg-primary font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
          >
            Activate
          </button>
        </div>
      </div>

      <div
        class="user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width"
      >
        <div class="user-profile-innr w-full">
          <table class="w-full table-fixed table-border">
            <tbody>
              <tr>
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Name
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ first_name }} {{ last_name }}
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Email
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ email }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Phone
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ phone }}
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Country
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ country }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Role
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ role }}
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Type
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ userType }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Status
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  <span
                    v-bind:class="[
                      status === 'suspended'
                        ? 'text-white bg-[#d7191c]'
                        : 'text-[#4e8d6e] bg-[#DCE9E3]',
                    ]"
                    class="font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"
                    >{{ status }}</span
                  >
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3"
                >
                  Date Joined
                </td>
                <td
                  class="font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words"
                >
                  {{ date_joined }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import router, { useRoute } from '@/router';
import instance from '@/axios-interceptor';

export default defineComponent({
  name: 'UserDetailsPage',
  components: {},

  data() {
    return {
      isLoading: false,
      processingActivation: false,
      processingDisactivation: false,
      activeTab: 'all',
      userId: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      gender: '',
      page: '',
      userType: '',
      date_of_birth: '',
      national_id_no: '',
      country: '',
      area_of_operation: '',
      status: '',
      role: '',
      date_joined: '',
      user: {},
      route: useRoute(),
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    editDetails() {
      router.push({
        name: 'user-edit',
        params: { id: this.route.params.id },
      });
    },
    async fetchUser(id: string) {
      await instance.get(`auth/users/${id}/details/`).then((response) => {
        if (response.status === 200) {
          this.user = response.data;
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.phone = response.data.phone;
          this.country = response.data.country;
          this.userType = response.data.user_type;
          this.role = response.data.role;
          this.email = response.data.email;
          this.status = response.data.status;
          this.date_joined = moment(response.data.date_joined).format(
            'M/D/YYYY, h:mm:ss a'
          );
        } else {
          this.$toast.error(' Could not fetch user details', {
            position: 'top-right',
          });
        }
      });
    },

    async disableUser() {
      const data = { is_active: false, status: 'suspended' };

      try {
        this.processingDisactivation = true;

        await instance
          .patch(`auth/users/${this.route.params.id}/details/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.fetchUser(this.route.params.id.toString());
              this.$toast.success(' User suspended successfully', {
                position: 'top-right',
              });
              this.processingDisactivation = false;
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async activateUser() {
      const data = { is_active: true, status: 'active' };

      try {
        this.processingActivation = true;

        await instance
          .patch(`auth/users/${this.route.params.id}/details/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.fetchUser(this.route.params.id.toString());
              this.$toast.success(' User activated successfully', {
                position: 'top-right',
              });
              this.processingActivation = false;
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    if (this.route.params.id) {
      this.userId = this.route.params.id.toString();
      this.fetchUser(this.userId);
    }
  },
});
</script>
