<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl max-h-[85vh] overflow-y-auto">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >
            Add Media
          </h3>
          <form>
            <div class="mb-4">
              <label
                for="small-input"
                class="block mb-2 text-sm font-medium text-gray-900"
                >Media Type *</label
              >
              <p class="text-xs mb-4 text-[#424242]">
                Select the media type of the main file
              </p>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                v-model="type"
                required
                id="small-input"
              >
                <option value="">Please select an option</option>
                <option value="Image">Image</option>
                <option value="Video">Video</option>
                <option value="Audio">Audio</option>
                <option value="Document">Document</option>
                <option value="App">App</option>
                <option value="partner media"></option>
              </select>
              <small
                v-if="missingFields.includes('Media Type')"
                class="text-[#ff0000] mt-2"
              >
                Media Type field is required
              </small>
            </div>
            <div class="mb-4">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Realm *</label
              >
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="realm"
                v-model="realm"
                required
              >
                <option value="">Select Realm</option>
                <option
                  v-for="realmItem in realmslist"
                  :key="realmItem.id"
                  :value="realmItem.id"
                >
                  {{ realmItem.name }}
                </option>
              </select>
              <small
                v-if="missingFields.includes('Realm')"
                class="text-[#ff0000] mt-2"
              >
                Realm field is required
              </small>
            </div>

            <div class="mb-4">
              <div class="flex justify-between items-center my-2">
                <label
                  for="small-input"
                  class="mb-0 text-sm font-medium text-gray-90"
                  >Category *</label
                >
                <button
                  type="button"
                  @click="showCategoryModal = true"
                  class="px-3 flex items-center font-['poppins'] text-[12px] bg-primary text-white rounded-[0.432rem] py-2 font-medium"
                >
                  <img
                    src="../../assets/plus-icon.png"
                    class="w-[0.6rem] mr-1"
                    alt=""
                  />
                  New Category?
                </button>
              </div>
              <addCategoryForm
                v-if="showCategoryModal"
                @close="closeCategoryModal()"
              ></addCategoryForm>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="realm"
                v-model="category"
                required
              >
                <option value="">Select Category</option>
                <option
                  v-for="category in categorylist"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
              <small
                v-if="missingFields.includes('Category')"
                class="text-[#ff0000] mt-2"
              >
                Category field is required
              </small>
            </div>

            <div class="mb-4">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Description *</label
              >

              <textarea
                class="text-[14px] w-full border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                rows="3"
                v-model="description"
              ></textarea>
              <small
                v-if="missingFields.includes('Description')"
                class="text-[#ff0000] mt-2"
              >
                Description field is required
              </small>
            </div>

            <div class="mb-4" v-if="type === 'Video'">
              <label
                for="small-input"
                class="block mb-2 text-sm font-medium text-gray-90"
                >Thumbnail File *</label
              >
              <p class="text-xs mb-4 text-[#424242]">
                Upload a thumbnail image for previewing the main file
              </p>
              <input
                id="file"
                ref="thumbnailFile"
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                placeholder="Choose a file or drop it here..."
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
              <small
                v-if="missingFields.includes('Thumbnail File')"
                class="text-[#ff0000] mt-2"
              >
                Thumbnail File field is required
              </small>
            </div>

            <div class="mb-6">
              <label
                for="small-input"
                class="block mb-2 text-sm font-medium text-gray-90"
                >Main File *</label
              >
              <p class="text-xs mb-4 text-[#424242]">
                Upload a main file that will be accessed or downloaded
              </p>
              <input
                id="file"
                ref="mainFile"
                type="file"
                placeholder="Choose a file or drop it here..."
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
              <small
                v-if="missingFields.includes('Main File')"
                class="text-[#ff0000] mt-2"
              >
                Main File field is required
              </small>
            </div>

            <div class="w-full bg-[#424242] dark:bg-neutral-600" v-if="loading">
              <div
                class="bg-primary p-0.5 text-center text-xs font-medium leading-none text-white animated"
                :style="{ width: `${uploadProgress}%` }"
              >
                {{ uploadProgress }}/%
              </div>
            </div>

            <div
              class="w-full flex lg:justify-end justify-start items-center mt-3"
            >
              <button
                @click="uploadMedia"
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center"
              >
                Upload
                <span
                  v-if="loading"
                  :class="{ 'spinner btn-spinner ml-2': loading }"
                >
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import axios from '@/axios-interceptor';

import { apiBaseUrl } from '../../config';
import addCategoryForm from './addCategoryForm.vue';

export default defineComponent({
  name: 'UploadMedia',
  components: {
    addCategoryForm,
  },
  props: {
    files: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      uploadModal: false,
      showCategoryModal: false,
      realmslist: [
        {
          id: '',
          name: '',
        },
      ],
      categorylist: [
        {
          id: '',
          name: '',
        },
      ],
      category: '',
      thumbnail: null,
      type: '',
      realm: '',
      status: 'Enabled',
      description: '',
      uploadProgress: 0,
      thumbnailFile: {} as File,
      missingFields: [] as string[],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'accessToken']),
  },
  mounted() {
    this.getRealms();
    this.getCategories();
  },
  methods: {
    getRealms() {
      axios
        .get(`realms/?sme=${this.loggedInUser.sme.id}`)
        .then((response) => {
          this.realmslist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeCategoryModal() {
      this.showCategoryModal = false;
      this.getCategories();
    },

    getCategories() {
      axios
        .get(`categories/?realm__sme=${this.loggedInUser.sme.id}`)
        .then((response) => {
          this.categorylist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async uploadMedia() {
      const mainFileRef = this.$refs.mainFile as any;
      const mainFile = mainFileRef.files[0];

      if (this.type === 'Video') {
        const thumbnailFileRef = this.$refs.thumbnailFile as any;
        this.thumbnailFile = thumbnailFileRef.files[0];
      }

      if (!this.type) {
        this.missingFields.push('Media Type');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Media Type'
        );
      }

      if (!this.realm) {
        this.missingFields.push('Realm');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Realm'
        );
      }

      if (!this.category) {
        this.missingFields.push('Category');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Category'
        );
      }

      if (!this.description) {
        this.missingFields.push('Description');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Description'
        );
      }

      if (this.type === 'Video' && !this.thumbnailFile) {
        this.missingFields.push('Thumbnail File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Thumbnail File'
        );
      }

      if (!mainFile) {
        this.missingFields.push('Main File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Main File'
        );
      }

      if (this.missingFields.length > 0) {
        return this.$toast.warning('Provide the missing fields', {
          position: 'top-right',
        });
      }

      const isDuplicateThumbnailFile =
        this.files &&
        this.files.some(
          (file: any) =>
            file.original_thumbnail_name === this.thumbnailFile.name
        );

      if (this.type === 'Video' && isDuplicateThumbnailFile) {
        this.$toast.warning(
          `Thumbnail file: ${this.thumbnailFile.name} already exists`,
          {
            position: 'top-right',
          }
        );
        return;
      }

      const isDuplicateMainFile =
        this.files &&
        this.files.some(
          (file: any) => file.original_file_name === mainFile.name
        );

      if (isDuplicateMainFile) {
        this.$toast.warning(`Main file: ${mainFile.name} already exists`, {
          position: 'top-right',
        });
        return;
      }

      const formData = new FormData();
      formData.append('file', mainFile);
      formData.append('realm', this.realm);
      formData.append('status', this.status);
      formData.append('file_type', this.type);
      formData.append('category', this.category);
      formData.append('description', this.description);
      formData.append('original_file_name', mainFile.name);

      if (this.type === 'Video') {
        formData.append('thumbnail', this.thumbnailFile);
        formData.append('original_thumbnail_name', this.thumbnailFile.name);
      }

      this.loading = true;

      const url = `${apiBaseUrl}v1/files/`;

      const xhr = new XMLHttpRequest();

      const token = this.accessToken;

      xhr.open('POST', url, true);

      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          // Update the progress value and display it to the user
          this.uploadProgress = progress;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          // Request completed successfully
          this.loading = false;
          this.$toast.success('Media uploaded successfully', {
            position: 'top-right',
          });
          this.uploadProgress = 0;
          this.$emit('close');
        } else {
          // Request completed with an error
          this.loading = false;
          const response = JSON.parse(xhr.responseText);
          this.$toast.error(response.error, {
            position: 'top-right',
          });
          this.uploadProgress = 0;
        }
      };

      xhr.onerror = () => {
        if (xhr.status === 0) {
          this.$toast.error('Request failed. Check your internet connection.', {
            position: 'top-right',
          });
          this.loading = false;
          this.uploadProgress = 0;
        } else {
          this.loading = false;
          this.uploadProgress = 0;
          this.$toast.error('An error occurred during the upload', {
            position: 'top-right',
          });
        }
      };

      xhr.send(formData);
    },
  },
});
</script>
