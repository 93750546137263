<template>
        <span>{{ file.folderName }}</span>
            <video ref="video" :src="file.url" @play="handlePlay" @timeupdate="updateProgress" controls></video>
            <div class="progress-container">
          <div class="progress" :style="{ width: `${progress}%` }"></div>
        </div>
        <p v-if="isPlaying" class="playing-message">The video is playing!</p>
</template>
<script>
export default {
    name: 'VideoPlayer',
    props: {
        file: Object,
    },
    data() {
        return {  
            isPlaying: null,
            progress: null,
        };
    },
    methods: {
        handlePlay() {
            // Custom logic when the video starts playing
            this.isPlaying = true;
        },
        updateProgress() {
            const video = this.$refs.video;
            if (video) {
                const currentTime = video.currentTime;
                const duration = video.duration;
                if (!isNaN(currentTime) && !isNaN(duration)) {
                    this.progress = (currentTime / duration) * 100;
                }
            }
        },
    },
};
</script>

<style>
.progress-container {
    width: 100%;
    height: 10px;
    background-color: #ccc;
}

.progress {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.1s;
}

.playing-message {
    font-weight: bold;
    color: #4CAF50;
}
</style>