<template>
  <DashboardHeader title="Shiriki File" :icon="shirikiFileIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span
          class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[1.099rem] h-[1.062rem] rounded ml-1"
        />
      </div>
    </template>
  </DashboardHeader>

  <suspended-page-message
    v-if="!loggedInUser.sme?.is_active"
  ></suspended-page-message>

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>

    <ShirikiFilePage v-else />
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import ShirikiFilePage from '../../components/Shiriki-file/Layouts/shirikifile.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';

export default defineComponent({
  name: 'ShirikiFile',
  components: {
    ShirikiFilePage,
    DashboardHeader,
    PaymentRequiredMessage,
  },
  setup() {
    const shirikiFileIcon = ref(dashboardHeadericon.shirikiFile);

    return {
      shirikiFileIcon,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
});
</script>
