import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/user-profile.svg'
import _imports_2 from '../../assets/lock.svg'
import _imports_3 from '../../assets/money.svg'
import _imports_4 from '../../assets/sign-in.svg'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium text-[0.8rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_5 = { class: "md:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16" }
const _hoisted_6 = { class: "lg:basis-2/5 relative flex overflow-x-auto pb-4 sm:mx-0 sm:block sm:overflow-visible shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl" }
const _hoisted_7 = { class: "lg:grid auto-cols-auto grid-flow-col justify-start whitespace-nowrap px-4 sm:mx-auto sm:max-w-2xl sm:grid-cols-3 sm:px-0 sm:text-center lg:grid-flow-row lg:grid-cols-1 lg:text-left" }
const _hoisted_8 = { class: "profile-sec full-width" }
const _hoisted_9 = { class: "mt-6 gap-x-8 gap-y-10 shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl relative full-width grid grid-cols-1 gap-x-25 xl:grid-cols-2" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { class: "block" }
const _hoisted_13 = { class: "block" }
const _hoisted_14 = { class: "block" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["src"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "block" }
const _hoisted_20 = {
  key: 0,
  class: "bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
}
const _hoisted_21 = {
  key: 1,
  disabled: "",
  class: "bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
}
const _hoisted_22 = { class: "mt-6 shadow-lg p-6 gap-x-8 gap-y-10 shadow-gray-900/5 bg-white rounded-2xl relative grid full-width grid-cols-1 gap-x-25 xl:grid-cols-2" }
const _hoisted_23 = { class: "block" }
const _hoisted_24 = { class: "profile-sec" }
const _hoisted_25 = { class: "mt-6 gap-x-8 md:gap-y-10 gap-y-5 shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl relative full-width grid grid-cols-1 gap-x-25 xl:grid-cols-1" }
const _hoisted_26 = { class: "block" }
const _hoisted_27 = { class: "block" }
const _hoisted_28 = { class: "block" }
const _hoisted_29 = {
  key: 0,
  class: "error"
}
const _hoisted_30 = { class: "profile-sec full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_header = _resolveComponent("dashboard-header")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_UpgradeSubscriptionPlan = _resolveComponent("UpgradeSubscriptionPlan")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_dashboard_header, {
      title: "Accoutn Info",
      icon: "fa-regular fa-user",
      iconType: "class"
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[16] || (_cache[16] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[0.8rem] h-[0.8rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "font-medium mb-6 px-3 mt-3" }, "Your Profile", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.activeTab == 'profile' ? 'bg-white ' : ''], "relative px-3 py-3 listhover rounded-md cursor-pointer"]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetActiveTab('profile')))
                }, _cache[18] || (_cache[18] = [
                  _createElementVNode("img", {
                    class: "inline-block",
                    src: _imports_1,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("div", { class: "relative inline-block" }, [
                    _createElementVNode("div", { class: "text-md text-slate-500" }, [
                      _createElementVNode("button", {
                        class: "text-[#464E5F] ml-3 font-medium",
                        role: "tab",
                        type: "button"
                      }, " Your Profile ")
                    ])
                  ], -1)
                ]), 2),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.activeTab == 'change-password' ? 'bg-white ' : ''], "relative px-3 py-3 listhover rounded-md cursor-pointer"]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetActiveTab('change-password')))
                }, _cache[19] || (_cache[19] = [
                  _createElementVNode("img", {
                    class: "inline-block",
                    src: _imports_2,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("div", { class: "relative inline-block" }, [
                    _createElementVNode("div", { class: "text-md text-slate-500" }, [
                      _createElementVNode("button", { class: "text-[#464E5F] ml-3 font-medium" }, " Change Password ")
                    ])
                  ], -1)
                ]), 2),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.activeTab == 'subscription' ? 'bg-white ' : ''], "relative px-3 py-3 listhover rounded-md cursor-pointer"]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetActiveTab('subscription')))
                }, _cache[20] || (_cache[20] = [
                  _createElementVNode("img", {
                    class: "inline-block",
                    src: _imports_3,
                    width: "22",
                    height: "22",
                    alt: ""
                  }, null, -1),
                  _createElementVNode("div", { class: "relative inline-block" }, [
                    _createElementVNode("div", { class: "text-md text-slate-500" }, [
                      _createElementVNode("button", { class: "text-[#464E5F] ml-3 font-medium" }, " Subscriptions ")
                    ])
                  ], -1)
                ]), 2),
                _createElementVNode("div", {
                  class: "relative px-3 py-3 listhover rounded-md mb-3 cursor-pointer",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
                }, _cache[21] || (_cache[21] = [
                  _createElementVNode("img", {
                    class: "inline-block",
                    src: _imports_4,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("div", { class: "relative inline-block" }, [
                    _createElementVNode("div", { class: "text-md text-blue-600" }, [
                      _createElementVNode("button", { class: "text-[#464E5F] ml-3 font-medium" }, "Logout")
                    ])
                  ], -1)
                ]))
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["lg:col-span-3 asis-1/2 full-width", [_ctx.activeTab == 'profile' ? 'block ' : 'hidden']])
            }, [
              _createElementVNode("form", {
                onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[32] || (_cache[32] = _createElementVNode("h2", { class: "font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 mt-5" }, " Profile ", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[23] || (_cache[23] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " First Name ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "name",
                        name: "firstName",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        placeholder: "Enter First Name",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.firstName) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.firstName]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[24] || (_cache[24] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Last Name ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "name",
                        name: "lastName",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        placeholder: "Enter Your LastName",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.lastName) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.lastName]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[25] || (_cache[25] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Phone ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "name",
                        name: "phone",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        placeholder: "Enter Phone ",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.phone) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.phone]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[27] || (_cache[27] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Gender ", -1)),
                      _withDirectives(_createElementVNode("select", {
                        id: "gender",
                        type: "name",
                        class: "text-[14px] w-full h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.625rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.gender) = $event))
                      }, _cache[26] || (_cache[26] = [
                        _createElementVNode("option", { value: "Male" }, "Male", -1),
                        _createElementVNode("option", { value: "Female" }, "Female", -1)
                      ]), 512), [
                        [_vModelSelect, _ctx.gender]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (!_ctx.newProfilePicture)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _cache[28] || (_cache[28] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Profile Picture ", -1)),
                            (_ctx.profilePicture)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: _ctx.profilePicture,
                                  alt: "profile picture",
                                  class: "mb-5 object-cover w-[200px] h-[200px]"
                                }, null, 8, _hoisted_16))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.newProfilePicture)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " New Profile Picture ", -1)),
                            _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-xs text-primary mb-2" }, " Click on Save Changes to apply the image ", -1)),
                            _createElementVNode("img", {
                              src: _ctx.newProfilePicture,
                              alt: "new profile picture",
                              class: "mb-5 object-cover w-[200px] h-[200px]"
                            }, null, 8, _hoisted_18)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("input", {
                        type: "file",
                        ref: "profilePicture",
                        onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                      }, null, 544)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[31] || (_cache[31] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Date of Birth ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "date",
                        name: "dob",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        placeholder: "Enter Your Date of birth",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.date_of_birth) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.date_of_birth]
                      ])
                    ])
                  ]),
                  (!_ctx.updatingProfile)
                    ? (_openBlock(), _createElementBlock("button", _hoisted_20, " Save Changes "))
                    : (_openBlock(), _createElementBlock("button", _hoisted_21, " Updating... "))
                ])
              ], 32),
              _createElementVNode("div", {
                class: _normalizeClass(["email-sec mt-8 full-width", [_ctx.activeTab == 'change-password' ? 'd-block ' : 'd-none']])
              }, [
                _cache[34] || (_cache[34] = _createElementVNode("h2", { class: "font-medium mt-6 inline-block md:text-[1.8rem] text-[1.4rem]" }, " Change Email Address ", -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, [
                    _cache[33] || (_cache[33] = _createElementVNode("span", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Email Address ", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      name: "email",
                      readonly: "",
                      class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                      placeholder: "Enter Email Address",
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.email) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.email]
                    ])
                  ])
                ]),
                _cache[35] || (_cache[35] = _createElementVNode("button", {
                  type: "submit",
                  class: "bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
                }, " Save Changes ", -1))
              ], 2)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["lg:col-span-3 asis-1/2", [_ctx.activeTab == 'change-password' ? 'block ' : 'hidden']])
            }, [
              _createElementVNode("form", {
                onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePasswordForm && _ctx.changePasswordForm(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[39] || (_cache[39] = _createElementVNode("h2", { class: "font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 mt-5" }, " Change Password ", -1)),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("label", _hoisted_26, [
                      _cache[36] || (_cache[36] = _createElementVNode("span", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Existing Password ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        name: "old_password",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.old_password) = $event)),
                        placeholder: ""
                      }, null, 512), [
                        [_vModelText, _ctx.old_password]
                      ])
                    ]),
                    _createElementVNode("label", _hoisted_27, [
                      _cache[37] || (_cache[37] = _createElementVNode("span", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " New Password ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        name: "new_password",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.new_password) = $event)),
                        placeholder: ""
                      }, null, 512), [
                        [_vModelText, _ctx.new_password]
                      ])
                    ]),
                    _createElementVNode("label", _hoisted_28, [
                      _cache[38] || (_cache[38] = _createElementVNode("span", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3" }, " Confirm Password ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        name: "confirm_password",
                        class: "mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.confirm_password) = $event)),
                        placeholder: ""
                      }, null, 512), [
                        [_vModelText, _ctx.confirm_password]
                      ]),
                      (_ctx.confirmPasswordError)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_29, _toDisplayString(_ctx.confirmPasswordError), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _cache[40] || (_cache[40] = _createElementVNode("button", {
                    type: "submit",
                    class: "bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
                  }, " Save Changes ", -1))
                ])
              ], 32)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["lg:col-span-3 asis-1/2 full-width", [_ctx.activeTab == 'subscription' ? 'block ' : 'hidden']])
            }, [
              _createElementVNode("div", _hoisted_30, [
                _cache[41] || (_cache[41] = _createElementVNode("h2", { class: "font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 my-5 uppercase" }, " Subscriptions ", -1)),
                _createVNode(_component_UpgradeSubscriptionPlan, {
                  pendingSubscriptions: _ctx.pendingSubscriptions,
                  processingSubscription: _ctx.processingSubscription,
                  onChangeSubscription: _ctx.changeSubscription,
                  onActivateSubscription: _ctx.activateSubscription
                }, null, 8, ["pendingSubscriptions", "processingSubscription", "onChangeSubscription", "onActivateSubscription"])
              ])
            ], 2)
          ])
        ]))
  ], 64))
}