<template>
  <div class="table-responsive w-full mt-3">
    <div class="overflow-auto table w-full">
      <div
        class="bg-[#F3F6F9] rounded-[0.337rem] items-center text-[#B5B5C3] font-poppins font-semibold text-[0.673rem] table-layout-tr table-th"
      >
      <td class="text-[#464E5F] capitalize" v-if="inputShow">
        <th class="whitespace-nowrap">
          <input type="checkbox" class="mr-2 shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight relative top-1" v-model="selectAllInternal" @change="selectAllRows" :disabled="deletingVouchers">
          <!-- <span class="px-1 text-white">{{ tableName }}</span> -->
        </th></td>
        <td class="text-[#464E5F] capitalize" v-if="tableName">
          {{ tableName }}
        </td>
        <td v-for="item in tableHeaderData" :key="item">{{ item }}</td>
      </div>
      <slot name="tableBodyData"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SmeTable',
  props: {
    tableHeaderData: {
      type: Array as PropType<string[]>,
      required: true,
    },
    tableName: {
      type: String as PropType<string>,
      required: false,
    },
    inputShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectAllValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    deletingVouchers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectAllInternal: false,
    };
  },
  watch: {
    selectAllValues(newVal) {
      // Update the data property when the prop value changes
      this.selectAllInternal = newVal;
    },
  },
  methods: {
    selectAllRows() {
      this.$emit('selectAllRows', this.selectAllInternal);
    },
    sortTable(sortKey: string) {
      this.$emit('sort', sortKey);
    },
  },
});
</script>
