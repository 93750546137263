<template>
  <DashboardHeader title="Shiriki Eye" :icon="shirikiEyeIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>

  <suspended-page-message v-if="!loggedInUser.sme.is_active"></suspended-page-message>

  <activate-subscription-message v-else-if="!loggedInUser.sme.subscription_plan" />

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>

    <!-- Conditional Render: has motion eye enabled -->
    <div v-else-if="loggedInUser.sme.has_motion_eye_feature_enabled">
      <div :style="{ textAlign: 'right' }">
        <button
          class="cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
          type="button" @click="disactivateAddon('motion-ui-addon')" v-if="!disactivating">
          Deactivate Motion Eye Add-On
        </button>
        <button v-else
          class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border"
          type="button" disabled>
          Disactivating...
        </button>
      </div>

      <shirikiEyePage />
    </div>

    <div v-else>
      <activate-add-on :price="25000" noActiveAddOnTitle="Motion Eye Add-On Inactive"
        noActiveAddOnMessage="The Motion Eye Add-On will help you add camera configurations to your captive portal so that users can view them"
        :processingAddOnActivation="processingAddOnActivation" @activate-add-on="activateMotionEyeAddOn">
      </activate-add-on>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import instance from '../../axios-interceptor';

import shirikiEyePage from '../../components/Shiriki-eye/shirikiEye.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';
import ActivateAddOn from '@/components/Common/ActivateAddOn.vue';

export default defineComponent({
  name: 'ShirikiEye',
  components: {
    shirikiEyePage,
    DashboardHeader,
    PaymentRequiredMessage,
    ActivateAddOn,
  },
  setup() {
    const shirikiEyeIcon = ref(dashboardHeadericon.shirikiEye);
    const disactivating = ref(false);

    return {
      shirikiEyeIcon,
      disactivating,
    };
  },
  data() {
    return {
      processingAddOnActivation: false,
      showCancellationDialog: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  methods: {
    activateMotionEyeAddOn() {
      this.processingAddOnActivation = true;

      instance
        .patch(`smes/${this.loggedInUser.sme.id}/`, {
          has_motion_eye_feature_enabled: true,
        })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Motion Eye Add-On activated successfully', {
            position: 'top-right',
          });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    disactivateAddon(addon: string) {
      let payload = {} as any;
      this.disactivating = true;

      if (addon === 'motion-ui-addon') {
        payload['has_motion_eye_feature_enabled'] = false;
      }

      instance
        .patch(`/smes/${this.loggedInUser.sme.id}/`, payload)
        .then(() => {
          this.disactivating = false;
          this.$toast.success('Add-On disactivated successfully', {
            position: 'top-right',
          });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.disactivating = false;
          this.$toast.error('Could not disactivate the Add-On', {
            position: 'top-right',
          });
        });
    },
  },
});
</script>
