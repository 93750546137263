import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center bg-gray-100" }
const _hoisted_2 = { class: "max-w-lg p-6 bg-white rounded-lg shadow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-2xl font-bold mb-4" }, "Activate Subscription", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray-600 mb-4" }, " You have no active subscription plan. To access the page, you need to activate a subscription plan. ", -1)),
      _createElementVNode("button", {
        type: "button",
        class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToSettings && _ctx.redirectToSettings(...args)))
      }, " Go to Settings ")
    ])
  ]))
}