import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/close-icon.svg'
import _imports_1 from '../../assets/plus-icon.png'


const _hoisted_1 = { class: "popup-modal" }
const _hoisted_2 = { class: "popup-modal-content rounded-xl max-h-[85vh] overflow-y-auto" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 mx-auto" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "flex justify-between items-center my-2" }
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  key: 1,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_14 = { class: "mb-4" }
const _hoisted_15 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_16 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_17 = {
  id: "file",
  ref: "thumbnailFile",
  type: "file",
  accept: ".png, .jpg, .jpeg, .gif",
  placeholder: "Choose a file or drop it here...",
  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
}
const _hoisted_18 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_19 = { class: "mb-6" }
const _hoisted_20 = {
  id: "file",
  ref: "mainFile",
  type: "file",
  placeholder: "Choose a file or drop it here...",
  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
}
const _hoisted_21 = {
  key: 0,
  class: "text-[#ff0000] mt-2"
}
const _hoisted_22 = {
  key: 1,
  class: "w-full bg-[#424242] dark:bg-neutral-600"
}
const _hoisted_23 = { class: "w-full flex lg:justify-end justify-start items-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_addCategoryForm = _resolveComponent("addCategoryForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "float-right"
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "w-[0.8rem] mt-1",
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[23] || (_cache[23] = _createElementVNode("h3", { class: "text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Add Media ", -1)),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_5, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                for: "small-input",
                class: "block mb-2 text-sm font-medium text-gray-900"
              }, "Media Type *", -1)),
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Select the media type of the main file ", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event)),
                required: "",
                id: "small-input"
              }, _cache[9] || (_cache[9] = [
                _createStaticVNode("<option value=\"\">Please select an option</option><option value=\"Image\">Image</option><option value=\"Video\">Video</option><option value=\"Audio\">Audio</option><option value=\"Document\">Document</option><option value=\"App\">App</option><option value=\"partner media\"></option>", 7)
              ]), 512), [
                [_vModelSelect, _ctx.type]
              ]),
              (_ctx.missingFields.includes('Media Type'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_6, " Media Type field is required "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[13] || (_cache[13] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Realm *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                id: "realm",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.realm) = $event)),
                required: ""
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("option", { value: "" }, "Select Realm", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.realmslist, (realmItem) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: realmItem.id,
                    value: realmItem.id
                  }, _toDisplayString(realmItem.name), 9, _hoisted_8))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.realm]
              ]),
              (_ctx.missingFields.includes('Realm'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, " Realm field is required "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[15] || (_cache[15] = _createElementVNode("label", {
                  for: "small-input",
                  class: "mb-0 text-sm font-medium text-gray-90"
                }, "Category *", -1)),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCategoryModal = true)),
                  class: "px-3 flex items-center font-['poppins'] text-[12px] bg-primary text-white rounded-[0.432rem] py-2 font-medium"
                }, _cache[14] || (_cache[14] = [
                  _createElementVNode("img", {
                    src: _imports_1,
                    class: "w-[0.6rem] mr-1",
                    alt: ""
                  }, null, -1),
                  _createTextVNode(" New Category? ")
                ]))
              ]),
              (_ctx.showCategoryModal)
                ? (_openBlock(), _createBlock(_component_addCategoryForm, {
                    key: 0,
                    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeCategoryModal()))
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("select", {
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                id: "realm",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.category) = $event)),
                required: ""
              }, [
                _cache[16] || (_cache[16] = _createElementVNode("option", { value: "" }, "Select Category", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categorylist, (category) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: category.id,
                    value: category.id
                  }, _toDisplayString(category.name), 9, _hoisted_12))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.category]
              ]),
              (_ctx.missingFields.includes('Category'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_13, " Category field is required "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[17] || (_cache[17] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Description *", -1)),
              _withDirectives(_createElementVNode("textarea", {
                class: "text-[14px] w-full border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                rows: "3",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.description) = $event))
              }, null, 512), [
                [_vModelText, _ctx.description]
              ]),
              (_ctx.missingFields.includes('Description'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_15, " Description field is required "))
                : _createCommentVNode("", true)
            ]),
            (_ctx.type === 'Video')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _cache[18] || (_cache[18] = _createElementVNode("label", {
                    for: "small-input",
                    class: "block mb-2 text-sm font-medium text-gray-90"
                  }, "Thumbnail File *", -1)),
                  _cache[19] || (_cache[19] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Upload a thumbnail image for previewing the main file ", -1)),
                  _createElementVNode("input", _hoisted_17, null, 512),
                  (_ctx.missingFields.includes('Thumbnail File'))
                    ? (_openBlock(), _createElementBlock("small", _hoisted_18, " Thumbnail File field is required "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_19, [
              _cache[20] || (_cache[20] = _createElementVNode("label", {
                for: "small-input",
                class: "block mb-2 text-sm font-medium text-gray-90"
              }, "Main File *", -1)),
              _cache[21] || (_cache[21] = _createElementVNode("p", { class: "text-xs mb-4 text-[#424242]" }, " Upload a main file that will be accessed or downloaded ", -1)),
              _createElementVNode("input", _hoisted_20, null, 512),
              (_ctx.missingFields.includes('Main File'))
                ? (_openBlock(), _createElementBlock("small", _hoisted_21, " Main File field is required "))
                : _createCommentVNode("", true)
            ]),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("div", {
                    class: "bg-primary p-0.5 text-center text-xs font-medium leading-none text-white animated",
                    style: _normalizeStyle({ width: `${_ctx.uploadProgress}%` })
                  }, _toDisplayString(_ctx.uploadProgress) + "/% ", 5)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("button", {
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.uploadMedia && _ctx.uploadMedia(...args))),
                type: "button",
                class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center"
              }, [
                _cache[22] || (_cache[22] = _createTextVNode(" Upload ")),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.loading })
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}