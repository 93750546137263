<template>
  <template v-if="isLoading">
    <div>
      <content-loader> </content-loader>
      <div class="h-[5rem]" />
      <content-loader> </content-loader>
    </div>
  </template>
  <template v-else>
    <div v-if="!isNotShowHeaderAndSidebar" class="flex flex-col -z-10" @click="handleCloseModal">
      <DashboardTopNav :isOpenModal="isOpenModal" @onToggle="onToggle" @handleOpenOrCloseModal="handleOpenOrCloseModal"
        companyLabel="" />

      <div class="bg-dashboardBg flex">
        <div
          class="lg:w-[8vw] lg:flex lg:items-center items-start flex-col lg:pb-10 toggle-sidenav h-screen fixed mt-[8vh]"
          :class="[isToggle ? 'block' : 'hidden']">
          <span
            class="my-3 lg:text-center font-semibold text-[0.75rem] font-poppins text-[#888889] tracking-wider bg-white-mobile lg:block hidden">Actions</span>
          <div
            class="mb-10 border-r-2 border-sidebarBorder h-full flex flex-col lg:items-center items-start lg:w-full md:w-[150px] w-[150px] bg-white-mobile overflow-y-auto overflow-hidden">
            <sidebar-menu v-for="route in routes" :showChild="false" :show="false"
              :child-sub-menus="route?.submenus?.childSubMenus" :sub-menus="route?.subMenus" :route-path="route.path"
              :key="route.id" :title="route.title" :icon="route.icon" classes="mb-2"
              @click="handleSetActive(route.id, route.title)" :isActive="activeId === route.id" />
          </div>
        </div>

        <div class="lg:ml-[9vw] lg:mr-2.5 w-full px-4 lg:px-0 mt-[7vh]">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div v-if="isNotShowHeaderAndSidebar" class="w-full lg:px-0">
      <div class="w-full">
        <router-view></router-view>
      </div>
    </div>
  </template>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import SidebarMenu from '../src/components/reusable/SidebarMenu.vue';
import { dashboard_routes } from './helpers/stub_data/dashboard_routes';
import DashboardTopNav from '../src/components/markup/DashboardTopNav.vue';
import {
  routers as _routers,
  dashboardHeadericon,
} from './helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'AppPage',
  components: {
    SidebarMenu,
    DashboardTopNav,
  },

  setup() {
    const isLoading = ref(false);
    const isOpenModal = ref(false);
    const isToggle = ref(false);
    const isNotShowHeaderAndSidebar = ref(false);
    const icon = ref({ ...dashboardHeadericon });
    const routes = ref([...dashboard_routes]);
    const routers = ref([..._routers]);
    const activeId = ref(1);
    const activeTab = ref('');
    return {
      isLoading,
      isOpenModal,
      icon,
      routes,
      routers,
      activeId,
      activeTab,
      isToggle,
      isNotShowHeaderAndSidebar,
    };
  },

  created() {
    if (localStorage.getItem('smeId')) {
      this.handleFetchUser();
    }
  },

  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    dashboadHeaderIcon() {
      if (this.activeTab == 'Routers') {
        return dashboardHeadericon.router;
      } else if (this.activeTab == 'Billing') {
        return dashboardHeadericon.accountIcon;
      } else if (this.activeTab == 'Support') {
        return dashboardHeadericon.accountIcon;
      } else if (this.activeTab == 'Files') {
        return dashboardHeadericon.fileIcon;
      } else if (this.activeTab == 'Captive Portal') {
        return dashboardHeadericon.captiveportal;
      } else if (this.activeTab == 'Dashboard') {
        return dashboardHeadericon.dashboard;
      } else if (this.activeTab == 'Vouchers') {
        return dashboardHeadericon.voucher;
      } else if (this.activeTab == 'Feedback') {
        return dashboardHeadericon.feedback;
      } else if (this.activeTab == 'Shiriki Eye') {
        return dashboardHeadericon.shirikiEye;
      } else if (this.activeTab == 'Shiriki File') {
        return dashboardHeadericon.shirikiFile;
      } else {
        return dashboardHeadericon.router;
      }
    },
  },
  beforeMount() {
    this.isAccountModule();
    var checkActiveTab = localStorage.getItem('activeTab');
    var activeId = localStorage.getItem('activeId');
    if (checkActiveTab != null && parseInt) {
      this.activeTab = checkActiveTab;
      this.activeId = activeId != null ? parseInt(activeId) : 0;
    }
  },

  watch: {
    $route(to, from) {
      this.isAccountModule();
    },
  },
  data() {
    return {
      activebtnId: '',
    };
  },
  methods: {
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    onToggle() {
      this.isToggle = !this.isToggle;
    },
    handleSetActive(id, title) {
      this.activeId = id;
      localStorage.setItem('activeId', id.toString());
      this.activeTab = title;
      localStorage.setItem('activeTab', title);
    },
    isAccountModule() {
      var hrefPath = document.location.pathname;

      if (
        hrefPath == '/otp' ||
        hrefPath == '/forgot-password' ||
        hrefPath.includes('/password/reset') ||
        hrefPath == '/change-password' ||
        hrefPath == '/signup' ||
        hrefPath == '/'
      ) {
        this.isNotShowHeaderAndSidebar = true;
      } else {
        this.isNotShowHeaderAndSidebar = false;
      }
    },
    async handleFetchUser() {
      this.isLoading = true;
      await this.$store.dispatch('fetchUser');
      this.isLoading = false;
    },
  },
});
</script>
