<template>
  <div class="flex justify-start items-center bg-gray-100">
    <div class="w-[500px] p-6 bg-white rounded-lg shadow">
      <!-- Display no active sms package message -->
      <div v-if="!loggedInUser.sme.sms_package" class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">
            SMS Add-On Inactive
          </h3>
          <p class="mt-3 text-sm">
            The SMS Add-On will help your users to generate wifi access tokens
            via sms instead of you providing them with the tokens manually
          </p>
        </div>
      </div>

      <!-- Display total sent sms messages -->
      <div v-if="loggedInUser.sme.sms_package && !loggedInUser.sme?.has_not_paid_for_one_sms_billing"
        class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">
            Total Sent SMS Messages
          </h3>
          <p class="mt-3 text-sm">
            You have sent
            <strong>{{
              loggedInUser.sme.total_sms_sent_in_current_cycle
            }}</strong>
            sms messages out of
            <strong>{{
              loggedInUser.sme.sms_package.name.split('_')[0]
            }}</strong>
            total eligible messages
          </p>
        </div>
      </div>

      <!-- Display the pending sms package change card if it exists -->
      <div v-if="pendingSMSPackages.length && !loggedInUser.sme?.has_not_paid_for_one_sms_billing"
        class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div v-for="pendingSMSPackage in pendingSMSPackages" :key="pendingSMSPackage.id">
          <h3 class="font-bold text-lg mb-2 text-primary">
            Pending SMS Package Change
          </h3>
          <p class="text-gray-600">
            From:
            {{ pendingSMSPackage.current_sms_package.name.split('_')[0] }} SMS
          </p>
          <p class="text-gray-600">
            To: {{ pendingSMSPackage.new_sms_package.name.split('_')[0] }} SMS
          </p>

          <p class="text-primary mt-3 text-sm">
            The
            <strong>{{
              pendingSMSPackage.new_sms_package.name.split('_')[0]
            }}
              SMS</strong>
            sms package will be applied after payment of the next billing cycle on:
            <strong>{{ nextBillingDate }}</strong> UTC+3 (EAT) Time Zone.
          </p>
        </div>
      </div>

      <!-- Display payment warning -->
      <div v-if="loggedInUser.sme?.has_not_paid_for_one_sms_billing"
        class="mb-4 p-4 border rounded-md border-[#f24236]">
        <div>
          <h3 class="font-bold text-xl mb-2 text-[#f24236]">
            Pending Payment for Previous Billing
          </h3>
          <p class="text-gray-600">
            There is a pending sms package billing that you have not paid.
            Please go to the billings page and make the payment to resume access to the SMS addon.
          </p>

          <button type="button" @click="navigateToPage({ path: '/billings', query: { tab: 'sms' } })"
            class="w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] p-3 font-medium mt-6">
            Access the Page
          </button>
        </div>
      </div>

      <div v-else>
        <h2 class="text-2xl font-bold mb-4" v-if="!!loggedInUser.sme.sms_package">
          Upgrade or Downgrade your SMS package
        </h2>

        <h2 class="text-2xl font-bold mb-4" v-if="!loggedInUser.sme.sms_package">
          Activate SMS Package
        </h2>

        <!-- Display the available sms package options -->
        <div v-for="smsPackage in smsPackages" :key="smsPackage.name" class="mb-4 cursor-pointer"
          @click="selectedSMSPackage = smsPackage.value" :class="loggedInUser.sme.sms_package?.name === smsPackage.value
            ? 'border-primary border-[1px] rounded-md p-4'
            : ''
            ">
          <div class="flex items-start">
            <input type="radio" :id="smsPackage.name" :value="smsPackage.value" v-model="selectedSMSPackage"
              class="mt-1 mr-2" />
            <label :for="smsPackage.name" class="font-bold">{{
              smsPackage.name
            }}</label>
          </div>
          <p class="text-gray-600">Price: {{ smsPackage.monthly_cost }} RWF</p>
          <p class="text-gray-600">Description: {{ smsPackage.description }}</p>
          <p class="text-primary mt-3 text-sm" v-if="loggedInUser.sme.sms_package?.name === smsPackage.value">
            Current SMS Package
          </p>
        </div>

        <p class="text-[#f24236] mt-3 text-sm mb-3" v-if="pendingSMSPackages.length">
          Warning: You have a pending SMS package change. Thus, you cannot upgrade
          or downgrade your sms package till the pending one is applied on the
          next billing cycle.
        </p>

        <p class="text-[#f24236] mt-3 text-sm mb-3" v-if="loggedInUser.sme?.has_not_paid_for_one_sms_billing">
          Warning: You have a pending SMS package billing payment. Thus, you cannot upgrade or
          downgrade your SMS package till you pay for the outstanding billings.
        </p>

        <!-- Upgrade button -->
        <button type="button"
          class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
          :class="{
            'cursor-not-allowed': isUpgradeButtonDisabled,
          }" @click="upgrade" :disabled="isUpgradeButtonDisabled"
          v-if="!processingSMSPackage && !!loggedInUser.sme.sms_package">
          Upgrade
        </button>

        <!-- Upgrading or Downgrading button -->
        <button v-if="processingSMSPackage" type="button"
          class="w-full cursor-not-allowed font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
          Processing...
        </button>

        <!-- Activate sms package button -->
        <button type="button"
          class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
          :class="{
            'cursor-not-allowed': !selectedSMSPackage,
          }" @click="activate" v-if="!processingSMSPackage && !loggedInUser.sme.sms_package"
          :disabled="!selectedSMSPackage">
          Activate <span v-if="selectedSMSPackage"> for {{ returnSMSPackagePrice(selectedSMSPackage.split("_")[0]) }}
            RWF
          </span>
        </button>
      </div>

    </div>
  </div>
  <confirmation-dialog :show="showCancellationDialog" title="SMS Add-On Deactivation"
    message="Disabling the SMS Add-On will remove your SMS package and cancel access to generating tokens via SMS. This change will take effect after your current billing cycle ends, so you'll still have access until then."
    actionLabel="Confirm" :loading="loadingCancellation" @cancel="toggleCancellationDialog(false)"
    @confirm="cancelSMSPackage()" />
</template>


<script lang="ts">
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ref, defineComponent, PropType } from 'vue';

import router from '@/router';
import { SMSPackage, LoggedInUser } from '@/interfaces/billing';
import { PendingSMSPackageChange } from '@/interfaces/subscription';

export default defineComponent({
  name: 'UpgradeSMSPackage',
  props: {
    pendingSMSPackages: {
      type: Array as PropType<PendingSMSPackageChange[]>,
      required: true,
    },
    processingSMSPackage: {
      type: Boolean,
      required: true,
    },
    defaultSMSPackages: {
      type: Array as PropType<SMSPackage[]>,
      required: true,
    },
    loadingCancellation: {
      type: Boolean,
      required: true,
    },
    showCancellationDialog: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const selectedSMSPackage = ref('');
    const smsPackages: SMSPackage[] = [
      {
        name: '100 SMS',
        monthly_cost: '5,000',
        description: 'Send 100 SMS Per Month (30 days)',
        value: '100_sms',
      },
      {
        name: '500 SMS',
        monthly_cost: '10,000',
        description: 'Send 500 SMS Per Month (30 days)',
        value: '500_sms',
      },
      {
        name: '1000 SMS',
        monthly_cost: '15,000',
        description: 'Send 1000 SMS Per Month (30 days)',
        value: '1000_sms',
      },
    ];

    return {
      smsPackages,
      selectedSMSPackage,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    isUpgradeButtonDisabled(): boolean {
      const loggedInUser = this.loggedInUser as LoggedInUser
      return (
        this.pendingSMSPackages.length > 0 ||
        (loggedInUser.sme.sms_package &&
          loggedInUser.sme.sms_package.name === this.selectedSMSPackage) ||
        loggedInUser.sme.has_not_paid_for_one_sms_billing
      );
    },
    nextBillingDate(): string {
      const billingDate: moment.Moment = moment.utc(
        this.pendingSMSPackages[0].sms_billing.due_date
      );
      const formattedDate: string = billingDate.format('MMMM Do, YYYY');

      return `${formattedDate}`;
    },
  },
  methods: {
    ...mapActions(['fetchUser']),
    returnSMSPackagePrice(sms: string) {
      if (sms === '100') {
        return '5,000';
      } else if (sms === '500') {
        return '10,000';
      } else {
        return '15,000'
      }
    },
    upgrade() {
      const smsPackage = this.defaultSMSPackages.find(
        (smsPackage: SMSPackage) => {
          return smsPackage.name === this.selectedSMSPackage;
        }
      );

      if (smsPackage) {
        this.$emit('change-sms-package', smsPackage.id);
      } else {
        this.$toast.error(
          'There is a problem with the selected sms package. Contact us for support',
          {
            position: 'top-right',
            duration: 7000,
          }
        );
      }
    },
    cancelSMSPackage() {
      this.$emit('cancel-sms-package');
    },
    toggleCancellationDialog(value: boolean) {
      this.$emit('toggle-cancellation-dialog', value);
    },
    activate() {
      if (this.selectedSMSPackage === this.loggedInUser.sme.sms_package?.name) {
        return;
      }

      const smsPackage = this.defaultSMSPackages.find(
        (smsPackage: SMSPackage) => {
          return smsPackage.name === this.selectedSMSPackage;
        }
      );

      if (smsPackage) {
        // console.log("SMS PACKAGE: ", smsPackage);

        // this.$emit('activate-sms-package', smsPackage.id);
        this.$emit('activate-sms-package', smsPackage);
      } else {
        this.$toast.error(
          'There is a problem with the selected sms package. Contact us for support',
          {
            position: 'top-right',
            duration: 7000,
          }
        );
      }
    },
    navigateToPage(path: any) {
      router.push(path);
    }
  },
  mounted() {
    this.selectedSMSPackage = this.loggedInUser.sme.sms_package?.name;
  },
});
</script>

<style></style>
