const currentDate = new Date();

export const currentYear = currentDate.getFullYear();
export const currentMonth = currentDate.getMonth() + 1
const numberOfDaysInMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
const currentDayOfWeek = currentDate.getDay();

export const labelsMonth = Array.from({ length: numberOfDaysInMonth }, (_, index) => (index + 1));

export const labelsYear =  Array.from({ length: 7 }, (_, index) => currentYear - 6 + index);

// Calculate the start date of the week (Sunday)
export const weekStartDate = new Date(currentDate);
weekStartDate.setDate(currentDate.getDate() - currentDayOfWeek);

// Calculate the end date of the week (Saturday)
export const weekEndDate = new Date(currentDate);
weekEndDate.setDate(currentDate.getDate() + (6 - currentDayOfWeek));


export const labelsWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];


export const datasetsMonth = [
  {
    backgroundColor: "#006665",
    borderColor:"#006665",
    borderWidth: 1,
    data: [],
    label: 'Month Records',
  }

];

export const datasetsWeek = [
  {
    backgroundColor: "#006665",
    borderColor:"#006665",
    borderWidth: 1,
    data: [],
    label: 'Week Records',
  },
];

export const datasetsYear = [
  {
    backgroundColor: "#006665",
    borderColor:"#006665",
    borderWidth: 1,
    data: [],
    label: 'Years Records',
  },
];

export const changeLabels = [
  { id: 1, label: 'Year' },
  { id: 2, label: 'Month' },
  { id: 3, label: 'Week' },
];



