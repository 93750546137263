import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/plus-icon.png'
import _imports_2 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto"
}
const _hoisted_8 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "mr-2" }
const _hoisted_13 = { id: "myTabContent" }
const _hoisted_14 = { class: "md:w-[30%] w-[50%]" }
const _hoisted_15 = { class: "relative block" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "flex flex-col ml-3" }
const _hoisted_18 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "flex flex-col" }
const _hoisted_21 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_22 = { class: "flex flex-col" }
const _hoisted_23 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_24 = { class: "flex flex-col" }
const _hoisted_25 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_26 = { class: "flex flex-col" }
const _hoisted_27 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_28 = { class: "flex flex-col" }
const _hoisted_29 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "flex flex-col" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_34 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_35 = { class: "flex flex-col" }
const _hoisted_36 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_37 = { class: "flex flex-col" }
const _hoisted_38 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_39 = { class: "flex flex-col" }
const _hoisted_40 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_41 = { class: "flex flex-col" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "spinner-container" }
const _hoisted_44 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_media = _resolveComponent("upload-media")!
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_payment_required_message = _resolveComponent("payment-required-message")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "All Media",
      description: "",
      icon: _ctx.icon.fileIcon
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[5] || (_cache[5] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ]),
        (_ctx.loggedInUser.sme?.is_active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (
            _ctx.shouldDisplayButton()
          )
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex" }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2",
                        alt: ""
                      }),
                      _createTextVNode(" Add Media")
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_upload_media, {
              key: 1,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal())),
              files: _ctx.files
            }, null, 8, ["files"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_payment_required_message)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("ul", _hoisted_10, [
                      _createElementVNode("li", _hoisted_11, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'offline-files'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                          id: "profile-tab",
                          "data-tabs-target": "#offline-files",
                          type: "button",
                          role: "tab",
                          "aria-controls": "offline-files",
                          "aria-selected": "false",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetActiveTab('offline-files')))
                        }, " Offline Files ", 2)
                      ]),
                      _createElementVNode("li", _hoisted_12, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'realm-categories'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                          id: "dashboard-tab",
                          "data-tabs-target": "#dashboard",
                          type: "button",
                          role: "tab",
                          "aria-controls": "dashboard",
                          "aria-selected": "false",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSetActiveTab('realm-categories')))
                        }, " Realm Categories ", 2)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (_ctx.propLoaded)
                    ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                        key: 0,
                        perPageItems: _ctx.perSize,
                        pageNumber: _ctx.page,
                        ApiData: _ctx.files,
                        onHandleNext: _ctx.handlleNext,
                        onHandlePrev: _ctx.handlePrev
                      }, {
                        section: _withCtx(() => [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("label", _hoisted_15, [
                              _cache[8] || (_cache[8] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                                _createElementVNode("img", {
                                  src: _imports_2,
                                  alt: "",
                                  class: "w-[1rem] h-[1rem]"
                                })
                              ], -1)),
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchQuery) = $event)),
                                class: "font-normal rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                                placeholder: "Search media",
                                type: "text",
                                name: "search"
                              }, null, 512), [
                                [_vModelText, _ctx.searchQuery]
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeTab !== 'realm-categories')
                    ? (_openBlock(), _createBlock(_component_SmeTable, {
                        key: 1,
                        tableHeaderData: _ctx.routerTableHeaders
                      }, {
                        tableBodyData: _withCtx(() => [
                          (_ctx.paginatedTableData.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: item.id,
                                  class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                }, [
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("span", _hoisted_18, [
                                          _createElementVNode("img", {
                                            src: item?.thumbnail,
                                            class: "rounded-full inline-block h-10 w-10"
                                          }, null, 8, _hoisted_19)
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_20, [
                                      _createElementVNode("span", _hoisted_21, _toDisplayString(item?.sme?.name), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_22, [
                                      _createElementVNode("span", _hoisted_23, _toDisplayString(item.realm_name), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_24, [
                                      _createElementVNode("span", _hoisted_25, _toDisplayString(item.category), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("span", _hoisted_27, _toDisplayString(item.file_type), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_28, [
                                      _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.createdAt(item.created)), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("span", {
                                        onClick: ($event: any) => (_ctx.downloadFile(item.file)),
                                        class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                                      }, _cache[10] || (_cache[10] = [
                                        _createTextVNode("Download"),
                                        _createElementVNode("i", { class: "ml-1.5 fa-solid fa-download" }, null, -1)
                                      ]), 8, _hoisted_30)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_31, [
                                      (!_ctx.deleting || _ctx.currentDeletionFileID !== item.id)
                                        ? (_openBlock(), _createElementBlock("i", {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.deleteMediaFile(item.id)),
                                            class: "fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                                          }, null, 8, _hoisted_32))
                                        : _createCommentVNode("", true),
                                      (_ctx.deleting && _ctx.currentDeletionFileID === item.id)
                                        ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                            key: 1,
                                            color: "#d7191c"
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]))
                              }), 128))
                            : (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[11] || (_cache[11] = [
                                _createElementVNode("b", null, "No Record found", -1)
                              ])))
                        ]),
                        _: 1
                      }, 8, ["tableHeaderData"]))
                    : _createCommentVNode("", true),
                  (_ctx.activeTab === 'realm-categories')
                    ? (_openBlock(), _createBlock(_component_SmeTable, {
                        key: 2,
                        tableHeaderData: _ctx.realmCategoriesTableHeader
                      }, {
                        tableBodyData: _withCtx(() => [
                          (!_ctx.categories.length)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_34, " No records "))
                            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.categories, (category) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: category.id,
                                  class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                }, [
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_35, [
                                      _createElementVNode("span", _hoisted_36, _toDisplayString(category.linked_realm.name), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_37, [
                                      _createElementVNode("span", _hoisted_38, _toDisplayString(category.name), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_39, [
                                      _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.createdAt(category.created)), 1)
                                    ])
                                  ]),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("div", _hoisted_41, [
                                      (
                      !_ctx.deletingCategory ||
                      _ctx.currentDeletionCategoryID !== category.id
                    )
                                        ? (_openBlock(), _createElementBlock("i", {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.deleteCategory(category.id)),
                                            class: "fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                                          }, null, 8, _hoisted_42))
                                        : _createCommentVNode("", true),
                                      (
                      _ctx.deletingCategory &&
                      _ctx.currentDeletionCategoryID === category.id
                    )
                                        ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                            key: 1,
                                            color: "#d7191c"
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]))
                              }), 128))
                        ]),
                        _: 1
                      }, 8, ["tableHeaderData"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_43, [
                    (_ctx.isLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_44))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
        ]))
  ], 64))
}