<template>
  <div class="bg-white w-full rounded-[0.938rem] py-4 md:py-6 md:px-6 px-4 shadow overflow-auto relative mt-5">
    <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
      {{ userId ? 'Add Advertisement' : ' Add Advertisement' }}
    </h3>
    <form class="">
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1">
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Title:</label>
          <input placeholder="Title" v-model="title" required type="text" id="title"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
          <small v-if="missingFields.includes('Title')" class="text-[#ff0000] mt-2">
            Title is required
          </small>
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Description:</label>
          <textarea v-modal="message" id="message" rows="2" v-model="short_description"
            class="text-[14px] w-full max-h-[5.5rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            placeholder="Write your thoughts here..."></textarea>
          <small v-if="missingFields.includes('Description')" class="text-[#ff0000] mt-2">
            Description is required
          </small>
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Media Type :</label>

          <select id="adType" type="name"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            v-model="adType">
            <option value="" disabled>Select Type</option>
            <!-- <option value="Image">Image</option> -->
            <option value="Video">Video</option>
            <!-- <option value="Link">Youtube Link</option> -->
          </select>
          <small v-if="missingFields.includes('Media Type')" class="text-[#ff0000] mt-2">
            Media Type is required
          </small>
        </div>

        <div class="my-2" v-if="adType === 'Link'">
          <label for="small-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link:</label>
          <input id="link" type="text" v-model="youtube_link"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
          <small v-if="missingFields.includes('YouTube Link')" class="text-[#ff0000] mt-2">
            YouTube Link is required
          </small>
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">File:</label>
          <input required type="file" accept="video/*" id="file" ref="fileInput"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
          <small class="text-primary mt-2" v-if="!missingFields.includes('Portrait')">
            Choose a Landscape video. The one that has more width than height. 16:9 ratio and above.
          </small>
          <small v-if="missingFields.includes('Advert File')" class="text-[#ff0000] mt-2">
            Advert File is required
          </small>
          <small v-if="missingFields.includes('Portrait') &&
            !missingFields.includes('Advert File')
          " class="text-[#ff0000] mt-2">
            The uploaded video is in Portrait mode. It has more height than
            width. Upload a Landscape mode video that has more width than
            height.
            <span>
              <button @click="previewVideo" class="ml-2 text-primary underline">
                Preview Video
              </button>
            </span>
          </small>
        </div>

        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Realms:</label>
          <select
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            id="small-input" v-model="realm" required>
            <option value="">Select Realm</option>
            <option v-for="realmItem in realmslist" :key="realmItem.id" :value="realmItem.id">
              {{ realmItem.name }}
            </option>
          </select>
          <small v-if="missingFields.includes('Realm')" class="text-[#ff0000] mt-2">
            Realm is required
          </small>
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Active :</label>

          <select id="isActive" type="name"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            v-model="isActive">
            <option value="" disabled>Select Status</option>
            <option value="true">Yes</option>

            <option value="false">No</option>
          </select>
          <small v-if="missingFields.includes('Active')" class="text-[#ff0000] mt-2">
            Active is required
          </small>
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Start Date :</label>
          <input required type="date" id="startDate" v-model="start_date"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
        </div>
        <div class="mb-2">
          <label for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">End Date :</label>
          <input required type="date" id="endDate" v-model="end_date"
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
        </div>
      </div>
      <div class="w-full bg-[#424242] dark:bg-neutral-600" v-if="loading">
        <div class="bg-primary p-0.5 text-center text-xs font-medium leading-none text-white animated"
          :style="{ width: `${uploadProgress}%` }">
          {{ uploadProgress }}/%
        </div>
      </div>
      <div class="w-full sm:flex lg:justify-end justify-end items-center mt-3">
        <button @click="manageAds" type="button"
          class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border flex items-center justify-center">
          {{ userId ? 'Update Advert' : 'Upload Advert' }}
          <span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
          </span>
        </button>
      </div>
    </form>

    <!-- Modal for video preview -->
    <div v-if="showPreview" class="popup-modal" @click="closePreview">
      <div class="popup-modal-content rounded-xl">
        <video :src="previewVideoSrc" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { apiBaseUrl } from '../../config';
import instance from '@/axios-interceptor';
import router, { useRoute } from '@/router';

export default {
  name: 'AddEditAdvertPage',
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      realmslist: [
        {
          id: '',
          name: '',
        },
      ],
      file: null,
      title: '',
      short_description: '',
      adType: '',
      realm: '',
      isActive: '',
      youtube_link: '',
      end_date: '',
      start_date: '',
      userId: '',
      uploadProgress: 0,
      missingFields: [],
      videoType: '',
      showPreview: false,
      previewVideoSrc: '',
      route: useRoute(),
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'accessToken']),
  },
  mounted() {
    this.getRealms();
    if (this.route.params.id) {
      this.userId = this.route.params.id.toString();

      this.fetchAdvert(this.userId);
    }
  },
  methods: {
    manageAds() {
      if (this.userId) {
        this.updateAd();
      } else {
        this.addAdvert();
      }
    },

    fetchAdvert(id) {
      instance.get(`verts/${id}/`).then((response) => {
        if (response.status === 200) {
          this.advert = response.data;
          this.title = response.data.title;
          this.adType = response.data.ad_type;
          this.short_description = response.data.short_description;
          this.realm = response.data.realm.id;
          this.isActive = response.data.is_active;
          this.file = response.data.file;
        } else {
          this.$toast.error('Could not fetch advert details', {
            position: 'top-right',
          });
        }
      });
    },
    getRealms() {
      instance
        .get(`realms/?sme=${this.loggedInUser.sme.id}`)
        .then((response) => {
          this.realmslist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    previewVideo() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];

      if (file) {
        const objectURL = URL.createObjectURL(file);

        // Set the previewVideoSrc to the object URL
        this.previewVideoSrc = objectURL;

        this.showPreview = true;
      }
    },

    closePreview() {
      this.showPreview = false;
    },

    async addAdvert() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];
      const formData = new FormData();

      if (!this.title) {
        this.missingFields.push('Title');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Title'
        );
      }

      if (!this.short_description) {
        this.missingFields.push('Description');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Description'
        );
      }

      if (!this.adType) {
        this.missingFields.push('Media Type');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Media Type'
        );
      }

      if (this.adType === 'Link' && !this.youtube_link) {
        this.missingFields.push('YouTube Link');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'YouTube Link'
        );
      }

      if (!file) {
        this.missingFields.push('Advert File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Advert File'
        );
      }

      if (file) {
        const video = document.createElement('video');

        video.addEventListener('loadedmetadata', () => {
          const aspectRatio = video.videoWidth / video.videoHeight;

          if (aspectRatio >= 1.7) {
            this.videoType = 'landscape';
          } else {
            this.videoType = 'portrait';
          }


          if (this.videoType !== 'landscape') {
            this.missingFields.push('Portrait');
          } else {
            this.missingFields = this.missingFields.filter(
              (field) => field !== 'Portrait'
            );
          }
        });

        const objectURL = URL.createObjectURL(file);
        video.src = objectURL;
      }


      if (!this.realm) {
        this.missingFields.push('Realm');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Realm'
        );
      }

      if (!this.isActive) {
        this.missingFields.push('Active');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Active'
        );
      }

      if (this.missingFields.length > 0) {
        return;
      }

      if (this.start_date && !this.end_date) {
        return this.$toast.warning('Both start and end dates are required', {
          position: 'top-right',
        });
      }

      if (this.end_date && !this.start_date) {
        return this.$toast.warning('Both start and end dates are required', {
          position: 'top-right',
        });
      }

      if (this.end_date < this.start_date) {
        return this.$toast.warning(
          'The end date should be greater than the start date',
          {
            position: 'top-right',
          }
        );
      }

      formData.append('file', file, file.name);
      formData.append('title', this.title);
      formData.append('short_description', this.short_description);
      formData.append('ad_type', this.adType);
      formData.append('realm', this.realm);
      formData.append('is_active', this.isActive);
      formData.append('youtube_url', this.youtube_link);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      formData.append('sme', this.loggedInUser.sme.id);

      this.loading = true;

      const url = `${apiBaseUrl}v1/verts/`;

      const xhr = new XMLHttpRequest();

      const token = this.accessToken;

      xhr.open('POST', url, true);

      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          // Update the progress value and display it to the user
          this.uploadProgress = progress;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          // Request completed successfully
          this.loading = false;
          this.$toast.success('Advert uploaded successfully', {
            position: 'top-right',
          });
          this.uploadProgress = 0;
          router.push('/adverts');
        } else {
          // Request completed with an error
          this.loading = false;
          const response = JSON.parse(xhr.responseText);
          this.$toast.error(response.error, {
            position: 'top-right',
          });
          this.uploadProgress = 0;
        }
      };

      xhr.onerror = () => {
        if (xhr.status === 0) {
          this.$toast.error('Request failed. Check your internet connection.', {
            position: 'top-right',
          });
          this.loading = false;
          this.uploadProgress = 0;
        } else {
          this.loading = false;
          this.uploadProgress = 0;
          this.$toast.error('An error occurred during the upload', {
            position: 'top-right',
          });
        }
      };

      xhr.send(formData);
    },

    updateAd() {
      const formData = new FormData();
      formData.append('title', this.title);
      formData.append('short_description', this.short_description);
      formData.append('ad_type', this.adType);
      formData.append('realm', this.realm);
      formData.append('is_active', this.isActive);

      if (this.file !== this.advert.file) {
        formData.append('file', this.file);
      }

      if (this.start_date && this.end_date) {
        formData.append('start_date', this.start_date);
        formData.append('end_date', this.end_date);
      }

      this.loading = true;

      instance
        .patch(`verts/${this.route.params.id}/`, formData)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.$toast.success('Advert updated sucessfully', {
              position: 'top-right',
            });
            router.push('/adverts');
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            console.log(error.response);
          }
        });
    },
  },
};
</script>