<template>
  <div class="w-full my-3">
    <div class="bg-white w-full min-h-[25rem]  p-6  overflow-auto relative">
      <div class="bg-light justify-center text-6xl rounded-xl p-6 bg-gray-100" id="routers" role="tabpanel"
        aria-labelledby="routers">
        <div class="min-server text-right mb-2 flex items-center">
          <select id="server-day" v-model="selected_router" @change="getHostsInfo($event)"
            class="text-[14px] max-w-[200px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1">
            <option value="" disabled>Select a mini server</option>
            <option value="all">All Boxes</option>
            <template v-if="filterrouter.length">
              <option :value="option.host" v-for="option in filterrouter" :key="option.id">
                {{ option.ssid ? option.ssid + ' - ' + option.host.split('_')[1] : option.host.split('_')[1] }}
              </option>
            </template>
          </select>
          <span class="dotonline ml-2" v-if="selected_router && selected_router !== 'all' && info.is_online"></span>
          <span class="dotoffline ml-2" v-if="selected_router && selected_router !== 'all' && !info.is_online"></span>
        </div>
        <!-- tabs -->
        <div class="tabs-block" id="tabs-block">
          <div class="mb-3">
            <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
              role="tablist">
              <li class="mr-2">
                <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
                  type="button" role="tab" aria-controls="profile" aria-selected="false" v-on:click="activetab = '1'"
                  v-bind:class="[
                    activetab === '1'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]">
                  Wi-Fi Analysis
                </button>
              </li>
              <li class="mr-2">
                <button class="inline-block p-4 py-2 rounded-md w-full" id="dashboard-tab" data-tabs-target="#dashboard"
                  type="button" role="tab" aria-controls="dashboard" aria-selected="false" v-on:click="activetab = '2'"
                  v-bind:class="[
                    activetab === '2'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]">
                  Customer Insights Analytics
                </button>
              </li>
              <li class="mr-2">
                <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab" data-tabs-target="#settings"
                  type="button" role="tab" aria-controls="settings" aria-selected="false" v-on:click="activetab = '3'"
                  v-bind:class="[
                    activetab === '3'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]">
                  Network Analytics
                </button>
              </li>

              <li class="mr-2">
                <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab" data-tabs-target="#settings"
                  type="button" role="tab" aria-controls="settings" aria-selected="false" v-on:click="activetab = '4'"
                  v-bind:class="[
                    activetab === '4'
                      ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]">
                  Service Status
                </button>
              </li>
            </ul>
          </div>
          <div id="myTabContent">
            <div v-if="activetab === '1'"
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-white"
              id="profile" role="tabpanel" aria-labelledby="profile-tab">

              <div class="mt-2">
                <TokenAnalyticsChart :data="tokenAnalyticsData" @filterChange="fetchTokenAnalyticsData"
                  :currentTimeRange="currentTimeRange" :loadingOne="false" :loadingTwo="fetchingTokenAnalyticsData" />
              </div>

              <div class="w-full">
                <a
                  class="h-4/5 block mt-6 p-4 bg-white shadow-gray-950 border-solid border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                  <h3 class="text-base font-bold">
                    Device Vendor Distribution
                  </h3>
                  <div class="mt-8">
                    <apexchart v-if="vendorChartSeries.length" width="670" type="pie" :options="vendorChartOptions"
                      :series="vendorChartSeries" :labels="vendorChartLabels">
                    </apexchart>
                    <p v-else class="text-sm">
                      No device vendor analytics available yet
                    </p>
                  </div>
                </a>
              </div>
            </div>

            <!-- CUSTOMER INSIGHTS ANALYTICS TAB SECTION -->
            <div v-if="activetab === '2'"
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100"
              id="profile" role="tabpanel" aria-labelledby="profile-tab">

              <div class="controls pt-6 pb-9 flex justify-center">
                <select v-model="currentTimeRange" @change="onTimeRangeChange" class="text-sm justify-center">
                  <option value="today">Today</option>
                  <option value="1week">1 Week</option>
                  <option value="1month">1 Month</option>
                  <option value="3months">3 Months</option>
                  <option value="6months">6 Months</option>
                  <option value="12months">12 Months</option>
                  <option value="5years">5 Years</option>
                </select>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <CardDesign :data="{
                  topic: 'Dwell Time',
                  icon: 'fa-solid fa-stopwatch',
                  figures: insightAnalytics.dwell_time,
                  color: '#E5E4FF',
                  iconcolor: '#8280FF',
                }" />

                <CardDesign :data="{
                  topic: 'Returned Customer',
                  icon: 'fa-solid fa-cube',
                  figures: insightAnalytics.returned_customers,
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',
                }" />

                <CardDesign :data="{
                  topic: 'Peek Time',
                  icon: 'fa-solid fa-clock',
                  figures: insightAnalytics.peek_time,
                  color: '#D9F7E8',
                  iconcolor: '#00B69B',


                }" />

                <CardDesign :data="{
                  topic: 'Unique Customers',
                  icon: 'fa-solid fa-users',
                  figures: insightAnalytics.unique_customers,
                  color: '#EDF2F7',
                  iconcolor: '#006665',
                }" />

              </div>


              <!-- Insights Overview -->
              <!-- <div class="mt-12">
                <div class="w-full">
                  <h3 class="text-base font-bold">Insights Overview</h3>
                  <apexchart type="area" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
                </div>
              </div> -->
            </div>

            <div v-if="activetab === '4'"
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
              id="profile" role="tabpanel" aria-labelledby="profile-tab">

              <!-- SERVICES STATUS -->
              <div
                class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                id="service-status" role="tabpanel" aria-labelledby="service-status-tab">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-2">

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'BOX LOCATION UPDATE',
                      icon: 'fa-solid fa-location-dot',
                      figures: info.boxLocationUpdateServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true
                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'BOX OS VERSION UPDATE',
                      icon: 'fa fa-location',
                      figures: info.boxOSVersionUpdateServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'CAPTIVE PORTAL DATABASE INITIALIZATION',
                      icon: 'fa-solid fa-database',
                      figures: info.captivePortalDbInitServiceStatus,
                      color: '#FFF3D6',
                      iconcolor: '#FEC53D',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'CAPTIVE PORTAL DATABASE SYNC',
                      icon: 'fa-solid fa-rotate',
                      figures: info.captivePortalDbSyncServiceStatus,
                      iconcolor: '#FF9066',
                      color: '#FFDED1',
                      no_zero: true,
                      small: true

                    }" />
                  </div>


                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'COOVACHILLI',
                      icon: 'fa-solid fa-microchip',
                      figures: info.coovaChilliServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'HOSTAPD',
                      icon: 'fa-solid fa-wifi',
                      figures: info.hostapdServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true
                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'MEDIA SYNC',
                      icon: 'fa-solid fa-file',
                      figures: info.mediaSyncServiceStatus,
                      color: '#FFF3D6',
                      iconcolor: '#FEC53D',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'SSID CHANGE',
                      icon: 'fa-solid fa-signature',
                      figures: info.ssidServiceStatus,
                      iconcolor: '#FF9066',
                      color: '#FFDED1',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'WEBMIN',
                      icon: 'fa-solid fa-spider',
                      figures: info.webminServiceStatus,
                      color: '#E5E4FF',
                      iconcolor: '#8280FF',
                      no_zero: true,
                      small: true

                    }" />
                  </div>

                  <div class="justify-center">
                    <CardDesign :data="{
                      topic: 'MOTIONEYE',
                      icon: 'fa-solid fa-camera',
                      figures: info.motionEyeServiceStatus,
                      color: '#4AD991',
                      no_zero: true,
                      small: true
                    }" />
                  </div>
                </div>
              </div>
            </div>


            <div v-if="activetab === '3'"
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100"
              id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-2">

                <CardDesign :data="{
                  topic: 'Network Bandwidth',
                  icon: 'fa-solid fa-network-wired',
                  network_figures: `${routersAvgSpeed.average_upload_speed.toFixed(2)} MBPS
                    UPLOAD /
                    ${routersAvgSpeed.average_download_speed.toFixed(2)} MBPS
                    DOWNLOAD`,
                  color: '#4AD991',
                  network: true
                }" />


                <CardDesign :data="{
                  topic: 'Network Latency',
                  icon: 'fa-solid fa-mobile-button',
                  figures: `0.00 MBs`,
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',

                }" />

                <CardDesign :data="{
                  topic: 'Network Usage',
                  icon: 'fa-solid fa-server',
                  network_figures: `  ${routersNetUsage.total_data_transmitted_mb.toFixed(2)}
                    MBs UPLOADED /
                    ${routersNetUsage.total_data_received_mb.toFixed(2)}
                    MBs DOWNLOADED`,
                  color: '#FEE7EC',
                  iconcolor: '#F93C65',
                  network: true
                }" />


                <CardDesign :data="{
                  topic: 'Signal Strength',
                  icon: 'fa-solid fa-chart-line',
                  figures: `${router_signal_strength}`,
                  color: '#E5E4FF',
                  iconcolor: '#8280FF',

                }" />

              </div>

              <!-- Network Overview -->
              <!-- <div class="mt-12">
                <h3 class="text-base font-bold">Network Overview</h3>
                <apexchart type="area" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import ApexCharts from 'vue3-apexcharts';
import { defineComponent, ref, provide } from 'vue';

import instance from '@/axios-interceptor';
import { DeviceVendor, DataUsage, ConnectedClients, TokenAnalyticsPayload, ApiResponseItem, InsightAnalytics, ApiResponse } from '@/interfaces/router';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import CardDesign from '@/components/reusable/CardDesign.vue';
import TokenAnalyticsChart from '@/components/Common/TokenAnalyticsChart.vue';


export default defineComponent({
  name: 'AnalyticsPage',
  components: {
    apexchart: ApexCharts,
    CardDesign,
    TokenAnalyticsChart,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const activeId = ref(1);
    const activeTab = ref('profile');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    const fetchingTokenAnalyticsData = ref(false);
    const currentTimeRange = '3months';
    const router_serial_number = ref<string | string[] | null>();

    provide('loadingOne', false);
    provide('loadingTwo', fetchingTokenAnalyticsData);

    return {
      activeId,
      center,
      markers,
      isOpenModal,
      activeTab,
      icon,
      fetchingTokenAnalyticsData,
      currentTimeRange,
      router_serial_number,
    };
  },

  data() {
    return {
      filterrouter: [
        {
          host: '',
          ssid: '',
          id: '',
        },
      ],
      list_router: [
        {
          id: 0,
          serial_number: '',
          ssid: ''
        },
      ],
      selected_router: '',
      miniServers: [
        {
          host: '',
          ssid: '',
          hostid: '',
        },
      ],
      tokenAnalyticsData: null,
      zabbixUserName: 'Admin',
      zabbixPassword: 'wizzdev2021',
      zabbixAuthenticationToken: '',
      zabbixBaseUri: 'https://zabbix.shirikihub.com/zabbix/',
      zabbixApiEndpoint: 'api_jsonrpc.php',
      zabbixHostRequestPayload: {
        jsonrpc: '2.0',
        method: 'host.get',
        params: {
          output: ['hostid', 'host'],
          selectInterfaces: ['interfaceid', 'ip'],
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      },
      info: {
        is_online: false,
        boxLocationUpdateServiceStatus: '',
        boxOSVersionUpdateServiceStatus: '',
        captivePortalDbInitServiceStatus: '',
        captivePortalDbSyncServiceStatus: '',
        coovaChilliServiceStatus: '',
        hostapdServiceStatus: '',
        mediaSyncServiceStatus: '',
        ssidServiceStatus: '',
        webminServiceStatus: '',
        motionEyeServiceStatus: '',
      },
      top10Vendors: [] as DeviceVendor[],
      vendorChartOptions: {
        labels: [] as string[],
      },
      vendorChartSeries: [] as number[],
      vendorChartLabels: [] as string[],
      series: [35, 214, 123, 34, 10],
      chartOptions: {
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          title: {
            text: 'Overview',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      chartSeries: [
        {
          name: 'data used',
          data: [100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180],
        },
        {
          name: 'people connected',
          data: [20, 30, 24, 50, 26, 30, 25, 15, 40, 20, 55, 60],
        },
      ],
      mainactivetab: 'microfranchise',
      activetab: '1',
      isLoading: false,
      tabIndex: 0,
      insightAnalytics: {} as any,
      wifiAnalytics: {} as any,
      totalMicroFranchisees: 0,
      femaleMicroFranchisees: 0,
      maleMicroFranchisees: 0,
      activeMicroFranchisees: 0,
      selectedRange: 'all_days',
      routersAvgSpeed: {
        average_upload_speed: 0,
        average_download_speed: 0,
      },
      routersNetUsage: {
        total_data_received_mb: 0,
        total_data_transmitted_mb: 0,
      },
      router_signal_strength: 'no data available yet',
      networkUsagePerMonth: [] as DataUsage[],
      connectedClientsPerMonth: [] as ConnectedClients[],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },

  methods: {
    chartSeriesForNetUsage() {
      const months = this.chartOptions.xaxis.categories;
      const dataUsedSeries = months.map((month) => {
        const matchingData = this.networkUsagePerMonth.find(
          (item: any) => this.convertMonth(item.month) === month
        );

        if (matchingData !== undefined) {
          return (
            matchingData.data_received.toFixed(1) +
            matchingData.data_received.toFixed(1)
          );
        } else {
          return 0;
        }
      });
      const peopleConnectedSeries = months.map((month) => {
        const matchingData = this.connectedClientsPerMonth.find(
          (item: any) => this.convertMonth(item.month) === month
        );

        if (matchingData !== undefined) {
          return matchingData.unique_mac_count;
        } else {
          return 0;
        }
      });

      return [
        {
          name: 'Data Used',
          data: dataUsedSeries,
        },
        {
          name: 'People Connected',
          data: peopleConnectedSeries,
        },
      ];
    },
    convertMonth(fullDate: string) {
      const dateObj = new Date(fullDate);
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return monthNames[dateObj.getMonth() + 1];
    },
    async getWifianlytics(router_serial_number: string) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router_serial_number,
      };
      this.isLoading = true;
      instance
        .post(`analytics/wifianalytics/`, formData)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.wifiAnalytics = response.data.results;
          this.updateActiveData();
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchTokenAnalyticsData(newTimeRange: string, router_serial_number: any) {
      const payload: TokenAnalyticsPayload = {
        sme: this.loggedInUser.sme.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.currentTimeRange = newTimeRange;

      if (newTimeRange && !router_serial_number) {
        if (Array.isArray(this.router_serial_number)) {
          payload.router_serial_numbers = this.router_serial_number;
        } else if (typeof this.router_serial_number === 'string') {
          payload.router_serial_number = this.router_serial_number;
        }
      }

      if (router_serial_number) {
        this.router_serial_number = router_serial_number;
      }

      try {
        this.fetchingTokenAnalyticsData = true;
        const response = await instance.post(`/analytics/wifi-v2/?time_range=${newTimeRange}`, payload);
        this.tokenAnalyticsData = response.data;
        this.fetchingTokenAnalyticsData = false;
        console.log("ANALYTICS DATA FETCHED: ", this.tokenAnalyticsData);
      } catch (error) {
        this.fetchingTokenAnalyticsData = false;
        console.error('Error fetching data:', error);
      }
    },
    async fetchCustomerInsights(newTimeRange: string, router_serial_number: string | string[]): Promise<void> {
      const payload: TokenAnalyticsPayload = {
        sme: this.loggedInUser.sme.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.currentTimeRange = newTimeRange;

      if (newTimeRange && (!router_serial_number || router_serial_number === '')) {
        if (Array.isArray(this.router_serial_number)) {
          payload.router_serial_numbers = this.router_serial_number;
        } else if (typeof this.router_serial_number === 'string') {
          payload.router_serial_number = this.router_serial_number;
        }
      }

      if (router_serial_number) {
        this.router_serial_number = router_serial_number;
      }

      this.isLoading = true;

      try {
        const response = await instance.post<Record<string, ApiResponse>>(
          `analytics/insight-v2/?time_range=${newTimeRange}`,
          payload
        );
        this.isLoading = false;
        this.insightAnalytics = this.processInsightAnalytics(response.data[newTimeRange]);
        console.log('WIFI INSIGHT ANALYTICS DATA FETCHED: ', this.insightAnalytics);
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    processInsightAnalytics(data: { series: ApiResponseItem[] }): InsightAnalytics {
      const processed: Partial<InsightAnalytics> = {};

      data.series.forEach(item => {
        const key = item.name.toLowerCase().replace(' ', '_') as keyof InsightAnalytics;
        switch (key) {
          case 'dwell_time':
          case 'peek_time':
            processed[key] = item.total as string;
            break;
          case 'returned_customers':
          case 'unique_customers':
          case 'first_time_visitors':
          case 'phone_model':
            processed[key] = Number(item.total);
            break;
        }
      });

      return {
        dwell_time: processed.dwell_time || '0 Hours',
        returned_customers: processed.returned_customers || 0,
        peek_time: processed.peek_time || 'N/A',
        unique_customers: processed.unique_customers || 0,
        first_time_visitors: processed.first_time_visitors || 0,
        phone_model: processed.phone_model || 0
      };
    },
    async loginZabbix() {
      console.log('LOGIN ZABBIX');
      var routerUrl = `routers/?sme=${this.loggedInUser.sme.id}`;
      await instance.get(routerUrl).then((response: any) => {
        console.log('GET ROUTERS REQUEST');
        if (response.status === 200) {
          console.log(`list routers - 1`, response.data.results);
          this.list_router = response.data.results;
          console.log(`list routers`, this.list_router);
        }
      });

      // var instance: any = instance.create();
      delete axios.defaults.headers.common['Authorization'];
      const data = {
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: this.zabbixUserName,
          password: this.zabbixPassword,
        },
        id: 1,
      };

      console.log('LOGIN ZABBIX - PAYLOAD: ', data);

      const endpoint = `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`;
      await axios.post(endpoint, data).then((response: any) => {
        if (response.status === 200) {
          this.zabbixAuthenticationToken = response.data.result;
        } else {
          this.$toast.error(`Could not authenticate w/Zabbix.`, {
            position: 'top-right',
          });
        }
      });
      var payload_get_host = {
        jsonrpc: '2.0',
        method: 'host.get',
        params: {
          output: ['hostid', 'host'],
          selectInterfaces: ['interfaceid', 'ip'],
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };
      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_get_host
        )
        .then((response: any) => {
          if (response.status === 200) {
            console.log('ZABBIX MINI SERVERS: ', response.data.result);
            this.miniServers = response.data.result;
            let routers: any = [];

            for (let i = 0; i < this.list_router.length; i++) {
              let matchingMiniServers = this.miniServers.filter(
                (item) =>
                  item.host ===
                  'mini-server_' + this.list_router[i].serial_number
              );

              if (matchingMiniServers.length) {
                matchingMiniServers[0].ssid = this.list_router[i].ssid
                routers.push(matchingMiniServers[0]);
              }
            }
            console.log('----', routers);
            this.filterrouter = routers;
          } else {
            this.$toast.error(`Could not get the hosts.`, {
              position: 'top-right',
            });
          }
        });
    },
    async fetchVendorAnalytics(router: number) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router,
      };

      this.isLoading = true;

      instance
        .post('analytics/device-vendor/', formData)
        .then((response: { data: DeviceVendor[] }) => {
          this.isLoading = false;
          console.log('vendor analytics: ', response.data);
          const vendors = response.data.map((data) => data.vendor);
          const devices = response.data.map((data) => data.devices);

          this.top10Vendors = response.data.slice(0, 10);
          this.vendorChartLabels = vendors;
          this.vendorChartSeries = devices;
          this.vendorChartOptions.labels = vendors;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchRouterSpeedAnalytics(router_serial_number: string | string[]) {
      const payload: TokenAnalyticsPayload = {
        sme: this.loggedInUser.sme.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.isLoading = true;

      instance
        .post('analytics/router-speed/', payload)
        .then((response: { data: any }) => {
          this.isLoading = false;

          this.routersAvgSpeed = {
            average_upload_speed: response.data.average_upload_speed,
            average_download_speed: response.data.average_download_speed,
          };
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchNetworkUsageAnalytics(router_serial_number: string | string[]) {
      const payload: TokenAnalyticsPayload = {
        sme: this.loggedInUser.sme.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.isLoading = true;

      instance
        .post('analytics/network-usage/', payload)
        .then((response: { data: any }) => {
          this.isLoading = false;

          this.routersNetUsage = {
            total_data_received_mb: response.data.total_data_received_mb,
            total_data_transmitted_mb: response.data.total_data_transmitted_mb,
          };
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchNetworkUsageAndConnectedClientsAnalytics(router: number) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router,
      };

      this.isLoading = true;
      instance
        .post('analytics/network-usage-and-connected-clients/', formData)
        .then((response: { data: any }) => {
          this.isLoading = false;

          this.networkUsagePerMonth = response.data.data_usage;
          this.connectedClientsPerMonth = response.data.connected_clients;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchRouterSignalStrengthAnalytic(router_serial_number: string) {
      const formData = {
        sme: this.loggedInUser.sme.id,
        router_serial_number,
      };

      this.isLoading = true;
      instance
        .post('analytics/router-signal-strength/', formData)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.router_signal_strength = response.data.category;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async getHostsInfo(event: any) {
      const targetRouterSerialNumber = event.target.value.includes(
        'mini-server'
      )
        ? event.target.value.split('_')[1]
        : event.target.value;

      let targetRouter: any = [];

      if (targetRouterSerialNumber === 'all') {
        const allSerialNumbers = this.list_router.map(router => router.serial_number);
        await this.fetchTokenAnalyticsData(this.currentTimeRange, allSerialNumbers);
        await this.fetchCustomerInsights(this.currentTimeRange,
          allSerialNumbers);
        await this.fetchRouterSpeedAnalytics(allSerialNumbers);
        await this.fetchNetworkUsageAnalytics(allSerialNumbers);
        return;
      } else {
        targetRouter = this.list_router.filter(
          (router) => router.serial_number === targetRouterSerialNumber
        );

      }

      await this.getWifianlytics(targetRouter[0].serial_number);
      await this.fetchTokenAnalyticsData(this.currentTimeRange, targetRouter[0].serial_number);
      await this.fetchCustomerInsights(this.currentTimeRange,
        targetRouter[0].serial_number);
      await this.fetchVendorAnalytics(targetRouter[0].id);
      await this.fetchRouterSpeedAnalytics(targetRouter[0].serial_number);
      await this.fetchNetworkUsageAnalytics(targetRouter[0].serial_number);
      await this.fetchNetworkUsageAndConnectedClientsAnalytics(targetRouter[0].id);
      await this.fetchRouterSignalStrengthAnalytic(targetRouter[0].serial_number);


      console.log('ZABBIX GET HOST INFO');
      const miniServerName = event.target.value;
      console.log("MINI SERVERS: ", this.miniServers);
      console.log("MINI SERVER NAME: ", miniServerName);
      const hostid = this.miniServers.filter(
        (x: any) => x.host === miniServerName
      )[0].hostid;
      console.log('HOST ID: ', hostid);

      var payload_is_connected = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'icmpping', name: 'ICMP ping' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };
      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_is_connected
        )
        .then((response: any) => {
          if (response.status === 200) {
            let online = response.data.result[0].lastvalue;
            console.log('this is online or offline status', online);
            if (online === '1') {
              this.info.is_online = true;

              console.log('Online');
            } else {
              this.info.is_online = false;
              console.log('Offline');
            }
            console.log(response.data.result);
          } else {
            this.$toast.error(`Could not get the hosts.`, {
              position: 'top-right',
            });
          }
        });


      // SERVICES STATUS

      console.log("BOX ONLINE STATUS: ", this.info.is_online);

      if (this.info.is_online) {

        // BOX LOCATION SERVICE STATUS

        const boxLocationPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["box-location-update.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            boxLocationPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('BOX LOCATION UPDATE LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.boxLocationUpdateServiceStatus = 'active'
              } else {
                this.info.boxLocationUpdateServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - BOX LOCATION UPDATE');
            }
          });

        // BOX LOCATION SERVICE STATUS

        const boxOSVersionPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["box-os-version-update.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            boxOSVersionPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('BOX OS VERSION UPDATE LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.boxOSVersionUpdateServiceStatus = 'active'
              } else {
                this.info.boxOSVersionUpdateServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - BOX OS VERSION UPDATE');
            }
          });


        // CAPTIVE PORTAL DB INIT SERVICE STATUS

        const captivePortalDbInitPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["captive-portal-db-init.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            captivePortalDbInitPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('CAPTIVE PORTAL DB INIT LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.captivePortalDbInitServiceStatus = 'active'
              } else {
                this.info.captivePortalDbInitServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - CAPTIVE PORTAL DB INIT');
            }
          });


        // CAPTIVE PORTAL DB SYNC SERVICE STATUS

        const captivePortalDbSyncPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["captive-portal-db-sync.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            captivePortalDbSyncPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('CAPTIVE PORTAL DB SYNC LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.captivePortalDbSyncServiceStatus = 'active'
              } else {
                this.info.captivePortalDbSyncServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - CAPTIVE PORTAL DB SYNC');
            }
          });


        // COOVACHILLI SERVICE STATUS

        const chilliPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["chilli.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            chilliPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('COOVACHILLI LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.coovaChilliServiceStatus = 'active'
              } else {
                this.info.coovaChilliServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - COOVA CHILLI');
            }
          });


        // HOSTAPD SERVICE STATUS

        const hostapdPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["hostapd.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            hostapdPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('HOSTAPD LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.hostapdServiceStatus = 'active'
              } else {
                this.info.hostapdServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - HOSTAPD');
            }
          });


        // MEDIA SYNC SERVICE STATUS

        const mediaSyncPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["media-sync.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            mediaSyncPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('MEDIA SYNC LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.mediaSyncServiceStatus = 'active'
              } else {
                this.info.mediaSyncServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - MEDIA SYNC');
            }
          });


        // SSID UPDATE SERVICE STATUS

        const ssidPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["ssid_run.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            ssidPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('SSID RUN LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.ssidServiceStatus = 'active'
              } else {
                this.info.ssidServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - SSID RUN');
            }
          });

        // WEBMIN SERVICE STATUS

        const webminPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["webmin.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            webminPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('WEBMIN LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.webminServiceStatus = 'active'
              } else {
                this.info.webminServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - WEBMIN');
            }
          });


        // MOTION EYE SERVICE STATUS

        const motionEyePayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["motioneye-control.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            motionEyePayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('MOTIONEYE CONTAINER LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.motionEyeServiceStatus = 'active'
              } else {
                this.info.motionEyeServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - MOTIONEYE CONTAINER');
            }
          });

        // ======================================================

      } else {
        this.info.boxLocationUpdateServiceStatus = 'offline';
        this.info.boxOSVersionUpdateServiceStatus = 'offline';
        this.info.captivePortalDbInitServiceStatus = 'offline';
        this.info.captivePortalDbSyncServiceStatus = 'offline';
        this.info.coovaChilliServiceStatus = 'offline';
        this.info.hostapdServiceStatus = 'offline';
        this.info.mediaSyncServiceStatus = 'offline';
        this.info.ssidServiceStatus = 'offline';
        this.info.webminServiceStatus = 'offline';
        this.info.motionEyeServiceStatus = 'offline';
      }
    },
    updateActiveData() {
      const range = this.selectedRange;
      this.insightAnalytics = {
        ...this.insightAnalytics,
        dwell_time: this.insightAnalytics.dwell_time_ranges[range],
        first_time_visitor:
          this.insightAnalytics.first_time_visitor_ranges[range],
        peek_time: this.insightAnalytics.peek_time_ranges[range],
        phone_model: this.insightAnalytics.phone_model_ranges[range],
        returned_customers:
          this.insightAnalytics.returned_customer_ranges[range],
        unique_customers: this.insightAnalytics.unique_customer_ranges[range],
      };
    },
  },
  mounted() {
    this.loginZabbix();
  },
});
</script>
