<template>
    <!-- base -->
    
    <headerComponent/>
    <p class="text-center">
        <a class="absolute inset-y-0 left-0 justify-center relative color-blue"> You have not configured any camera yet. Click here to add one...</a>
    </p>
    
</template>
<script>
import headerComponent from './header.vue'

export default{
    name: 'shirikiEyePage',
    components: {
        headerComponent,
    },
}
</script>
<style>
    * {
        padding: 0px;
        border: 0px solid black;
        margin: 0px;
        outline: 0px;
        border-spacing: 0px;
        border-collapse: separate;
    }

</style>