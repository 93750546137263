<template>
  <div class="m-2 p-3">
    <p>Filter</p>
    <select v-model="filter" @change="filterChanged">
      <option value="1">today</option>
      <option value="7">1 week</option>
      <option value="30">1 month</option>
      <option value="90">3 months</option>
      <option value="180">6 months</option>
      <option value="365">1 year</option>
    </select>
  </div>

  <div class="flex flex-col sm:flex-row">
    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3">
      <h1 class="border-b border-solid border-blue-500">Overall Average Rating</h1>
      <h1 class="text-center text-7xl font-extrabold mt-16">
        {{ overall_average_rating }}<i class="fa-solid fa-star text-5xl relative top-[-8px]"></i>
      </h1>
      <p class="text-center mb-16">{{ total_ratings }} Total Ratings</p>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
      <h1 class="border-b border-solid border-blue-500 mb-3">Distribution of Ratings</h1>
      <canvas id="ratingDistributionChart"></canvas>
    </div>
  </div>

  <div class="flex flex-col sm:flex-row">
    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
      <h1 class="border-b border-solid border-blue-500 mb-3">Trends Over Time</h1>
      <canvas id="trendsOverTimeChart" class="mt-2"></canvas>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
      <h1 class="border-b border-solid border-blue-500 mb-3">Feedback Frequency</h1>
      <canvas id="feedbackFrequencyChart" class="mt-2"></canvas>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chart from 'chart.js/auto'
import instance from '../../axios-interceptor'

export default {
  name: 'OverallRating',
  data() {
    return {
      filter: 30,
      total_ratings: 0,
      overall_average_rating: 0.0,

      feedbackFrequencyChart: null,
      trendsOverTimeChart: null,
      ratingDistributionChart: null
    }
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  mounted() {
    this.filterChanged()
  },
  methods: {
    filterChanged() {
      const today = new Date()
      let endDate = today.toISOString().split('T')[0]

      const daysAgo = new Date(today.getTime() - parseInt(this.filter) * 24 * 60 * 60 * 1000)
      let startDate = daysAgo.toISOString().split('T')[0]

      this.$emit('filterData', { startDate: startDate, endDate: endDate })

      instance
        .get('rating/analytics/?sme=' + this.loggedInUser.sme.id + '&start_date=' + startDate + '&end_date=' + endDate + '&days=' + this.filter)
        .then((response) => {
          this.total_ratings = response.data.total_ratings
          this.overall_average_rating = response.data.overall_average_rating.toFixed(2)

          this.createOrUpdateRatingDistributionChart(response.data.distribution_of_ratings.labels, response.data.distribution_of_ratings.data)
          this.createOrUpdateTrendsOverTimeChart(response.data.trends_over_time.labels, response.data.trends_over_time.data)
          this.createOrUpdateFeedbackFrequencyChart(response.data.feedback_frequency.labels, response.data.feedback_frequency.data)
        })
        .catch((error) => {
          console.log(error);
        })
    },
    createOrUpdateFeedbackFrequencyChart(rlabels, rdata) {
      const feedbackFrequencyChartInstance = document.getElementById('feedbackFrequencyChart')
      if (this.feedbackFrequencyChart)
        this.feedbackFrequencyChart.destroy()

      this.feedbackFrequencyChart = new Chart(feedbackFrequencyChartInstance, {
        type: 'bar',
        data: {
          labels: rlabels,
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            backgroundColor: 'rgba(154, 208, 245, 0.8)'
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },
    createOrUpdateTrendsOverTimeChart(rlabels, rdata) {
      const trendsOverTimeChartInstance = document.getElementById('trendsOverTimeChart')
      if (this.trendsOverTimeChart)
        this.trendsOverTimeChart.destroy()

      this.trendsOverTimeChart = new Chart(trendsOverTimeChartInstance, {
        type: 'line',
        data: {
          labels: rlabels,
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            borderColor: 'rgba(255, 102, 0, 0.8)',
            backgroundColor: 'rgba(255, 0, 0, 1)'
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },
    createOrUpdateRatingDistributionChart(rlabels, rdata) {
      const ratingDistributionChartInstance = document.getElementById('ratingDistributionChart')

      if (this.ratingDistributionChart)
        this.ratingDistributionChart.destroy()

      this.ratingDistributionChart = new Chart(ratingDistributionChartInstance, {
        type: 'bar',
        data: {
          labels: ['\u2605', '\u2605\u2605', '\u2605\u2605\u2605', '\u2605\u2605\u2605\u2605', '\u2605\u2605\u2605\u2605\u2605'],//rlabels,
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            backgroundColor: 'rgba(255, 102, 0, 0.8)'
          }]
        },
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    }
  }
}
</script>
