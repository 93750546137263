<template>
  <div class="flex justify-center items-center bg-gray-100">
    <div class="max-w-lg p-6 bg-white rounded-lg shadow">
      <h2 class="text-2xl font-bold mb-4 text-[#f24236]">Payment Required</h2>
      <p class="text-gray-600 mb-4">
        5 days passed without the payment.
      </p>
      <p class="text-gray-600 mb-4">
        You cannot access this page until you pay for the outstanding billings.
        Please go to the billings page to make the payment.
      </p>
      <button
        type="button"
        class="w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] p-3 font-medium mt-2"
        @click="redirectToBillings"
      >
        Go to Billings
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentRequiredMessage',
  methods: {
    redirectToBillings() {
      router.push({ path: '/billings' });
    },
  },
});
</script>

<style>
</style>
