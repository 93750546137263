<template>
    <div class="popup-modal">
        <div class="popup-modal-content rounded-xl">
            <button @click="$emit('close')" class="float-right" v-if="activeSection === 'before-payment-request'">
                <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
            </button>
            <div class="row">
                <div class="col-md-12 mx-auto">
                    <!-- PAYMENT REQUEST SECTION -->
                    <div v-if="activeSection === 'before-payment-request'">
                        <h3
                            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
                            Billing Payment
                        </h3>

                        <p class="text-sm mb-2" v-if="!billing.is_paid">
                            1. starter subscription plan: {{ Number(billing.subscription_plan.price).toLocaleString() }}
                            RWF
                            <span class="text-[#f24236]" v-if="!billing.is_paid">
                                (not paid)
                            </span>
                            <span class="text-primary" v-else>
                                (paid)
                            </span>
                        </p>

                        <div v-if="billing.billed_addons?.length">
                            <div v-for="(billedAddOn, index) in billing.billed_addons" :key="billedAddOn.id">
                                <p class="text-sm mb-1">

                                    {{ index + 2 }}. {{ billedAddOn.addon.name }} addon: {{
                                        Number(billedAddOn.charged_amount).toLocaleString() }} RWF

                                    <span class="text-primary" v-if="isAddOnPaid(billedAddOn.id)">
                                        (paid)
                                    </span>
                                    <span class="text-[#f24236]" v-if="!isAddOnPaid(billedAddOn.id)">
                                        (not paid)
                                    </span>
                                </p>
                                <p class="text-sm mb-2 ml-4 text-gray">
                                    <span v-if="billedAddOn.is_paid"> {{
                                        moment(billedAddOn.start_date).format("DD-MM-YYYY") }} - {{
                                            moment(billedAddOn.end_date).format("DD-MM-YYYY") }} | For Previous
                                        Billing</span>
                                </p>
                            </div>

                        </div>

                        <form>
                            <div class="mb-2 mt-6">
                                <label for="small-input"
                                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Payment
                                    Method:</label>
                                <select id="small-input"
                                    class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                                    v-model="paymentMethod" @change="handleChange" required>
                                    <option value="momo">MoMo</option>
                                </select>
                            </div>
                            <div class="mb-2">
                                <label for="small-input"
                                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Phone
                                    Number:</label>
                                <input placeholder="mtn phone number: 25078 or 25079" v-model="phone" required
                                    type="number" id="small-input"
                                    class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
                            </div>
                            <div class="mb-2">
                                <label for="small-input"
                                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Amount
                                    in RWF:</label>
                                <input v-if="!discounted_amount" :value="Number(billing.final_amount)" type="number"
                                    min="100" id="small-input" readonly
                                    class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
                                <input v-else :value="Number(discounted_amount)" type="number" min="100"
                                    id="small-input" readonly
                                    class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />

                                <p v-if="discounted_amount" class="text-primary text-sm mt-2">{{ percentage_discount }}%
                                    discount applied to the original amount {{
                                        Number(billing.final_amount).toLocaleString()
                                    }} RWF. You will now pay {{
                                        Number(discounted_amount).toLocaleString() }} RWF</p>

                            </div>

                            <div class="mb-2">
                                <label for="small-input"
                                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Discount
                                    Coupon
                                    :</label>
                                <input placeholder="Enter discount coupon (optional)" v-model="discount_coupon"
                                    type="text" id="small-input"
                                    class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
                            </div>

                            <p v-if="error" class="text-[#f24236] text-sm my-4">{{ error }}</p>

                            <p class="text-xs mb-1 text-[#bd7f40]">
                                <strong>NOTE:</strong> Ensure you have sufficient funds in your MTN Mobile Money account
                                before clicking
                                the pay button. If your balance is insufficient, you won't receive a payment prompt.
                                Please top up your Mobile Money account with the required billing amount before
                                proceeding.
                            </p>


                            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
                                <button v-if="!loading && !discount_coupon" @click="processPayment()" type="button"
                                    class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                    PAY
                                </button>
                                <button v-else-if="!loading && discount_coupon && !discounted_amount"
                                    @click="verifyCoupon()" type="button"
                                    class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                    VERIFY COUPON
                                </button>
                                <button v-else-if="!loading && discount_coupon && discounted_amount"
                                    @click="processPayment()" type="button"
                                    class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                    PAY
                                </button>
                                <button v-else type="button"
                                    class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                    PROCESSING...
                                </button>
                            </div>
                        </form>
                    </div>

                    <!-- AFTER PAYMENT REQUEST SECTION -->
                    <div v-if="activeSection === 'after-payment-request'">
                        <h3
                            class="text-primary text-center mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem]">
                            PAYMENT REQUEST PROMPT SENT
                        </h3>

                        <div class="text-routersTextColor">

                            <p class="mt-3 text-sm">

                                We have sent a payment request to your MTN Mobile Money number: {{ this.phone }}.
                            </p>

                            <p class="mt-3 text-sm">


                                1. Approve the payment request if you have sufficient balance.

                            </p>

                            <p class="mt-3 text-sm">


                                2. If no prompt received, dial *182*7# to check for pending requests. Choose 1, enter
                                your PIN, and choose 1 to approve the payment. If you don't find any, restart the process.

                            </p>

                            <p class="mt-3 text-sm">

                                3. Approve within 10 minutes. If it expires, restart the process on the billing page.
                            </p>


                            <p class="mt-3 text-sm">

                                4. After approval, refresh the page after 3 minutes to restore access.
                            </p>


                            <p class="mt-3 text-sm">

                                You will receive email and SMS notifications about your payment status.

                            </p>



                        </div>

                        <div class="flex justify-center">
                            <button type="button" @click="$emit('close')"
                                class="w-1/4 font-['poppins'] text-[15px] bg-routersTextColor text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center mt-6">
                                NOTED
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import axios from '@/axios-interceptor';

import { Billing } from '@/interfaces/billing'


export default defineComponent({
    name: 'BillingPaymentModal',
    props: {
        billing: {
            type: Billing
        },
        processing: {
            type: Boolean
        },
    },
    data() {
        return {
            loading: false,
            paymentMethod: 'momo',
            phone: '',
            discount_coupon: null,
            error: '',
            discounted_amount: null,
            percentage_discount: 0,
            activeSection: 'before-payment-request'
        };
    },
    computed: {
        ...mapGetters(['loggedInUser']),
    },
    methods: {
        moment,
        isAddOnPaid(addOnId) {
            // Iterate through the billing addons array
            for (const addOn of this.billing.billed_addons) {
                // Check if the current addon's ID matches the given one
                if (addOn.id === addOnId) {
                    // Return true if the addon is paid, false otherwise
                    console.log("MATCHING ADDON FOUND");
                    return addOn.is_paid;
                }
            }

            console.log("MATCHING ADDON NOT FOUND");

            // If no matching addon is found, return false
            return false;
        },
        verifyCoupon() {
            this.error = '';
            this.loading = true;
            let payload = {
                billing: this.billing.id,
                discount_coupon: this.discount_coupon,
            };

            axios
                .post('discount-coupon/verify/', payload)
                .then((res) => {
                    this.$toast.success('Discount applied', {
                        position: 'top-right',
                    });
                    this.loading = false;
                    this.discounted_amount = res.data.discounted_amount;
                    this.percentage_discount = res.data.percentage_discount;
                })
                .catch((error) => {
                    this.loading = false;

                    if (error.response.status === 400) {
                        this.error = error.response.data.message;
                    } else if (error.response.status === 404) {
                        this.$toast.error(error.response.data.message, {
                            position: 'top-right',
                        });
                    }
                    else {
                        this.$toast.error('Could not process the payment', {
                            position: 'top-right',
                        });
                    }

                });
        },
        isValidMTNPhoneNumber(phoneNumber) {
            // Regular expression pattern to match the required MTN Rwanda phone number formats
            const mtnPattern = /^2507[89]\d{7}$/;

            // Test the phone number against the pattern
            return mtnPattern.test(phoneNumber);

        },
        processPayment() {
            if (!this.phone) {
                return this.$toast.warning("Enter your momo phone number for payment", {
                    position: 'top-right',
                });
            }

            if (!this.isValidMTNPhoneNumber(this.phone)) {
                return this.$toast.error("Invalid MTN Rwanda phone number. Please enter a valid 12-digit number starting with 25078 or 25079.", {
                    position: 'top-right',
                    duration: 10000,
                });
            }

            this.error = '';
            this.loading = true;
            let payload = {
                billing: this.billing.id,
                amount: this.billing.final_amount,
                phone_number: this.phone,
                request_type: "GENERAL BILLING"
            };

            if (this.discount_coupon && this.discounted_amount) {
                payload.discount_coupon = this.discount_coupon
                payload.amount = this.discounted_amount
            }

            axios
                .post('partner/billing/momo-payment-request/', payload)
                .then(() => {
                    this.$toast.success('Payment request sent to your mobile money number', {
                        position: 'top-right',
                    });
                    this.loading = false;
                    this.activeSection = "after-payment-request"
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error('Could not send mobile money payment request to your phone number', {
                        position: 'top-right',
                    });

                });
        },
    },
});
</script>
