import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%]" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center h-full relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "lg:w-[50%]" }
const _hoisted_13 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, " Change Password ", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-poppins font-normal text-[14px] mt-2 lg:text-[#838282] text-[#4E8D6E] text-center" }, " You are required to change the default password you received in your email after the registration ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                name: "oldPassword",
                class: "mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Current Password",
                type: "password",
                modelValue: _ctx.v$.currentPassword.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.currentPassword.$model) = $event)),
                showErrors: _ctx.v$.currentPassword.$errors.length ? true : false,
                errors: _ctx.v$.currentPassword.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "newPassword",
                class: "mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "New Password",
                type: "password",
                modelValue: _ctx.v$.newPassword.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.newPassword.$model) = $event)),
                showErrors: _ctx.v$.newPassword.$errors.length ? true : false,
                errors: _ctx.v$.newPassword.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "confirmPassword",
                class: "font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Confirm New Password",
                type: "password",
                modelValue: _ctx.v$.confirmNewPassword.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.confirmNewPassword.$model) = $event)),
                showErrors: 
                  _ctx.v$.confirmNewPassword.$errors.length ? true : false
                ,
                errors: _ctx.v$.confirmNewPassword.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.passwordsNotMatch)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
                    _createElementVNode("span", { class: "text-red text-[12px] mt-2" }, "Passwords must match", -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.passwordErrors.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordErrors, (error) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: error,
                        class: "text-red text-[12px] mt-2"
                      }, _toDisplayString(error), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      disabled: _ctx.v$.$invalid,
                      type: "button",
                      class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2 mt-10",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)))
                    }, _toDisplayString(_ctx.buttonText), 9, _hoisted_10))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      disabled: _ctx.v$.$invalid,
                      type: "button",
                      class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2 mt-10"
                    }, " Processing... ", 8, _hoisted_11))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_auth_right_image)
      ])
    ])
  ]))
}