<template>
    <div>
      <table class="settings">
          <tr class="settings-item" :required="true" strip="true">
            <td class="settings-item-label"><span class="text-sm">Admin Username</span></td>
            <td class="settings-item-value">
              <input class="border-2 border-green-700 hover:border-green-400 rounded w-3/4 mb-1 leading-tight focus:outline-none focus:shadow-outline" 
              type="text" placeholder="user-name">
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
              the username to be used for configuring the system
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item" strip="true">
            <td class="settings-item-label"><span class="text-sm">Admin Password</span></td>
            <td class="settings-item-value">
              <input type="password" autocomplete="new-password" class="border-2 border-green-700 hover:border-green-400 rounded w-3/4 mb-1 leading-tight focus:outline-none focus:shadow-outline" 
              id="adminPasswordEntry">
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                  administrator's password
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item" :required="true" strip="true">
            <td class="settings-item-label"><span class="text-sm">Surveillance Username</span></td>
            <td class="settings-item-value">
              <input type="text" autocapitalize="none" class="border-2 border-green-700 hover:border-green-400 rounded w-3/4 mb-1 leading-tight focus:outline-none focus:shadow-outline" 
              id="normalUsernameEntry">
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                the username to be used for video surveillance
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item" strip="true">
            <td class="settings-item-label"><span class="text-sm">Surveillance Password</span></td>
            <td class="settings-item-value">
              <input type="password" autocomplete="new-password" class="border-2 border-green-700 hover:border-green-400 rounded w-3/4 mb-2 leading-tight focus:outline-none focus:shadow-outline" 
              id="normalPasswordEntry">
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                  the password for the surveillance user (leave empty for passwordless surveillance)
                  </v-tooltip>
               </i>
                </td>
          </tr>
          <!-- <div class="border-t p-2"></div> -->
          <tr class="settings-item py-2" v-if="enable_reboot">
            <td class="settings-item-label"><span class="text-sm">Power</span></td>
            <td class="settings-item-value">
              <div class="button normal-button shut-down-button" id="shutDownButton">Shut Down</div>
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                  shuts down the system
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item" v-if="enable_reboot">
            <td class="settings-item-label"><span class="settings-item-label"></span></td>
            <td class="settings-item-value">
              <div class="button normal-button reboot-button" id="rebootButton">Reboot</div>
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                  reboots the system
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item">
            <td colspan="100"><div class="settings-item-separator"></div></td>
          </tr>
          <tr class="settings-item py-2">
            <td class="settings-item-label"><span class="text-sm">Configuration</span></td>
            <td class="settings-item-value">
              <button class="bg-primary rounded lg mb-2 px-3"><span class="text-sm">Backup</span></button>
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
                creates a file with the current configuration for you to save it locally
                </v-tooltip>
              </i>
            </td>
          </tr>
          <tr class="settings-item py-2">
            <td class="settings-item-label"><span class="settings-item-label"></span></td>
            <td class="settings-item-value">
              <button class="bg-primary rounded mb-2 px-3"><span class="text-sm">Restore</span></button>
            </td>
            <td>
              <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                <v-tooltip
                  activator="parent"
                  location="bottom"
                  open-delay="10"
                >
              restores the configuration from a previously saved backup file
              </v-tooltip>
              </i>
            </td>
          </tr>
        </table>
      </div>
    </template>
    
    <script>
    export default {
      name: 'generalSettings',
      props: {
        version: String,
        motion_version: String,
        os_version: String,
        enable_update: Boolean,
        enable_reboot: Boolean,
      },
    };
    </script>
    