import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_5 = { class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative" }
const _hoisted_6 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_7 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { id: "TabContent" }
const _hoisted_11 = { class: "w-full md:flex my-6 justify-between" }
const _hoisted_12 = {
  key: 0,
  class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto"
}
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = {
  key: 0,
  class: "mb-0.5 text-primary font-bold"
}
const _hoisted_15 = {
  key: 1,
  class: "mb-0.5 text-[#f24236] font-bold"
}
const _hoisted_16 = {
  key: 2,
  class: "text-[#464E5F] text-xs mt-2 font-normal"
}
const _hoisted_17 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 0,
  class: "text-[#464E5F]"
}
const _hoisted_21 = {
  key: 1,
  class: "text-[#464E5F]"
}
const _hoisted_22 = {
  key: 2,
  class: "text-[#464E5F]"
}
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_25 = { class: "flex flex-col" }
const _hoisted_26 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5"
}
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "flex flex-col" }
const _hoisted_29 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_30 = { class: "flex flex-col" }
const _hoisted_31 = { class: "capitalize text-[#2274a5] mb-0.5" }
const _hoisted_32 = ["href"]
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_36 = {
  key: 1,
  class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto"
}
const _hoisted_37 = { class: "flex flex-col" }
const _hoisted_38 = {
  key: 0,
  class: "mb-0.5 text-primary font-bold"
}
const _hoisted_39 = {
  key: 1,
  class: "mb-0.5 text-[#f24236] font-bold"
}
const _hoisted_40 = {
  key: 2,
  class: "text-[#464E5F] text-xs mt-2 font-normal"
}
const _hoisted_41 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_42 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5"
}
const _hoisted_43 = {
  key: 1,
  class: "capitalize text-[#464E5F] mb-0.5"
}
const _hoisted_44 = { class: "flex flex-col" }
const _hoisted_45 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_46 = { class: "text-[#464E5F]" }
const _hoisted_47 = { class: "flex flex-col" }
const _hoisted_48 = { class: "capitalize text-[#2274a5] mb-0.5" }
const _hoisted_49 = ["href"]
const _hoisted_50 = ["onClick"]
const _hoisted_51 = ["onClick"]
const _hoisted_52 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_billing_payment_modal = _resolveComponent("billing-payment-modal")!
  const _component_sms_payment_modal = _resolveComponent("sms-payment-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Billing",
      description: "",
      icon: _ctx.icon.Billing
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[4] || (_cache[4] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("ul", _hoisted_7, [
                  _createElementVNode("li", _hoisted_8, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'subscription'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                      id: "microfranchisee",
                      "data-tabs-target": "#subscription",
                      type: "button",
                      role: "tab",
                      "aria-controls": "subscription",
                      "aria-selected": "false",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetActiveTab('subscription')))
                    }, " Subscription Plan + Add-Ons ", 2)
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'sms'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                      id: "routers",
                      "data-tabs-target": "#sms",
                      type: "button",
                      role: "tab",
                      "aria-controls": "routers",
                      "aria-selected": "false",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetActiveTab('sms')))
                    }, " SMS Package ", 2)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_ctx.activeTab == 'subscription')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_DashboardTableTitle, {
                          perPageItems: _ctx.perSize,
                          pageNumber: _ctx.page,
                          ApiData: [],
                          onHandleNext: () => { },
                          onHandlePrev: () => { }
                        }, {
                          section: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", { class: "mt-5 flex items-center" }, [
                                _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page"),
                                _createElementVNode("span", { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }, [
                                  _createElementVNode("select", null, [
                                    _createElementVNode("option", { value: "10" }, "10"),
                                    _createElementVNode("option", { value: "20" }, "20"),
                                    _createElementVNode("option", { value: "30" }, "30"),
                                    _createElementVNode("option", { value: "40" }, "40"),
                                    _createElementVNode("option", { value: "50" }, "50")
                                  ])
                                ])
                              ])
                            ], -1)
                          ])),
                          _: 1
                        }, 8, ["perPageItems", "pageNumber"]),
                        _createVNode(_component_SmeTable, { tableHeaderData: _ctx.subscriptionBillingTableHeaders }, {
                          tableBodyData: _withCtx(() => [
                            (_ctx.subscriptionBillings.length)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.subscriptionBillings, (billing) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: billing.id,
                                    class: "table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between font-poppins text-[0.673rem]"
                                  }, [
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_13, [
                                        (billing.is_paid)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString('Yes, you paid for this billing')))
                                          : _createCommentVNode("", true),
                                        (!billing.is_paid)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString('No, you have not paid for this billing')))
                                          : _createCommentVNode("", true),
                                        (!billing.is_paid && billing.due_date <= _ctx.today
                          )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[7] || (_cache[7] = [
                                              _createElementVNode("p", null, "Please pay for this billing", -1)
                                            ])))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("span", _hoisted_17, [
                                        _createElementVNode("strong", null, _toDisplayString(_ctx.moment(billing.due_date).format('D-MMM-YYYY')), 1)
                                      ])
                                    ]),
                                    (!billing.is_paid)
                                      ? (_openBlock(), _createElementBlock("td", _hoisted_18, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.billingStartDate), 1),
                                          _cache[8] || (_cache[8] = _createTextVNode(" to ")),
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.billingEndDate), 1)
                                        ]))
                                      : _createCommentVNode("", true),
                                    (billing.is_paid)
                                      ? (_openBlock(), _createElementBlock("td", _hoisted_19, [
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.moment(billing.start_date).format('D-MMM-YYYY')), 1),
                                          _cache[9] || (_cache[9] = _createTextVNode(" to ")),
                                          _createElementVNode("strong", null, _toDisplayString(_ctx.moment(billing.end_date).format('D-MMM-YYYY')), 1)
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("td", null, [
                                      (!billing.is_paid)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(Number(billing.final_amount).toLocaleString()) + " RWF", 1))
                                        : (billing.is_paid)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(Number(billing.final_charged_amount).toLocaleString()) + " RWF", 1))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(Number(billing.amount).toLocaleString()) + " RWF", 1))
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("span", _hoisted_24, _toDisplayString(billing.subscription_plan.name), 1)
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_25, [
                                        (billing.billed_addons?.length)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(billing.billed_addons.map(billedAddon => billedAddon.addon.name).join(', ')), 1))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_27, " None "))
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_28, [
                                        _createElementVNode("span", _hoisted_29, _toDisplayString(billing.sme.partner.name), 1)
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_30, [
                                        _createElementVNode("span", _hoisted_31, [
                                          _createElementVNode("a", {
                                            href: billing.billing_invoices[0]?.invoice_ninja_link
                              ,
                                            target: "_blank"
                                          }, "View Invoice", 8, _hoisted_32)
                                        ])
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      (!billing.is_paid)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.initiatePayment(billing))
                                          }, _cache[10] || (_cache[10] = [
                                            _createElementVNode("span", { class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer" }, "Pay", -1)
                                          ]), 8, _hoisted_33))
                                        : (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            onClick: ($event: any) => (_ctx.viewBillingDetails(billing))
                                          }, _cache[11] || (_cache[11] = [
                                            _createElementVNode("span", { class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer" }, "View", -1)
                                          ]), 8, _hoisted_34))
                                    ])
                                  ]))
                                }), 128))
                              : (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[12] || (_cache[12] = [
                                  _createElementVNode("b", null, "No Starter Subscription Billings found", -1)
                                ])))
                          ]),
                          _: 1
                        }, 8, ["tableHeaderData"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.activeTab == 'sms')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                        _createVNode(_component_DashboardTableTitle, {
                          perPageItems: _ctx.perSize,
                          pageNumber: _ctx.page,
                          ApiData: [],
                          onHandleNext: () => { },
                          onHandlePrev: () => { }
                        }, {
                          section: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", { class: "mt-5 flex items-center" }, [
                                _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page"),
                                _createElementVNode("span", { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }, [
                                  _createElementVNode("select", null, [
                                    _createElementVNode("option", { value: "10" }, "10"),
                                    _createElementVNode("option", { value: "20" }, "20"),
                                    _createElementVNode("option", { value: "30" }, "30"),
                                    _createElementVNode("option", { value: "40" }, "40"),
                                    _createElementVNode("option", { value: "50" }, "50")
                                  ])
                                ])
                              ])
                            ], -1)
                          ])),
                          _: 1
                        }, 8, ["perPageItems", "pageNumber"]),
                        _createVNode(_component_SmeTable, { tableHeaderData: _ctx.smsBillingTableHeaders }, {
                          tableBodyData: _withCtx(() => [
                            (_ctx.smsBillings.length)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.smsBillings, (billing) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: billing.id,
                                    class: "table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                  }, [
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_37, [
                                        (billing.is_paid)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString('Yes, you paid for this billing')))
                                          : _createCommentVNode("", true),
                                        (!billing.is_paid)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString('No, you have not paid for this billing')))
                                          : _createCommentVNode("", true),
                                        (!billing.is_paid && billing.due_date <= _ctx.today
                          )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_40, _cache[14] || (_cache[14] = [
                                              _createElementVNode("p", null, "Please pay for this billing", -1)
                                            ])))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.moment(billing.due_date).format('D-MMM-YYYY')), 1)
                                    ]),
                                    (!billing.is_paid)
                                      ? (_openBlock(), _createElementBlock("td", _hoisted_42, _toDisplayString(_ctx.billingStartDate) + " to " + _toDisplayString(_ctx.billingEndDate), 1))
                                      : (_openBlock(), _createElementBlock("td", _hoisted_43, _toDisplayString(_ctx.moment(billing.start_date).format('D-MMM-YYYY')) + " to " + _toDisplayString(_ctx.moment(billing.end_date).format('D-MMM-YYYY')), 1)),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_44, [
                                        _createElementVNode("span", _hoisted_45, _toDisplayString(billing.sms_package.name.split('_')[0]) + " SMS", 1)
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("span", _hoisted_46, _toDisplayString(billing.amount) + " RWF", 1)
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", _hoisted_47, [
                                        _createElementVNode("span", _hoisted_48, [
                                          _createElementVNode("a", {
                                            href: billing.sms_package_billing_invoices[0]
                              ?.invoice_ninja_link
                              ,
                                            target: "_blank"
                                          }, "View Invoice", 8, _hoisted_49)
                                        ])
                                      ])
                                    ]),
                                    _createElementVNode("td", null, [
                                      (!billing.is_paid)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.displaySMSPaymentModal(billing))
                                          }, _cache[15] || (_cache[15] = [
                                            _createElementVNode("span", { class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer" }, "Pay", -1)
                                          ]), 8, _hoisted_50))
                                        : (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            onClick: ($event: any) => (_ctx.viewBillingDetails(billing))
                                          }, _cache[16] || (_cache[16] = [
                                            _createElementVNode("span", { class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer" }, "View", -1)
                                          ]), 8, _hoisted_51))
                                    ])
                                  ]))
                                }), 128))
                              : (_openBlock(), _createElementBlock("div", _hoisted_52, _cache[17] || (_cache[17] = [
                                  _createElementVNode("b", null, "No SMS Billings found", -1)
                                ])))
                          ]),
                          _: 1
                        }, 8, ["tableHeaderData"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])),
    (_ctx.showBillingPaymentModal)
      ? (_openBlock(), _createBlock(_component_billing_payment_modal, {
          key: 2,
          billing: _ctx.selectedBilling,
          processing: _ctx.processingPayment,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showBillingPaymentModal = !_ctx.showBillingPaymentModal))
        }, null, 8, ["billing", "processing"]))
      : _createCommentVNode("", true),
    (_ctx.showSMSPaymentModal)
      ? (_openBlock(), _createBlock(_component_sms_payment_modal, {
          key: 3,
          loading: _ctx.processingSMSBillingPayment,
          smsPackage: _ctx.selectedSMSPackage,
          onPayForSms: _ctx.payForSMSBilling,
          smsBilling: _ctx.selectedSMSBilling,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSMSPaymentModal = !_ctx.showSMSPaymentModal))
        }, null, 8, ["loading", "smsPackage", "onPayForSms", "smsBilling"]))
      : _createCommentVNode("", true)
  ], 64))
}