import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%]" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center h-full relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = { class: "w-full flex lg:justify-end justify-start items-center -mt-3 mb-6" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "w-full flex justify-center items-center my-6" }
const _hoisted_10 = { class: "font-poppins font-normal text-[#838282] text-[12.9px]" }
const _hoisted_11 = { class: "lg:w-[50%]" }
const _hoisted_12 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, " Sign In ", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "font-poppins lg:font-bold lg:text-[19px] font-normal text-[15px] mt-2 tracking-widest text-primary text-center" }, " ARED SME Platform ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                name: "email",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Email",
                type: "email",
                modelValue: _ctx.v$.email.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
                showErrors: _ctx.v$.email.$errors.length ? true : false,
                errors: _ctx.v$.email.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "password",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Password",
                type: "password",
                modelValue: _ctx.v$.password.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.password.$model) = $event)),
                showErrors: _ctx.v$.password.$errors.length ? true : false,
                errors: _ctx.v$.password.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleForgotPassword && _ctx.handleForgotPassword(...args)))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "text-success font-poppins font-medium text-[0.778rem] lg:text-[#4D99FF] text-[#4E8D6E]" }, "Forgot Password?", -1)
                ]))
              ]),
              _createElementVNode("button", {
                disabled: _ctx.v$.$invalid || _ctx.loading,
                type: "button",
                class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)))
              }, _toDisplayString(_ctx.loginBtn.text), 9, _hoisted_8),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _cache[6] || (_cache[6] = _createTextVNode("Don't have an account yet? ")),
                  _createElementVNode("button", {
                    type: "button",
                    class: "text-success font-semibold lg:text-[#4D99FF] text-[#4E8D6E]",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleSignup && _ctx.handleSignup(...args)))
                  }, " Sign up ")
                ])
              ])
            ])
          ])
        ]),
        _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"max-form w-full absolute bottom-5 justify-between items-center lg:flex hidden\" data-v-1c15bf92><ul class=\"flex\" data-v-1c15bf92><li data-v-1c15bf92><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-1c15bf92><a href=\"javascript:void(0)\" data-v-1c15bf92>Terms</a></span></li><li data-v-1c15bf92><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-1c15bf92><a href=\"javascript:void(0)\" data-v-1c15bf92>Plans</a></span></li><li data-v-1c15bf92><span class=\"text-success font-poppins font-medium text-[0.778rem]\" data-v-1c15bf92><a href=\"javascript:void(0)\" data-v-1c15bf92>Contact Us</a></span></li></ul></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_auth_right_image)
      ])
    ])
  ]))
}