<template>
    <div class="rounded-lg bg-white" >
      <button class="p-4">
        <i class="fa-solid fa-bars fa-xl" style="color: #1f5127;">
          <v-tooltip
            activator="parent"
            location="bottom"
          >Settings</v-tooltip>
        </i>
  
        <v-menu  activator="parent" width="450">
          <v-list>
            <v-list-item class="flex justify-between items-center bg-white-400">
              <select class="text-center py-2 px-3 rounded border-green-700 hover:border-green-400 border-2">
                <option value=""></option>
                <option>add camera...</option>
              </select>
              <!-- options --> 
              <button class="rounded-full outline outline-3 outline-green inline-flex justify-end p-3 m-2">
                <i class="fa-solid fa-trash-can fa-xl" style="color: #1f5127;">
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="10"
                  >remove camera</v-tooltip>
                </i>
              </button>
              <span class="text-xl">Shiriki Eye</span>
            </v-list-item>
            <div class="border-t"></div>
  
            <!-- tables -->
            <v-list-item >
              <p class="text-right p-2"> 
                <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="10"
                  >user preferences</v-tooltip>
                </i>
                Preferences 
                <i class="fa-solid fa-caret-down" style="color: #1f5127;"></i>
              </p>
              <settingsPreferences/>
            </v-list-item>
            <div class="border-t"></div>
            <v-list-item>
              <p class="text-right p-2"> 
                <i class="fa-regular fa-circle-question" style="color: #1f5127;">
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                    open-delay="10"
                  >general settings, not related to any camera
                  </v-tooltip>
                </i>
                General Settings
                <i class="fa-solid fa-caret-down" style="color: #1f5127;"></i>
              </p>
              <generalSettings/>
            </v-list-item>
          </v-list>
        </v-menu>
      </button>        
    </div>
  </template>
  
  <script>
  import settingsPreferences from './preferences.vue'
  import generalSettings from './generalSettings.vue'
  
  export default {
    name: 'headerComponent',
    components: {
      settingsPreferences,
      generalSettings,
    },
    props: {
    },
    data: () => ({
      showMenu: false,
    }),
    methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    }
  }
  };
  </script>
  