import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/show-password.png'
import _imports_1 from '../../assets/hide-password.png'


const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["value", "type", "name", "placeholder"]
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  alt: "eye",
  class: "h-full"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_1,
  alt: "eye",
  class: "h-full"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        value: _ctx.modelValue,
        type: _ctx.type === 'password' ? _ctx.passwordTextField : _ctx.type,
        name: _ctx.name,
        placeholder: _ctx.placeholder,
        class: _normalizeClass(["w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2", [
          _ctx.showErrors ? 'border-red' : 'border-textFieldBorder',
          _ctx.classes ? _ctx.classes : '',
        ]]),
        onInput: _cache[0] || (_cache[0] = ($event) => _ctx.handleEmitUpdateValue($event))
      }, null, 42, _hoisted_3),
      (_ctx.type == 'password' || (_ctx.type === 'text' && _ctx.showPassword))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "z-10 -ml-8 cursor-pointer h-[1rem]",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHideOrShowPassword && _ctx.onHideOrShowPassword(...args)))
          }, [
            (!_ctx.showPassword)
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "text-red text-[12px]",
              key: index
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}