import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = {
  key: 2,
  class: "w-full my-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { style: { textAlign: 'right' } }
const _hoisted_8 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_activate_subscription_message = _resolveComponent("activate-subscription-message")!
  const _component_payment_required_message = _resolveComponent("payment-required-message")!
  const _component_shirikiEyePage = _resolveComponent("shirikiEyePage")!
  const _component_activate_add_on = _resolveComponent("activate-add-on")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Shiriki Eye",
      icon: _ctx.shirikiEyeIcon
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[1] || (_cache[1] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (!_ctx.loggedInUser.sme.subscription_plan)
        ? (_openBlock(), _createBlock(_component_activate_subscription_message, { key: 1 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_payment_required_message)
                ]))
              : (_ctx.loggedInUser.sme.has_motion_eye_feature_enabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (!_ctx.disactivating)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                            type: "button",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disactivateAddon('motion-ui-addon')))
                          }, " Deactivate Motion Eye Add-On "))
                        : (_openBlock(), _createElementBlock("button", _hoisted_8, " Disactivating... "))
                    ]),
                    _createVNode(_component_shirikiEyePage)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_activate_add_on, {
                      price: 25000,
                      noActiveAddOnTitle: "Motion Eye Add-On Inactive",
                      noActiveAddOnMessage: "The Motion Eye Add-On will help you add camera configurations to your captive portal so that users can view them",
                      processingAddOnActivation: _ctx.processingAddOnActivation,
                      onActivateAddOn: _ctx.activateMotionEyeAddOn
                    }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                  ]))
          ]))
  ], 64))
}