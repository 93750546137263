<template>
    <!-- Display component for inside folder -->
    <!-- Upload Buttons -->
    
    <!-- Directory breadcrumb navigation -->
    <v-breadcrumbs>
        <v-breadcrumbs-item class="cursor-pointer" @click="backToHome">
            <i class="fa-solid fa-house" style="color: #1f5137;"></i> Home
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider class="px-2">
            <i class="fa-solid fa-chevron-right"></i>
        </v-breadcrumbs-divider>
        <v-breadcrumbs-item disabled="true">
            {{ folders.folderName }}
        </v-breadcrumbs-item>
    </v-breadcrumbs>

    <!-- Divider -->
    <div class="w-full border-t border-gray-300 my-2"></div>

    <FileList :folders="folders.contents" 
    :isGridLayout="isGridLayout" 
    @delete="deleteFile"
    @favourite="favouriteFile"
    @viewFolder="viewFolder"/>     
</template>
<script>
import FileList from '../Tabs/FileList.vue'

export default{
    name: "OpenFolder",
    components: {
        FileList
    },
    props: {
        folders: Object,
        folderIndex: Number,
        isGridLayout: Boolean,
        folderName: String,
    },
    data: () => ({
    }),
    methods: {
        viewFolder(folder, index) {
            this.$emit("viewFolder", folder, index);
        },
        deleteFile(index){
            this.$emit("deleteItem", index);
        },
        favouriteFile(index){
            this.$emit("favourite", index);
        },
        backToHome(){
            this.$emit("backToHome");
        }
    },
}
</script>
