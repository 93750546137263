<template>  
    <span>{{ file.folderName }}</span>
    <img :src="file.url" alt="Image Description"/>
</template>
<script>
export default {
    name: 'AudioPlayer',
    props: {
        file: Object,
    }
}
</script>
