<template>
  <div class="flex my-6 justify-between">
    <div class="flex items-center">
      <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
      <span
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
        >ADVERT DETAILS</span
      >
    </div>
    <button
      @click="editDetails"
      type="button"
      class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold text-[0.8rem] text-[#fff]"
    >
      Edit Ads
    </button>
  </div>

  <div class="md:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16">
    <div
      class="lg:basis-2/6 relative flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible shadow-lg px-5 pt-5 pb-6 shadow-gray-900/5 bg-white rounded-2xl h-100 min-h-[132px] justify-center items-center"
    >
      <h3 class="font-bold font-lg text-center">{{ advert.title }}</h3>
    </div>

    <div
      class="user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width"
    >
      <h3
        class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
      >
        Advert details
      </h3>
      <table class="w-full table-fixed table-border">
        <tbody>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889] p-3">Title</td>
            <td class="font-medium text-[1rem]">{{ advert?.title }}</td>
          </tr>
          <tr>
            <td class="font-poppin text-[1rem] text-[#888889] p-3">Realm</td>
            <td
              class="font-medium text-[1rem] cursor-pointer text-[#5e72e4]"
              @click="realmDetails(advert.realm.id)"
            >
              {{ advert.realm.name }}
            </td>
          </tr>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889] p-3">Media</td>
            <td class="font-poppin text-[1rem]">
              <a class="text-[#5e72e4]" :href="advert.file" target="_blank"
                >View Media</a
              >
            </td>
          </tr>
          <tr>
            <td class="font-poppin text-[1rem] text-[#888889] p-3">Status</td>
            <td class="font-medium text-[1rem]">
              <span
                v-if="advert.is_active"
                class="font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
                >ENABLED</span
              >
              <span
                v-else
                class="font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
                >DISABLED</span
              >
            </td>
          </tr>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889] p-3">Date Joined</td>
            <td class="font-medium text-[1rem]">
              {{ new Date(advert.created).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

import router, { useRoute } from '@/router';
import instance from '@/axios-interceptor';

export default defineComponent({
  name: 'AdvertDetailsPage',
  components: {},

  data() {
    return {
      advert: {
        title: '',
        realm: {
          id: '',
          name: '',
        },
        file: '',
        is_active: '',
        created: '',
      },
      isLoading: false,
      activeTab: 'all',
      route: useRoute(),
    };
  },

  methods: {
    realmDetails(realmId: string) {
      router.push({ name: 'realms-details', params: { id: realmId } });
    },
    editDetails() {
      router.push({
        name: 'edit-advert',
        params: { id: this.route.params.id },
      });
    },

    fetchAdvert(id: string) {
      instance.get(`verts/${id}/`).then((response) => {
        if (response.status === 200) {
          this.advert = response.data;
        } else {
          this.$toast.error(`Could not fetch advert details`, {
            position: 'top-right',
          });
        }
      });
    },
  },
  mounted() {
    this.fetchAdvert(this.route.params.id.toString());
  },
});
</script>
  