import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center bg-gray-100" }
const _hoisted_2 = { class: "w-[500px] p-6 bg-white rounded-lg shadow" }
const _hoisted_3 = {
  key: 0,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_4 = {
  key: 1,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_5 = { class: "mt-3 text-sm" }
const _hoisted_6 = {
  key: 2,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = { class: "text-gray-600" }
const _hoisted_9 = { class: "text-primary mt-3 text-sm" }
const _hoisted_10 = {
  key: 3,
  class: "mb-4 p-4 border rounded-md border-[#f24236]"
}
const _hoisted_11 = { key: 4 }
const _hoisted_12 = {
  key: 0,
  class: "text-2xl font-bold mb-4"
}
const _hoisted_13 = {
  key: 1,
  class: "text-2xl font-bold mb-4"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "flex items-start" }
const _hoisted_16 = ["id", "value"]
const _hoisted_17 = ["for"]
const _hoisted_18 = { class: "text-gray-600" }
const _hoisted_19 = { class: "text-gray-600" }
const _hoisted_20 = {
  key: 0,
  class: "text-primary mt-3 text-sm"
}
const _hoisted_21 = {
  key: 2,
  class: "text-[#f24236] mt-3 text-sm mb-3"
}
const _hoisted_22 = {
  key: 3,
  class: "text-[#f24236] mt-3 text-sm mb-3"
}
const _hoisted_23 = ["disabled"]
const _hoisted_24 = {
  key: 5,
  type: "button",
  class: "w-full cursor-not-allowed font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.loggedInUser.sme.sms_package)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[6] || (_cache[6] = [
              _createElementVNode("div", null, [
                _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " SMS Add-On Inactive "),
                _createElementVNode("p", { class: "mt-3 text-sm" }, " The SMS Add-On will help your users to generate wifi access tokens via sms instead of you providing them with the tokens manually ")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.loggedInUser.sme.sms_package && !_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " Total Sent SMS Messages ", -1)),
                _createElementVNode("p", _hoisted_5, [
                  _cache[7] || (_cache[7] = _createTextVNode(" You have sent ")),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.loggedInUser.sme.total_sms_sent_in_current_cycle), 1),
                  _cache[8] || (_cache[8] = _createTextVNode(" sms messages out of ")),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.loggedInUser.sme.sms_package.name.split('_')[0]), 1),
                  _cache[9] || (_cache[9] = _createTextVNode(" total eligible messages "))
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.pendingSMSPackages.length && !_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingSMSPackages, (pendingSMSPackage) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: pendingSMSPackage.id
                }, [
                  _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " Pending SMS Package Change ", -1)),
                  _createElementVNode("p", _hoisted_7, " From: " + _toDisplayString(pendingSMSPackage.current_sms_package.name.split('_')[0]) + " SMS ", 1),
                  _createElementVNode("p", _hoisted_8, " To: " + _toDisplayString(pendingSMSPackage.new_sms_package.name.split('_')[0]) + " SMS ", 1),
                  _createElementVNode("p", _hoisted_9, [
                    _cache[11] || (_cache[11] = _createTextVNode(" The ")),
                    _createElementVNode("strong", null, _toDisplayString(pendingSMSPackage.new_sms_package.name.split('_')[0]) + " SMS", 1),
                    _cache[12] || (_cache[12] = _createTextVNode(" sms package will be applied after payment of the next billing cycle on: ")),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.nextBillingDate), 1),
                    _cache[13] || (_cache[13] = _createTextVNode(" UTC+3 (EAT) Time Zone. "))
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "font-bold text-xl mb-2 text-[#f24236]" }, " Pending Payment for Previous Billing ", -1)),
                _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-gray-600" }, " There is a pending sms package billing that you have not paid. Please go to the billings page and make the payment to resume access to the SMS addon. ", -1)),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToPage({ path: '/billings', query: { tab: 'sms' } }))),
                  class: "w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] p-3 font-medium mt-6"
                }, " Access the Page ")
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (!!_ctx.loggedInUser.sme.sms_package)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_12, " Upgrade or Downgrade your SMS package "))
                : _createCommentVNode("", true),
              (!_ctx.loggedInUser.sme.sms_package)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_13, " Activate SMS Package "))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.smsPackages, (smsPackage) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: smsPackage.name,
                  class: _normalizeClass(["mb-4 cursor-pointer", _ctx.loggedInUser.sme.sms_package?.name === smsPackage.value
            ? 'border-primary border-[1px] rounded-md p-4'
            : ''
            ]),
                  onClick: ($event: any) => (_ctx.selectedSMSPackage = smsPackage.value)
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      id: smsPackage.name,
                      value: smsPackage.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSMSPackage) = $event)),
                      class: "mt-1 mr-2"
                    }, null, 8, _hoisted_16), [
                      [_vModelRadio, _ctx.selectedSMSPackage]
                    ]),
                    _createElementVNode("label", {
                      for: smsPackage.name,
                      class: "font-bold"
                    }, _toDisplayString(smsPackage.name), 9, _hoisted_17)
                  ]),
                  _createElementVNode("p", _hoisted_18, "Price: " + _toDisplayString(smsPackage.monthly_cost) + " RWF", 1),
                  _createElementVNode("p", _hoisted_19, "Description: " + _toDisplayString(smsPackage.description), 1),
                  (_ctx.loggedInUser.sme.sms_package?.name === smsPackage.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_20, " Current SMS Package "))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_14))
              }), 128)),
              (_ctx.pendingSMSPackages.length)
                ? (_openBlock(), _createElementBlock("p", _hoisted_21, " Warning: You have a pending SMS package change. Thus, you cannot upgrade or downgrade your sms package till the pending one is applied on the next billing cycle. "))
                : _createCommentVNode("", true),
              (_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing)
                ? (_openBlock(), _createElementBlock("p", _hoisted_22, " Warning: You have a pending SMS package billing payment. Thus, you cannot upgrade or downgrade your SMS package till you pay for the outstanding billings. "))
                : _createCommentVNode("", true),
              (!_ctx.processingSMSPackage && !!_ctx.loggedInUser.sme.sms_package)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 4,
                    type: "button",
                    class: _normalizeClass(["w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2", {
            'cursor-not-allowed': _ctx.isUpgradeButtonDisabled,
          }]),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.upgrade && _ctx.upgrade(...args))),
                    disabled: _ctx.isUpgradeButtonDisabled
                  }, " Upgrade ", 10, _hoisted_23))
                : _createCommentVNode("", true),
              (_ctx.processingSMSPackage)
                ? (_openBlock(), _createElementBlock("button", _hoisted_24, " Processing... "))
                : _createCommentVNode("", true),
              (!_ctx.processingSMSPackage && !_ctx.loggedInUser.sme.sms_package)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 6,
                    type: "button",
                    class: _normalizeClass(["w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2", {
            'cursor-not-allowed': !_ctx.selectedSMSPackage,
          }]),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args))),
                    disabled: !_ctx.selectedSMSPackage
                  }, [
                    _cache[17] || (_cache[17] = _createTextVNode(" Activate ")),
                    (_ctx.selectedSMSPackage)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, " for " + _toDisplayString(_ctx.returnSMSPackagePrice(_ctx.selectedSMSPackage.split("_")[0])) + " RWF ", 1))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_25))
                : _createCommentVNode("", true)
            ]))
      ])
    ]),
    _createVNode(_component_confirmation_dialog, {
      show: _ctx.showCancellationDialog,
      title: "SMS Add-On Deactivation",
      message: "Disabling the SMS Add-On will remove your SMS package and cancel access to generating tokens via SMS. This change will take effect after your current billing cycle ends, so you'll still have access until then.",
      actionLabel: "Confirm",
      loading: _ctx.loadingCancellation,
      onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleCancellationDialog(false))),
      onConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelSMSPackage()))
    }, null, 8, ["show", "loading"])
  ], 64))
}