<template>
  <DashboardHeader title="Allocated Routers" :icon="icon.router">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>

  <suspended-page-message v-if="!loggedInUser.sme?.is_active"></suspended-page-message>

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>
    <div v-else
      class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
      <div class="tabs-block" id="tabs-block">
        <div>
          <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
            role="tablist">
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="microfranchisee"
                data-tabs-target="#microfranchisee" type="button" role="tab" aria-controls="microfranchisee"
                aria-selected="false" v-on:click="handleSetActiveTab('router')" v-bind:class="[
                  activeTab === 'router'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Routers
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="routers" data-tabs-target="#routers"
                type="button" role="tab" aria-controls="routers" aria-selected="false"
                v-on:click="handleSetActiveTab('analytics')" v-bind:class="[
                  activeTab === 'analytics'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Analytics
              </button>
            </li>
          </ul>
        </div>
        <div id="TabContent">
          <!-- ROUTER TAB CONTENT -->
          <div v-if="activeTab === 'router'">
            <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
              @handleNext="handlleNext" @handlePrev="handlePrev">
              <template v-slot:section>
                <div>
                  <div class="mt-5 flex items-center">
                    <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                      class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                      <select v-model="selectedOption" @change="handleChange">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select></span>
                  </div>
                </div>
              </template>
            </DashboardTableTitle>

            <SmeTable :tableHeaderData="routerTableHeaders" :table-name="tableName">
              <template v-slot:tableBodyData>
                <template v-if="paginatedTableData.length">
                  <div v-for="router in paginatedTableData" :key="router.id"
                    class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                    <td>
                      <div class="flex items-center">
                        <div
                          class="bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center">
                          <img :src="router.location === 'In Transit'
                            ? require('../../assets/router.png')
                            : router.location === 'Manufacturer'
                              ? require('../../assets/transit_router.png')
                              : require('../../assets/active_router.png')
                            " :alt="router.nasidentifier" class="w-[1.89rem] h-[1.779rem]" />
                        </div>

                        <div class="flex flex-col ml-3">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            router.serial_number
                          }}</span>
                          <span class="dotonline ml-2" v-if="router.is_online"></span>
                          <span class="dotoffline ml-2" v-else></span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{ router.ssid }}</span>
                    </td>
                    <td>
                      <div class="flex flex-col">
                        <span class="text-[#464E5F]" v-if="router.os_version_id">v{{ router.os_version_id }}</span>
                      </div>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{
                        router.model_number
                      }}</span>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{
                        router.location_name
                      }}</span>
                    </td>
                    <td>
                      <div class="flex flex-col">
                        <span class="capitalize text-[#464E5F] mb-0.5">{{
                          router.ip_address
                        }}</span>
                        <span>{{ router.last_contact_ip }}</span>
                      </div>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{
                        createdAt(router.created)
                      }}</span>
                    </td>
                    <td>
                      <div class="flex items-center justify-end">
                        <div
                          class="bg-[#F3F6F9] w-[4rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2 p-2">
                          <button @click="editRouter(router)" class="flex">
                            <img :src="routerTableIcons[1]" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                            <span class="ml-2">Edit</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </div>
                </template>
                <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                  <b>No Routers Found</b>
                </div>
              </template>
            </SmeTable>

            <div v-if="isLoading" class="spinner-container">
              <div class="spinner"></div>
            </div>
          </div>
          <!-- ANALYTICS TAB CONTENT -->
          <div v-if="activeTab === 'analytics'">
            <AnalyticsPage></AnalyticsPage>
          </div>
        </div>
      </div>
    </div>
  </div>
  <edit-form v-if="showModal" @close="showModal = false" @routerUpdated="fetchRouters()"
    :router="currentRouter"></edit-form>
</template>

<script lang="ts">
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import router from '@/router';
import { defineComponent, ref } from 'vue';
import AnalyticsPage from './analytics.vue';
import { Router } from '@/interfaces/router';
import instance from '../../axios-interceptor';
import EditForm from '../../components/Router/editForm.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';
import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    SmeTable,
    AnalyticsPage,
    DashboardHeader,
    DashboardTableTitle,
    EditForm,
    PaymentRequiredMessage,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Serial Number');
    const routers = ref([..._routers]);
    const routerTableHeaders = ref([..._routerTableHeaders]);
    const routerTableIcons = ref([..._routerTableIcons]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);

    const deleting = ref(false);
    const currentDeletionFileID = ref(0);

    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      tableName,
      icon,
      deleting,
      currentDeletionFileID,
    };
  },

  data() {
    return {
      activeTab: 'router',
      isLoading: false,
      selectedOption: 10,
      apiData: [],
      propLoaded: false,
      page: 1,
      perSize: 10,
      showModal: false,
      showUploadMenuModal: false,
      currentRouter: {} as Router,
      routerList: [
        {
          id: '',
          nasidentifier: '',
          serial_number: '',
          icon: '',
          imei: '',
          os_version_name: '',
          os_version_id: '',
          location: '',
          model_number: '',
          ip_address: '',
          ssid: '',
          last_contact_ip: '',
          created: '',
          is_online: false,
        },
      ],
      zabbixUserName: 'Admin',
      zabbixPassword: 'wizzdev2021',
      zabbixAuthenticationToken: '',
      zabbixBaseUri: 'https://zabbix.shirikihub.com/zabbix/',
      zabbixApiEndpoint: 'api_jsonrpc.php',
      filteredRouters: [
        {
          host: '',
          id: '',
          hostid: '',
        },
      ],
      onlineRouters: [] as any,
      processingSMSPackage: false,
    };
  },

  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.routerList.slice(startIndex, endIndex);
    },
  },

  methods: {
    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });
    },
    createdAt(date: Date) {
      if (date) {
        return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
      }
    },
    handlePrev(data: any) {
      this.page--;
      this.fetchRouters();
    },
    handlleNext(data: any) {
      this.page++;
      this.fetchRouters();
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    fetchRouters() {
      this.isLoading = true;
      instance
        .get(`routers/?sme=${this.loggedInUser.sme.id}`)
        .then((response: { data: any }) => {
          this.routerList = response.data.results;
          this.apiData = response.data.results;
          this.propLoaded = true;
          this.isLoading = false;
          this.loginZabbix();
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    downloadFile(file: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.fetchRouters();
    },
    editRouter(router: Router) {
      this.currentRouter = router;
      this.showModal = true;
    },
    async loginZabbix() {
      delete axios.defaults.headers.common['Authorization'];

      const loginData = {
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: this.zabbixUserName,
          password: this.zabbixPassword,
        },
        id: 1,
      };

      const endpoint = `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`;

      try {
        // Step 1: Authenticate with Zabbix
        const loginResponse = await axios.post(endpoint, loginData);

        if (loginResponse.status === 200) {
          this.zabbixAuthenticationToken = loginResponse.data.result;
        } else {
          this.$toast.error('Could not authenticate w/Zabbix.', {
            position: 'top-right',
          });
          return;
        }

        // Step 2: Get host information
        const getHostData = {
          jsonrpc: '2.0',
          method: 'host.get',
          params: {
            output: ['hostid', 'host'],
            selectInterfaces: ['interfaceid', 'ip'],
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        const hostResponse = await axios.post(endpoint, getHostData);

        if (hostResponse.status === 200) {
          const zabbixHosts = hostResponse.data.result;
          const routerSerialNumbers = Object.keys(this.routerList).map(
            (router: any) => this.routerList[router].serial_number
          );

          const filteredRouters = [];

          for (let i = 0; i < routerSerialNumbers.length; i++) {
            const hostName = 'mini-server_' + routerSerialNumbers[i];
            const foundHost = zabbixHosts.find(
              (host: any) => host.host === hostName
            );

            if (foundHost) {
              filteredRouters.push(foundHost);
            }
          }

          this.filteredRouters = filteredRouters;
        } else {
          this.$toast.error('Could not get the hosts.', {
            position: 'top-right',
          });
          return;
        }

        // Step 3: Retrieve online status for each filtered router
        for (const router of this.filteredRouters) {
          const itemData = {
            jsonrpc: '2.0',
            method: 'item.get',
            params: {
              output: ['lastvalue', 'status'],
              hostids: router.hostid,
              search: {
                key_: 'zabbix[host,agent,available]',
                name: 'Linux: Zabbix agent availability',
              },
            },
            auth: this.zabbixAuthenticationToken,
            id: 1,
          };

          const itemResponse = await axios.post(endpoint, itemData);

          if (itemResponse.status === 200 && itemResponse.data.result.length) {
            const onlineStatus = itemResponse.data.result[0].lastvalue;

            const onlineRouter = {
              router: router.host.split('_')[1],
              status: onlineStatus,
            };

            this.onlineRouters.push(onlineRouter);
          }
        }

        // Step 4: Update the 'is_online' property for each router
        this.routerList.forEach((router) => {
          const onlineRouter = this.onlineRouters.find(
            (online: any) => online.router === router.serial_number
          );

          if (onlineRouter && onlineRouter.status === '1') {
            router.is_online = true;
          } else {
            router.is_online = false;
          }
        });
      } catch (error) {
        this.$toast.error('An error occurred during Zabbix login.', {
          position: 'top-right',
        });
      }
    },
  },
  created() {
    this.fetchRouters();
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.activeTab = tab;
    }
  },
});
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-btn:hover+.tooltip-text {
  visibility: visible;
  opacity: 1;
}

.dotonline {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.dotoffline {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
</style>