<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            {{ heading }}
          </h3>
          <form @submit.prevent="updateSSID">
            <div class="mb-5">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">SSID:</label>
              <input placeholder="Enter new SSID name" v-model="ssid" required type="text" id="small-input"
                maxlength="30"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
            </div>
            <div class="mb-5">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Location:</label>
              <input placeholder="Enter new location name" v-model="location" required type="text" id="small-input"
                maxlength="30"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
            </div>
            <div class="w-full flex lg:justify-end justify-start items-center mt-3" v-if="!loading">
              <button type="submit"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium">
                UPDATE
              </button>
            </div>

            <div class="w-full flex lg:justify-end justify-start items-center mt-3" v-else>
              <button type="submit"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] font-medium"
                disabled>
                <clip-loader color="#fff"></clip-loader>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent, PropType } from 'vue';

import axios from '@/axios-interceptor';
import { Router } from '@/interfaces/router';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default defineComponent({
  name: 'EditForm',
  components: {
    ClipLoader,
  },
  props: {
    router: {
      type: Object as PropType<Router>,
    },
  },
  data() {
    return {
      heading: 'Edit Router Properties',
      ssid: '' as string | undefined,
      location: '' as string | undefined,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    updateSSID() {
      this.loading = true;

      axios
        .patch(`routers/${this.router?.id}/`, {
          ssid: this.ssid,
          location_name: this.location,
        })
        .then(() => {
          this.loading = false;
          this.$toast.success('Router properties updated successfully', {
            position: 'top-right',
          });
          this.$emit('close');
          this.$emit('routerUpdated');
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error('Could not update the router properties', {
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.ssid = this.router?.ssid;
    this.location = this.router?.location_name;
  },
});
</script>
