import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center bg-gray-100" }
const _hoisted_2 = { class: "w-[500px] p-6 bg-white rounded-lg shadow" }
const _hoisted_3 = { class: "mb-4 p-4 border rounded-md border-[#4E8D6E]" }
const _hoisted_4 = { class: "font-bold text-lg mb-2 text-primary" }
const _hoisted_5 = { class: "mt-3 text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "mt-3 text-sm"
}
const _hoisted_7 = {
  key: 0,
  class: "mt-3 text-sm text-primary font-bold"
}
const _hoisted_8 = {
  key: 1,
  class: "mt-3 text-sm"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  type: "button",
  class: "w-full cursor-not-allowed font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.noActiveAddOnTitle), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.noActiveAddOnMessage), 1),
          (_ctx.price > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createTextVNode(" Price: " + _toDisplayString(_ctx.price.toLocaleString()) + " RWF per month (30 days) ", 1),
                (_ctx.loggedInUser.sme.is_in_trial_period)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, " | Free In Trial Period "))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, _cache[1] || (_cache[1] = [
                _createTextVNode(" Always "),
                _createElementVNode("span", { class: "mt-3 text-sm text-primary font-bold" }, " | Free ", -1)
              ])))
        ])
      ]),
      (!_ctx.processingAddOnActivation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args)))
          }, [
            _cache[2] || (_cache[2] = _createTextVNode(" Activate for ")),
            (_ctx.loggedInUser.sme.is_in_trial_period)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Free"))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.price.toLocaleString()) + " RWF", 1))
          ]))
        : (_openBlock(), _createElementBlock("button", _hoisted_11, " Processing... "))
    ])
  ]))
}