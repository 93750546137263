import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/ared-africa.png'


const _hoisted_1 = { class: "bg-white max-h-[4.188rem] flex py-3 fixed w-full top-0 z-20" }
const _hoisted_2 = { class: "flex w-full justify-between items-center lg:mx-12 mx-3" }
const _hoisted_3 = {
  type: "button",
  class: "max-w-[50rem] md:px-6 px-4 md:h-[2.938rem] h-[1.938rem] bg-light rounded-md text-primary font-poppins font-semibold md:text-[1.062rem] text-[0.60rem]"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "w-1/3 bg-[#f24236] flex justify-center ml-6 rounded-md" }
const _hoisted_6 = {
  key: 0,
  class: "text-white text-sm py-3"
}
const _hoisted_7 = {
  key: 1,
  class: "text-white text-sm py-3"
}
const _hoisted_8 = { class: "min-w-[10vw] items-center justify-between relative md:flex hidden" }
const _hoisted_9 = { class: "flex flex-col items-end justify-end mr-2" }
const _hoisted_10 = { class: "font-semibold text-[0.75rem] font-poppins text-black" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 0,
  class: "absolute w-[10vw] min-h-[5vh] bg-white shadow top-[3.188rem] right-0 items-left justify-end"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "w-full bg-primary flex justify-center" }
const _hoisted_15 = {
  key: 0,
  class: "text-white text-xs py-3"
}
const _hoisted_16 = {
  key: 1,
  class: "text-white text-xs py-3"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "w-full bg-primary flex justify-center" }
const _hoisted_19 = { class: "text-white text-xs pt-3" }
const _hoisted_20 = { class: "w-full bg-primary flex justify-center" }
const _hoisted_21 = { class: "text-white text-xs pt-1 pb-3" }
const _hoisted_22 = {
  key: 2,
  class: "w-full bg-[#f24236] flex justify-center"
}
const _hoisted_23 = { class: "text-white text-xs py-3" }
const _hoisted_24 = {
  key: 3,
  class: "w-full bg-[#f24236] flex justify-center"
}
const _hoisted_25 = { class: "text-white text-xs py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "md:w-[8vw] w-[18vw] md:flex items-center justify-center" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "africa",
          class: "w-[3.188rem]"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _cache[5] || (_cache[5] = _createTextVNode(" ARED SME PORTAL - ")),
          (_ctx.loggedInUser.sme)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.loggedInUser.sme.name), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.offlineRouters.length === 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.offlineRouters.length) + " router/box is offline. ", 1),
                _createVNode(_component_router_link, {
                  to: "/routers",
                  class: "underline"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Click here to see ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.offlineRouters.length > 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.offlineRouters.length) + " routers/boxes are offline. ", 1),
                _createVNode(_component_router_link, {
                  to: "/routers",
                  class: "underline"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Click here to see ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "flex flex-row items-center z-10",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOpenOrCloseModal && _ctx.handleOpenOrCloseModal(...args)), ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.loggedInUser.full_name), 1)
            ]),
            _createElementVNode("img", {
              src: _ctx.loggedInUser.profile_pic
            ? _ctx.loggedInUser.profile_pic
            : require('../../assets/user-avatar.svg')
            ,
              alt: "profile picture",
              class: "w-[2rem] h-[2rem] rounded-[1rem] object-cover"
            }, null, 8, _hoisted_11)
          ]),
          (_ctx.isOpenModal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.profile && _ctx.profile(...args))),
                  class: "px-4 py-2 hover:bg-light hover:text-primary border-b-1 text-left w-full font-bold text-[0.75rem] font-poppins"
                }, " My Profile "),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)), ["stop"])),
                  class: "px-4 py-2 hover:bg-light hover:text-primary w-full text-left font-bold text-[0.75rem] font-poppins"
                }, " Sign Out ")
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          id: "close-btn",
          class: "flex items-center justify-between relative lg:hidden toggle-btn",
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args)), ["stop"]))
        }, [
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleActive && _ctx.toggleActive(...args))),
            class: _normalizeClass([_ctx.isToggleicon ? 'open' : 'close'])
          }, null, 2)
        ])
      ])
    ]),
    (_ctx.loggedInUser.sme?.is_in_trial_period)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_ctx.returnTrialRemainingDays(_ctx.loggedInUser.sme.trial_end_date) === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_15, " Your 30-day trial period will end today. Make the most of this time to explore our features and see how they can benefit your business! "))
              : (_openBlock(), _createElementBlock("p", _hoisted_16, " Your 30-day trial period will end in " + _toDisplayString(_ctx.returnTrialRemainingDays(_ctx.loggedInUser.sme.trial_end_date)) + " days. Make the most of this time to explore our features and see how they can benefit your business! ", 1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showTrialEndBannerSevenDays)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("p", _hoisted_19, " Free trial period is about to end on " + _toDisplayString(_ctx.formatDate(_ctx.loggedInUser.sme.trial_end_date)) + ". Your " + _toDisplayString(_ctx.loggedInUser.sme.subscription_plan.name) + " subscription will be applied after payment. ", 1)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("p", _hoisted_21, [
              _cache[10] || (_cache[10] = _createTextVNode(" Till you pay, you will not be able to access your subscription features. ")),
              _createVNode(_component_router_link, {
                to: "/billings",
                class: "underline"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Click here to pay")
                ])),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loggedInUser.sme?.is_active && _ctx.loggedInUser.sme?.has_not_paid_for_one_billing && !_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("p", _hoisted_23, [
            _createTextVNode(" You have not paid for the last billing. Kindly, make payment to continue using your subscription features. You have " + _toDisplayString(_ctx.calculateRemainingDaysToNewDueDate(_ctx.loggedInUser.sme.billings[0].due_date)) + " days to make the payment. ", 1),
            _createVNode(_component_router_link, {
              to: "/billings",
              class: "underline"
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Click here to pay in advance")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loggedInUser.sme?.is_active && _ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("p", _hoisted_25, [
            _cache[13] || (_cache[13] = _createTextVNode(" You have not paid for the last billing. Kindly, make payment to continue using your subscription features. ")),
            _createVNode(_component_router_link, {
              to: "/billings",
              class: "underline"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Click here to pay")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}