<template>
  <DashboardHeader title="All Vouchers" description="" :icon="icon.voucher">
    <template v-slot:aside>
      <div v-if="
        loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago ||
        !loggedInUser.sme?.is_active || !loggedInUser.sme.subscription_plan
      " class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
      <button v-else @click="showModal = true" type="button"
        class="bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex"><img
            src="../../assets/plus-icon.png" class="md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2" alt="" />
          Generate new voucher (s)</span>
      </button>

      <voucherForm v-if="showModal" @close="closeModal"></voucherForm>
    </template>
  </DashboardHeader>

  <suspended-page-message v-if="!loggedInUser.sme?.is_active"></suspended-page-message>

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>

    <div v-else id="tabs-block"
      class="tabs-block bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
      <div>
        <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
          role="tablist">
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="vouchers" data-tabs-target="#vouchers"
              type="button" role="tab" aria-controls="vouchers" aria-selected="false"
              v-on:click="mainActiveTab = 'vouchers'" v-bind:class="[
                mainActiveTab === 'vouchers'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Vouchers
            </button>
          </li>
        </ul>
      </div>

      <div id="TabContent">
        <div v-if="mainActiveTab === 'vouchers'">
          <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
            @handleNext="handlleNext" @handlePrev="handlePrev">
            <template v-slot:section>
              <div class="md:w-[30%] w-[50%]">
                <label class="relative block">
                  <span class="sr-only">Search</span>
                  <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                    <img src="../../assets/search-icon.png" alt="" class="w-[1rem] h-[1rem]" />
                  </span>
                  <input v-model="searchQuery"
                    class="font-normal w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block focus:ring-1"
                    placeholder="Search vouchers" type="text" name="search" />
                </label>
              </div>
            </template>
          </DashboardTableTitle>
          <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
            <div>
              <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} vouchers selected</span>
              <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows"
                v-if="!deletingVouchers">clear</span>
            </div>
            <div>
              <button type="button"
                class="me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="deleteVouchers()" v-if="!deletingVouchers">
                Delete Voucher(s)
              </button>
              <button v-else type="button"
                class="me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 text-center flex">
                <span class="mr-2 pt-2"> Deleting Vouchers </span>
                <span>
                  <ScaleLoader color="#ffffff" :size="5"></ScaleLoader>
                </span>
              </button>
            </div>
          </div>
          <SmeTable :tableName="tableName" :tableHeaderData="routerTableHeaders" :inputShow="true"
            :selectAllValues="selectAll" @selectAllRows="selectAllRows" :deleting-vouchers="deletingVouchers">
            <template v-slot:tableBodyData>
              <template v-if="paginatedTableData.length">
                <div v-for="voucher in paginatedTableData" :key="voucher.id"
                  class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <input :disabled="deletingVouchers" v-model="voucher.selected"
                          @change="updateSelectedRows(voucher)" type="checkbox"
                          class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <span class="capitalize text-[#464E5F] mb-0.5">{{
                          voucher.value
                          }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-[#464E5F]">{{ voucher.time_valid }}</span>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        voucher.source
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        voucher.used_by_customer ? 'Yes' : 'No'
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <span class="text-[#464E5F]">{{
                      createdAt(voucher.created)
                      }}</span>
                  </td>
                  <td>
                    <div class="flex items-center justify-start">
                      <div v-if="voucher.is_qrcode"
                        class="bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2">
                        <button @click="showQR(voucher.id)">
                          <img src="../../assets/Scan-icon.png" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                        </button>
                        <qrForm :QRnumber="QRValue" v-if="showQRModal" @close="showQRModal = false"></qrForm>
                      </div>
                      <div v-else
                        class="bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2">
                        <button disabled>
                          <img src="../../assets/Scan-icon.png" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-[#464E5F]">{{ voucher.re_usable }}</span>
                  </td>
                </div>
              </template>
              <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                <b>No Record found</b>
              </div>
            </template>
          </SmeTable>
        </div>
      </div>
      <div class="spinner-container">
        <div v-if="isLoading" class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

import qrForm from './qrForm.vue';
import instance from '@/axios-interceptor';
import voucherForm from './voucherForm.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboard_routes } from '../../helpers/stub_data/dashboard_routes';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';
import {
  vouchers as _vouchers,
  voucherTableHeaders as _voucherTableHeaders,
  voucherTableIcons as _voucherTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import { Voucher } from '@/interfaces/router';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    DashboardHeader,
    voucherForm,
    qrForm,
    PaymentRequiredMessage,
    ScaleLoader,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Vouchers Code');
    const routes = ref([...dashboard_routes]);
    const routers = ref([..._vouchers]);
    const routerTableHeaders = ref([..._voucherTableHeaders]);
    const routerTableIcons = ref([..._voucherTableIcons]);
    const activeId = ref(1);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);

    return {
      routes,
      activeId,
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      isOpenModal,
      tableName,
      icon,
    };
  },

  data() {
    return {
      showQRModal: false,
      showModal: false,
      isLoading: false,
      searchQuery: '',
      apiData: [] as any,
      propLoaded: false,
      loadingSMSSettings: false,
      page: 1,
      perSize: 10,
      mainActiveTab: 'vouchers',
      duration: 'Select Default Duration',
      voucherList: [
        {
          id: '',
          time_valid: '',
          value: '',
          is_qrcode: false,
          re_usable: false,
          source: '',
          created: '',
        },
      ] as Voucher[],
      selectedRows: [] as Voucher[],
      QRValue: '',
      selectAll: false,
      rowsSelected: 0,
      deletingVouchers: false,
    };
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.getVouchersData();
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
      }
    },
    handleSetActive(id: number) {
      this.activeId = id;
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    handlePrev(data: any) {
      this.page--;
      this.getVouchersData();
    },
    handlleNext(data: any) {
      this.page++;
      this.getVouchersData();
    },
    getVouchersData() {
      this.isLoading = true;
      instance
        .get(`vouchers/?router__sme=${this.loggedInUser.sme.id}&added_by=${this.loggedInUser.id}`)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.voucherList = response.data.results;
          this.apiData = response.data.results;
          this.propLoaded = true;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    showQR(id: any) {
      const voucher = this.filteredTableData.find(
        (voucher) => voucher.id === id
      );
      this.showQRModal = true;
      this.QRValue = voucher.value;
    },
    updateSelectedRows(row: Voucher) {
      if (!row.selected) {
        this.selectAll = false;
        this.selectedRows = this.selectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
      } else {
        this.selectedRows = this.voucherList.filter((row) => row.selected);
        this.rowsSelected = this.selectedRows.length;
        this.selectAll = this.selectedRows.length === this.voucherList.length;
      }
    },
    selectAllRows(checked: boolean) {
      this.selectAll = checked;
      this.voucherList.forEach((voucher) => {
        voucher.selected = this.selectAll;
      });

      if (this.selectAll) {
        this.selectedRows = [...this.voucherList];
        this.rowsSelected = this.selectedRows.length;
      } else {
        this.selectedRows = [];
        this.rowsSelected = 0;
      }
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async deleteVouchers() {
      try {
        this.deletingVouchers = true;
        const voucher_ids = this.selectedRows.map((voucher) => voucher.value);

        await instance
          .delete('vouchers-delete', {
            data: { voucher_ids: voucher_ids }, // Include data in the request configuration
          })
          .then(() => {
            this.$toast.success('Voucher(s) deleted successfully', {
              position: 'top',
            });
            this.voucherList = this.voucherList.filter(
              (voucher) => !voucher_ids.includes(voucher.value)
            );
            this.deletingVouchers = false;
          });
      } catch (e: any) {
        this.deletingVouchers = false;
        this.$toast.error('Could not delete the vouchers', {
          position: 'top',
        });
      }
    },
  },
  created() {
    this.getVouchersData();
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.voucherList;
      }

      // Filter table data based on search query
      const filteredData = this.voucherList.filter((voucher) => {
        // Convert voucher values to lowercase for case-insensitive search
        const time_valid = voucher.time_valid.toString().toLowerCase();
        const value = voucher.value.toString().toLowerCase();
        const source = voucher.source.toString().toLowerCase();
        const created = voucher.created.toString().toLowerCase();
        const query = this.searchQuery.toLowerCase();

        // Check if name or age contain the search query
        return (
          time_valid.includes(query) ||
          value.includes(query) ||
          source.includes(query) ||
          created.includes(query)
        );
      });
      return filteredData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
  },
});
</script>
