<template>
  <div class="flex justify-start items-center bg-gray-100">
    <div class="max-w-lg p-6 bg-white rounded-lg shadow">
      <!-- Display no active subscription message -->
      <div v-if="!loggedInUser.sme.subscription_plan" class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">
            No Active Subscription
          </h3>
          <p class="mt-3 text-sm">
            You have no active subscription. Consider activating a subscription
            to get access to most of the pages
          </p>
        </div>
      </div>

      <!-- Display the pending subscription change card if it exists -->
      <div v-if="pendingSubscriptionPlans.length" class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div v-for="pendingSubscription in pendingSubscriptionPlans" :key="pendingSubscription.id">
          <h3 class="font-bold text-lg mb-2 text-primary">
            Pending Subscription Change
          </h3>
          <p class="text-gray-600">
            From: {{ pendingSubscription.current_subscription_plan.name }}
          </p>
          <p class="text-gray-600">
            To: {{ pendingSubscription.new_subscription_plan.name }}
          </p>

          <p class="text-primary mt-3 text-sm">
            The
            <strong>{{ pendingSubscription.new_subscription_plan.name }}</strong>
            subscription will be applied on the next billing cycle on:
            <strong>{{ nextBillingDate }}</strong> UTC+3 (EAT) Time Zone.
          </p>
        </div>
      </div>

      <!-- Display free trial message -->
      <div v-if="loggedInUser.sme.is_in_trial_period" class="mb-4 p-4 border rounded-md border-primary">
        <div>
          <h3 class="font-bold text-xl mb-2 text-primary">Free Trial Period</h3>
          <p class="text-gray-600">
            You are currently in a 30-day trial period. Your selected subscription
            plan will come into effect on <strong>{{ formatDate(loggedInUser.sme.billings[0].due_date) }}</strong>,
            and you will be billed on that date after completing the payment for the subscription.
          </p>
        </div>
      </div>

      <!-- Display payment warning -->
      <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago"
        class="mb-4 p-4 border rounded-md border-[#f24236]">
        <div>
          <h3 class="font-bold text-xl mb-2 text-[#f24236]">
            Pending Payment for 1 Billing
          </h3>
          <p class="text-gray-600">
            You have not paid for the last billing. Please go to the
            billings page and make the payment.
          </p>
        </div>
      </div>

      <h2 class="text-2xl font-bold mb-4 capitalize" v-if="!!loggedInUser.sme.subscription_plan">
        Base subscription
      </h2>

      <h2 class="text-2xl font-bold mb-4" v-if="!loggedInUser.sme.subscription_plan">
        Activate Subscription
      </h2>

      <!-- Display the available subscription options -->
      <div v-for="plan in subscriptionPlanPackages" :key="plan.name" class="mb-4 cursor-pointer"
        @click="selectedSubscription = plan.name" :class="loggedInUser.sme.subscription_plan?.name === plan.name
          ? 'border-primary border-[1px] rounded-md p-4'
          : ''
          ">
        <div class="flex items-start">
          <input type="radio" :id="plan.name" :value="plan.name" v-model="selectedSubscription" class="mt-1 mr-2" />
          <label :for="plan.name" class="font-bold">{{
            plan.name
          }}</label>
        </div>
        <p class="text-gray-600">{{ plan.price }}</p>
        <p class="text-gray-600">{{ plan.features }}</p>
        <p class="text-primary mt-3 text-sm" v-if="loggedInUser.sme.subscription_plan?.name === plan.name">
          Current subscription plan
        </p>
      </div>

      <p class="text-[#f24236] mt-3 text-sm mb-3" v-if="pendingSubscriptionPlans.length">
        Warning: You have a pending subscription plan change. Thus, you cannot
        upgrade or downgrade your subscription plan till the pending one is applied
        on the next billing cycle.
      </p>

      <p class="text-[#f24236] mt-3 text-sm mb-3" v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
        Warning: You have pending billing payment. Thus, you cannot upgrade or
        downgrade your subscription plan till you pay for the outstanding billings
      </p>

      <!-- Upgrade button -->
      <!-- <button
        type="button"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
        :class="{
          'cursor-not-allowed': isUpgradeButtonDisabled,
        }"
        @click="upgrade"
        :disabled="isUpgradeButtonDisabled"
        v-if="!processingSubscription && !!loggedInUser.sme.subscription_plan"
      >
        Upgrade
      </button> -->

      <!-- Upgrading or Downgrading button -->
      <!-- <button
        v-if="processingSubscription"
        type="button"
        class="w-full cursor-not-allowed font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
      >
        Processing...
      </button> -->

      <!-- Cancel subscription button -->
      <button type="button"
        class="w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-[15px] text-[#f24236] rounded-[0.432rem] p-3 font-medium border mt-2"
        v-if="!processingSubscription && !!loggedInUser.sme.subscription_plan" @click="showCancellationDialog = true">
        Cancel
      </button>

      <!-- Activate subscription button -->
      <button type="button"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2" :class="{
          'cursor-not-allowed': !selectedSubscription,
        }" @click="activate" v-if="!processingSubscription && !loggedInUser.sme.subscription_plan"
        :disabled="!selectedSubscription">
        Activate
      </button>
    </div>
  </div>
  <confirmation-dialog :show="showCancellationDialog" title="Are you sure you want to cancel your subscription?"
    message="You will lose access to any active addons and most of the pages" actionLabel="Confirm"
    :loading="loadingCancellation" @cancel="showCancellationDialog = false" @confirm="cancelSubscription()" />
</template>


<script lang="ts">
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ref, defineComponent, PropType } from 'vue';

import {
  PendingSubscriptionPlan,
  SubscriptionPlan,
  SubscriptionPlanPackages,
} from '@/interfaces/subscription';
import instance from '@/axios-interceptor';
import ConfirmationDialog from './ConfirmationDialog.vue';
import { LoggedInUser } from '@/interfaces/billing';

export default defineComponent({
  name: 'UpgradeSubscription',
  props: {
    pendingSubscriptionPlans: {
      type: Array as PropType<PendingSubscriptionPlan[]>,
      required: false,
      default: () => [],
    },
    processingSubscription: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ConfirmationDialog,
  },
  setup() {
    const loadingCancellation = ref(false);
    const showCancellationDialog = ref(false);
    const selectedSubscription = ref('');
    const subscriptionPlanPackages: SubscriptionPlanPackages[] = [
      {
        name: 'Starter',
        price: 'Price: $15/month',
        features: 'Features: Basic Token & QR Code system',
      },
    ];

    return {
      subscriptionPlanPackages,
      loadingCancellation,
      selectedSubscription,
      showCancellationDialog,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    isUpgradeButtonDisabled(): boolean {
      const loggedInUser = this.loggedInUser as LoggedInUser;

      return (
        this.pendingSubscriptionPlans.length > 0 ||
        (loggedInUser.sme.subscription_plan &&
          loggedInUser.sme.subscription_plan.name === this.selectedSubscription) ||
        loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago
      );
    },
    nextBillingDate() {
      const loggedInUser = this.loggedInUser as LoggedInUser;

      const billingDate = moment.utc(
        loggedInUser.sme.billings[0].due_date
      );
      const formattedDate = billingDate.format('MMMM Do, YYYY');

      return `${formattedDate}`;
    },
  },
  methods: {
    ...mapActions(['fetchUser']),
    formatDate(date: any) {
      if (date !== '') {
        return moment(date).format('dddd, MMMM Do YYYY');
      }
    },
    upgrade() {
      if (
        this.selectedSubscription === this.loggedInUser.sme.subscription_plan?.name
      ) {
        return;
      }

      let subscriptionID = 0;

      this.loggedInUser.sme.partner.subscription_plans.filter(
        (subscription: SubscriptionPlan) => {
          if (subscription.name === this.selectedSubscription) {
            subscriptionID = subscription.id;
            return this.$emit('change-subscription', subscriptionID);
          }
        }
      );

      if (!subscriptionID) {
        this.$toast.error(
          'There is a problem with the selected subscription. Contact us for support',
          {
            position: 'top-right',
            duration: 7000,
          }
        );
      }
    },
    cancelSubscription() {
      this.loadingCancellation = true;
      instance
        .post('partner/subscription/sme-cancel/', {
          sme: this.loggedInUser.sme.id,
        })
        .then(() => {
          this.loadingCancellation = false;
          this.$toast.success('Subscription cancelled successfully', {
            position: 'top-right',
          });
          this.showCancellationDialog = false;
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error: any) => {
          this.loadingCancellation = false;
          if (error.response) {
            this.$toast.error(error.response.data.message, {
              position: 'top-right',
            });
          }
        });
    },
    activate() {
      if (
        this.selectedSubscription === this.loggedInUser.sme.subscription_plan?.name
      ) {
        return;
      }

      let subscriptionID = 0;

      this.loggedInUser.sme.partner.subscription_plans.filter(
        (subscription: SubscriptionPlan) => {
          if (subscription.name === this.selectedSubscription) {
            subscriptionID = subscription.id;
            return this.$emit('activate-subscription', subscriptionID);
          }
        }
      );

      if (!subscriptionID) {
        this.$toast.error(
          'There is a problem with the selected subscription. Contact us for support',
          {
            position: 'top-right',
            duration: 7000,
          }
        );
      }
    },
  },
  mounted() {
    this.selectedSubscription = this.loggedInUser.sme.subscription_plan?.name;
  },
});
</script>

<style></style>
