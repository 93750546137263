<template>
  <div class="bg-white h-[30rem] lg:w-[49%] min-h-[21.432rem] rounded-[0.938rem] py-3 px-6 shadow lg:mb-0 mb-5">
    <span class="font-poppins font-semibold md:text-[1.01rem] text-[0.8rem] text-[#212121]">Analytics</span>
    <div class="flex justify-between items-center">
      <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">New Users joined/ New Viewers</span>
      <div class="flex items-center">
        <button v-for="item in changeLabelsArr" :key="item.id"
          class="mx-1 font-poppins text-[0.556rem] font-semibold w-[2.828rem] h-[1.576rem] flex items-center justify-center rounded-[0.278rem]"
          :class="item.id === currentButton
            ? 'bg-[#3F4254] text-[#FFFFFF]'
            : 'text-[#B5B5C3] '
            " @click="handleCurrentButton(item.id)">
          {{ item.label }}
        </button>
      </div>
    </div>
    <div class="w-full flex">
      <Line id="my-chart-id" :options="chartOptions" :data="currentButton === 2
        ? chartData
        : currentButton === 3
          ? chartDataWeek
          : chartDataYear
        " />
    </div>

    <div>
      <div class="date-container">
        <p v-if="currentButton === 2" class="month-year">{{ currentMonthName }} - {{ currentYear }}</p>
        <p v-else-if="currentButton === 3" class="week-range">
          {{ weekStartDate.getDate() }} {{ currentMonthName }} - {{ weekEndDate.getDate() }} {{ currentMonthName }}
        </p>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import {
  labelsMonth,
  datasetsMonth,
  changeLabels,
  labelsWeek,
  datasetsWeek,
  labelsYear,
  datasetsYear,
  currentMonth,
  currentYear,
  weekEndDate,
  weekStartDate
} from '../../helpers/stub_data/charts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { defineProps } from 'vue';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const props = defineProps<{
  weekly_data: { new_users: number[] },
  monthly_data: { new_users: number[] },
  yearly_data: { new_users: number[] },
}>();

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const currentMonthName = monthNames[currentMonth - 1];

const chartData = ref({
  labels: labelsMonth,
  datasets: datasetsMonth,
});
const chartOptions = ref({
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      min: 0
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the second series from the legend
    },
  },
});
const chartDataWeek = ref({
  labels: labelsWeek,
  datasets: datasetsWeek,
});
const chartDataYear = ref({
  labels: labelsYear,
  datasets: datasetsYear,
});
const changeLabelsArr = ref<any[]>(changeLabels);
const currentButton = ref<number>(1);

const handleCurrentButton = (id: number) => {
  currentButton.value = id;
};

// Watch for changes in the props and update chart data accordingly
watch(() => props.weekly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsWeek[0].data = valuesArray;
  // Log the values to verify

  chartDataWeek.value = {
    ...chartDataWeek.value,
    datasets: [...datasetsWeek]
  };
}, { deep: true });

watch(() => props.monthly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsMonth[0].data = valuesArray;
  // Log the values to verify

  chartData.value = {
    ...chartData.value,
    datasets: [...datasetsMonth]
  };

},
  { deep: true }
);

watch(() => props.yearly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsYear[0].data = valuesArray;
  // Log the values to verify

  chartDataYear.value = {
    ...chartDataYear.value,
    datasets: [...datasetsYear]
  };
}, { deep: true });

</script>

<style scoped>
.date-container {
  padding: 5px;
  /* max-width: 400px; */
  margin: 5px;
  background-color: #f9f9f9;
}

.date-container p {
  margin: 0;
  padding: 10px;
  /* font-size: 1.2em; */
  color: #333;
}

.month-year {
  font-weight: bold;
  color: #2c3e50;
}

.week-range {
  font-style: italic;
  font-weight: bold;
  color: #16a085;
}
</style>
