import { createApp } from 'vue';
import Toast from 'vue-toast-notification';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3ColorPicker from 'vue3-colorpicker';

import './styles/app.css';
import App from './App.vue';
import store from './store';
import router from './router';
// import './registerServiceWorker';
import 'vue3-colorpicker/style.css';
import 'vue-toast-notification/dist/theme-sugar.css';


import DashboardHeader from '@/components/markup/DashboardHeader.vue';
import SuspendedPageMessage from '@/components/Common/SuspendedPageMessage.vue';
import PaymentRequiredMessage from '@/components/Common/PaymentRequiredMessage.vue';
import ActivateSubscriptionMessage from '@/components/Common/ActivateSubscriptionMessage.vue';
import ConfirmationDialog from '@/components/Common/ConfirmationDialog.vue';


const swalOptions = {
  confirmButtonColor: '#11b874',
  cancelButtonColor: '#ff7674',
  timer: 2000,
};

createApp(App)
  .use(Toast)
  .use(store)
  .use(router)
  .use(VueSweetalert2, swalOptions)
  .use(Vue3ColorPicker)
  .component('dashboard-header', DashboardHeader)
  .component('suspended-page-message', SuspendedPageMessage)
  .component('payment-required-message', PaymentRequiredMessage)
  .component('activate-subscription-message', ActivateSubscriptionMessage)
  .component('confirmation-dialog', ConfirmationDialog)
  .mount('#app');
