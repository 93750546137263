import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white h-[30rem] lg:w-[49%] min-h-[21.432rem] rounded-[0.938rem] py-3 px-6 shadow lg:mb-0 mb-5" }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex" }
const _hoisted_6 = { class: "date-container" }
const _hoisted_7 = {
  key: 0,
  class: "month-year"
}
const _hoisted_8 = {
  key: 1,
  class: "week-range"
}

import { ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import {
  labelsMonth,
  datasetsMonth,
  changeLabels,
  labelsWeek,
  datasetsWeek,
  labelsYear,
  datasetsYear,
  currentMonth,
  currentYear,
  weekEndDate,
  weekStartDate
} from '../../helpers/stub_data/charts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';





export default /*@__PURE__*/_defineComponent({
  __name: 'SmeAnalytics',
  props: {
    weekly_data: {},
    monthly_data: {},
    yearly_data: {}
  },
  setup(__props: any) {

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const props = __props;

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const currentMonthName = monthNames[currentMonth - 1];

const chartData = ref({
  labels: labelsMonth,
  datasets: datasetsMonth,
});
const chartOptions = ref({
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      min: 0
    },
  },
  plugins: {
    legend: {
      display: false, // Hide the second series from the legend
    },
  },
});
const chartDataWeek = ref({
  labels: labelsWeek,
  datasets: datasetsWeek,
});
const chartDataYear = ref({
  labels: labelsYear,
  datasets: datasetsYear,
});
const changeLabelsArr = ref<any[]>(changeLabels);
const currentButton = ref<number>(1);

const handleCurrentButton = (id: number) => {
  currentButton.value = id;
};

// Watch for changes in the props and update chart data accordingly
watch(() => props.weekly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsWeek[0].data = valuesArray;
  // Log the values to verify

  chartDataWeek.value = {
    ...chartDataWeek.value,
    datasets: [...datasetsWeek]
  };
}, { deep: true });

watch(() => props.monthly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsMonth[0].data = valuesArray;
  // Log the values to verify

  chartData.value = {
    ...chartData.value,
    datasets: [...datasetsMonth]
  };

},
  { deep: true }
);

watch(() => props.yearly_data, (newVal) => {
  const valuesArray = Object.values(newVal);
  datasetsYear[0].data = valuesArray;
  // Log the values to verify

  chartDataYear.value = {
    ...chartDataYear.value,
    datasets: [...datasetsYear]
  };
}, { deep: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.01rem] text-[0.8rem] text-[#212121]" }, "Analytics", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "New Users joined/ New Viewers", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(changeLabelsArr.value, (item) => {
          return (_openBlock(), _createElementBlock("button", {
            key: item.id,
            class: _normalizeClass(["mx-1 font-poppins text-[0.556rem] font-semibold w-[2.828rem] h-[1.576rem] flex items-center justify-center rounded-[0.278rem]", item.id === currentButton.value
            ? 'bg-[#3F4254] text-[#FFFFFF]'
            : 'text-[#B5B5C3] '
            ]),
            onClick: ($event: any) => (handleCurrentButton(item.id))
          }, _toDisplayString(item.label), 11, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(Line), {
        id: "my-chart-id",
        options: chartOptions.value,
        data: currentButton.value === 2
        ? chartData.value
        : currentButton.value === 3
          ? chartDataWeek.value
          : chartDataYear.value
        
      }, null, 8, ["options", "data"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_6, [
        (currentButton.value === 2)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(currentMonthName)) + " - " + _toDisplayString(_unref(currentYear)), 1))
          : (currentButton.value === 3)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(weekStartDate).getDate()) + " " + _toDisplayString(_unref(currentMonthName)) + " - " + _toDisplayString(_unref(weekEndDate).getDate()) + " " + _toDisplayString(_unref(currentMonthName)), 1))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})