<template>
  <iframe :src="this.file.url" style="width: 100%; height: 600px;"></iframe>
  <button class="p-2 bg-primary text-black shadow-lg rounded-lg relative" @click="openPdfInNewTab">View in New Tab</button>
</template>
   
<script>
export default {
  name: "DocumentViewer",
  components: {
  },
  props: {
    file: Object,
  },
  methods: {
    openPdfInNewTab() {
      const pdfUrl = this.file.url; // Replace with the actual URL to your PDF
      const newTab = window.open(pdfUrl, '_blank'); // Open the PDF URL in a new tab
      if (newTab) {
        newTab.focus(); // Ensure the new tab is in focus
      }
    },
  },
};
</script>