import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../assets/close-icon.svg'


const _hoisted_1 = { class: "popup-modal" }
const _hoisted_2 = { class: "popup-modal-content rounded-xl" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 mx-auto" }
const _hoisted_5 = { class: "text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_6 = { class: "mb-5" }
const _hoisted_7 = { class: "mb-5" }
const _hoisted_8 = {
  key: 0,
  class: "w-full flex lg:justify-end justify-start items-center mt-3"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex lg:justify-end justify-start items-center mt-3"
}
const _hoisted_10 = {
  type: "submit",
  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] font-medium",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clip_loader = _resolveComponent("clip-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "float-right"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "w-[0.8rem] mt-1",
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.heading), 1),
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateSSID && _ctx.updateSSID(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "SSID:", -1)),
              _withDirectives(_createElementVNode("input", {
                placeholder: "Enter new SSID name",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ssid) = $event)),
                required: "",
                type: "text",
                id: "small-input",
                maxlength: "30",
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
              }, null, 512), [
                [_vModelText, _ctx.ssid]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Location:", -1)),
              _withDirectives(_createElementVNode("input", {
                placeholder: "Enter new location name",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.location) = $event)),
                required: "",
                type: "text",
                id: "small-input",
                maxlength: "30",
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
              }, null, 512), [
                [_vModelText, _ctx.location]
              ])
            ]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[7] || (_cache[7] = [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
                  }, " UPDATE ", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("button", _hoisted_10, [
                    _createVNode(_component_clip_loader, { color: "#fff" })
                  ])
                ]))
          ], 32)
        ])
      ])
    ])
  ]))
}