import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-3 w-3",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_3 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-3 w-3",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_4 = { class: "mt-3 bg-[#f4f4f4] text-[] rounded-[5px] px-[9px] py-[7px] submenu" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-3 w-3",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_8 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-3 w-3",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_9 = { class: "mt-3 bg-[#D6D6D6] rounded-[5px] px-[7px] py-[7px] sun-subMenu w-[4.8rem]" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "font-semibold text-[10px] text-[#414354] font-poppins mt-1 break-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["w-[6.3rem] flex items-center justify-center flex-col p-3 rounded-[0.438rem] mb-2", [_ctx.classes ? _ctx.classes : '', _ctx.isActive ? 'shadow-lg bg-white ' : '']]),
    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.subMenus?.length > 0 ? _ctx.toggleSubMenu : _ctx.onSetActive(_ctx.routePath)))
  }, [
    (_ctx.icon.includes('fa'))
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(_ctx.icon),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSubMenu && _ctx.toggleSubMenu(...args)))
        }, null, 2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.icon,
          alt: "africa",
          class: "w-[1.188rem] h-[1.188rem] mb-1",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSubMenu && _ctx.toggleSubMenu(...args)))
        }, null, 8, _hoisted_1)),
    _createElementVNode("span", {
      class: "font-semibold text-[10px] text-[#414354] font-poppins mt-1",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSubMenu && _ctx.toggleSubMenu(...args)))
    }, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSubMenu && _ctx.toggleSubMenu(...args))),
      class: ""
    }, [
      (_ctx.subMenus?.length > 0 && _ctx.showSubMenu)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[9] || (_cache[9] = [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "M19 9l-7 7-7-7"
            }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.subMenus?.length > 0 && !_ctx.showSubMenu)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[10] || (_cache[10] = [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "M5 15l7-7 7 7"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subMenus, (subMenu) => {
        return (_openBlock(), _createElementBlock("button", {
          key: subMenu.id,
          type: "button",
          class: _normalizeClass(["w-[4.313rem] flex items-center justify-center flex-col p-2 rounded-[0.438rem] mb-1 last:border-b-0 focus:ring-transparent", { subselectedTab: _ctx.activeMenuPath === subMenu.path }]),
          onClick: ($event: any) => (
          subMenu?.childSubMenus && subMenu.childSubMenus?.length > 0
            ? _ctx.toggleSubMenu
            : _ctx.onSetActive(subMenu.path)
          )
        }, [
          (subMenu.icon.includes('fa'))
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass(subMenu.icon),
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSubMenu && _ctx.toggleSubMenu(...args)))
              }, null, 2))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: subMenu.icon,
                alt: "africa",
                class: "w-[1.188rem] h-[1.188rem] mb-1",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleChildSubMenu && _ctx.toggleChildSubMenu(...args)))
              }, null, 8, _hoisted_6)),
          _createElementVNode("span", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleChildSubMenu && _ctx.toggleChildSubMenu(...args))),
            class: "text-textDark font-semibold text-[10px] font-poppins mt-1 break-all"
          }, _toDisplayString(subMenu.title), 1),
          _createElementVNode("div", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleChildSubMenu && _ctx.toggleChildSubMenu(...args)))
          }, [
            (
            subMenu?.childSubMenus &&
            subMenu.childSubMenus?.length > 0 &&
            _ctx.showChildrenMenus
          )
              ? (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[11] || (_cache[11] = [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M19 9l-7 7-7-7"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (
            subMenu?.childSubMenus &&
            subMenu.childSubMenus?.length > 0 &&
            !_ctx.showChildrenMenus
          )
              ? (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[12] || (_cache[12] = [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M5 15l7-7 7 7"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenu.childSubMenus, (childsubMenu) => {
              return (_openBlock(), _createElementBlock("button", {
                key: childsubMenu.id,
                type: "button",
                class: _normalizeClass(["w-full flex items-center justify-center flex-col px-1 py-2 rounded-[0.438rem] mb-2 last:border-b-0", { selectedTab: _ctx.activeChildMenuPath === childsubMenu.path }]),
                onClick: ($event: any) => (_ctx.onSetchildActive(childsubMenu.path))
              }, [
                (_ctx.icon.includes('fa'))
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(childsubMenu.icon)
                    }, null, 2))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: childsubMenu.icon,
                      alt: "africa",
                      class: "w-[1.188rem] h-[1.188rem] mb-1"
                    }, null, 8, _hoisted_11)),
                _createElementVNode("span", _hoisted_12, _toDisplayString(childsubMenu.title), 1)
              ], 10, _hoisted_10))
            }), 128))
          ], 512), [
            [_vShow, _ctx.showChildrenMenus &&
          subMenu?.childSubMenus &&
          subMenu.childSubMenus.length
          ]
          ])
        ], 10, _hoisted_5))
      }), 128))
    ], 512), [
      [_vShow, _ctx.showSubMenu && _ctx.subMenus?.length > 0]
    ])
  ], 2))
}