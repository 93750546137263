import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/plus-icon.png'
import _imports_2 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 2,
  class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative"
}
const _hoisted_7 = { class: "md:w-[100%] w-[50%] mb-2" }
const _hoisted_8 = { class: "relative block" }
const _hoisted_9 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_10 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_11 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { class: "flex flex-col ml-3" }
const _hoisted_14 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex flex-col" }
const _hoisted_18 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_19 = { class: "flex flex-col" }
const _hoisted_20 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "flex justify-end" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "spinner-container" }
const _hoisted_26 = {
  key: 0,
  class: "spinner"
}
const _hoisted_27 = { class: "flex items-center" }
const _hoisted_28 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_header = _resolveComponent("dashboard-header")!
  const _component_payment_required_message = _resolveComponent("payment-required-message")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_dashboard_header, {
      title: "Adverts",
      description: "",
      icon: "fa-regular fa-calendar",
      iconType: "class"
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[7] || (_cache[7] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ]),
        (_ctx.loggedInUser.sme?.is_active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (
            _ctx.shouldDisplayButton()
          )
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routeToAddEditAdvert && _ctx.routeToAddEditAdvert(...args))),
                    type: "button",
                    class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]"
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      class: "w-[0.6rem] h-[0.6rem] mr-1",
                      alt: ""
                    }, null, -1),
                    _createTextVNode(" Add Advert ")
                  ])))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_payment_required_message)
        ]))
      : (!_ctx.loggedInUser.sme?.is_active)
        ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 1 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "",
                    class: "w-[0.8rem] h-[0.8rem]"
                  })
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
                  class: "font-normal rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Search",
                  type: "text",
                  name: "search"
                }, null, 512), [
                  [_vModelText, _ctx.searchQuery]
                ])
              ])
            ]),
            (_ctx.showButtons())
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
                    _createElementVNode("span", {
                      class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                    }, "clear")
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromSme && _ctx.unallocateRoutersFromSme(...args)))
                    }, " unassign SME router "),
                    _createElementVNode("button", {
                      type: "button",
                      class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromPartner && _ctx.unallocateRoutersFromPartner(...args)))
                    }, " unassign Partner router ")
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_SmeTable, { tableHeaderData: _ctx.advertTableHeadings }, {
              tableBodyData: _withCtx(() => [
                (!_ctx.paginatedTableData.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " No records "))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (advert) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: advert.id,
                        class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(advert?.title), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.realmDetails(advert.id)),
                              class: "cursor-pointer capitalize text-[#4e8d6e] mb-0.5"
                            }, _toDisplayString(advert.realm.name), 9, _hoisted_16)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(advert.is_active ? 'Enabled' : 'Disabled'), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.createdAt(advert.created)), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.downloadFile(advert.file)),
                              class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                            }, _cache[12] || (_cache[12] = [
                              _createTextVNode("Download"),
                              _createElementVNode("i", { class: "ml-1.5 fa-solid fa-download" }, null, -1)
                            ]), 8, _hoisted_21)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_22, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.viewDetails(advert.id)),
                              class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 mr-2 inline-block text-center cursor-pointer"
                            }, "View Details", 8, _hoisted_23)
                          ]),
                          _createElementVNode("div", null, [
                            (!_ctx.deleting || _ctx.currentDeletionFileID !== advert.id)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.deleteAdvert(advert.id)),
                                  class: "capitalize p-2 rounded bg-[#f24236] text-white mb-0.5 inline-block text-center cursor-pointer"
                                }, "Delete", 8, _hoisted_24))
                              : _createCommentVNode("", true),
                            (_ctx.deleting && _ctx.currentDeletionFileID === advert.id)
                              ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                  key: 1,
                                  color: "#d7191c"
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    }), 128))
              ]),
              _: 1
            }, 8, ["tableHeaderData"]),
            _createElementVNode("div", _hoisted_25, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26))
                : _createCommentVNode("", true)
            ]),
            (_ctx.propLoaded)
              ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                  key: 1,
                  perPageItems: _ctx.perSize,
                  pageNumber: _ctx.page,
                  ApiData: _ctx.apiData,
                  onHandleNext: _ctx.handlleNext,
                  onHandlePrev: _ctx.handlePrev
                }, {
                  section: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                        _createElementVNode("span", _hoisted_28, [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedOption) = $event)),
                            onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                          }, _cache[13] || (_cache[13] = [
                            _createElementVNode("option", { value: "10" }, "10", -1),
                            _createElementVNode("option", { value: "20" }, "20", -1),
                            _createElementVNode("option", { value: "30" }, "30", -1),
                            _createElementVNode("option", { value: "40" }, "40", -1),
                            _createElementVNode("option", { value: "50" }, "50", -1)
                          ]), 544), [
                            [_vModelSelect, _ctx.selectedOption]
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
              : _createCommentVNode("", true)
          ]))
  ], 64))
}