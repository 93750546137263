<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            {{ submit }}
          </h3>
          <form>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Number of
                vouchers:</label>
              <select id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                v-model="number_vouchers" @change="handleChange" required>
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Router:</label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                id="small-input" v-model="router" required>
                <option value="">Select Box</option>
                <option v-for="router in routers" :key="router.id" :value="router.id">
                  <span v-if="router.ssid && router.serial_number">{{ router.ssid }} - {{ router.serial_number }}</span>
                  <span v-else>{{ router.serial_number }}</span>
                </option>
              </select>
            </div>
            <!-- <div class="mb-2" v-if="number_vouchers > 1">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Batch Name:</label
              >
              <input
                placeholder="Enter batch name"
                v-model="batch"
                required
                type="text"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
              />
            </div> -->
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Time Valid
                (minutes):</label>
              <input v-model="time_valid" type="number" min="5" id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Show QR
                Code:</label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                v-model="is_qrcode" required id="small-input">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <div class="mb-2" v-if="is_qrcode">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Re-Usable:</label>
              <p class="text-xs mb-2 text-gray">
                Unlike time-bound access tokens, our reusable QR code remains
                perennial, renewing itself automatically after each expiration
                with the same duration, ensuring seamless and ongoing access
                without the need for generating new codes.
              </p>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                v-model="reusable" required id="small-input">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
              <button v-if="!loading" @click="addVoucher" type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                GENERATE NEW VOUCHER(S)
              </button>
              <button v-else type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                PROCESSING...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import instance from '@/axios-interceptor';
export default {
  name: 'VoucherForm',
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      routers: [
        {
          id: '',
          ssid: '',
          serial_number: '',
        },
      ],
      number_vouchers: '1',
      batch: '',
      time_valid: 5,
      router: '',
      source: 'website',
      is_qrcode: false,
      reusable: false,
      submit: 'Generate new voucher(s)',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.fetchRouters();
  },
  methods: {
    fetchRouters() {
      instance
        .get(`routers/?sme=${this.loggedInUser.sme.id}`)
        .then((response) => {
          this.routers = response.data.results;
          console.log("ROUTERS FETCHED: ", response.data.results);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async addVoucher() {
      const voucher = {
        number_vouchers: this.number_vouchers,
        batch: this.batch,
        time_valid: this.time_valid,
        router: this.router,
        source: 'website',
        is_qrcode: this.is_qrcode,
        re_usable: this.reusable,
      };

      this.loading = true;

      await instance
        .post(`transactions/`, voucher)
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.$emit('close');
            if (response.data.detail) {
              this.$toast.success(response.data.detail, {
                position: 'top-right',
              });
            } else {
              this.$toast.success('Voucher Added Successfully', {
                position: 'top-right',
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
