import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center bg-gray-100" }
const _hoisted_2 = { class: "max-w-lg p-6 bg-white rounded-lg shadow" }
const _hoisted_3 = {
  key: 0,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_4 = {
  key: 1,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_5 = { class: "text-gray-600" }
const _hoisted_6 = { class: "text-gray-600" }
const _hoisted_7 = { class: "text-primary mt-3 text-sm" }
const _hoisted_8 = {
  key: 2,
  class: "mb-4 p-4 border rounded-md border-primary"
}
const _hoisted_9 = { class: "text-gray-600" }
const _hoisted_10 = {
  key: 3,
  class: "mb-4 p-4 border rounded-md border-[#f24236]"
}
const _hoisted_11 = {
  key: 4,
  class: "text-2xl font-bold mb-4 capitalize"
}
const _hoisted_12 = {
  key: 5,
  class: "text-2xl font-bold mb-4"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex items-start" }
const _hoisted_15 = ["id", "value"]
const _hoisted_16 = ["for"]
const _hoisted_17 = { class: "text-gray-600" }
const _hoisted_18 = { class: "text-gray-600" }
const _hoisted_19 = {
  key: 0,
  class: "text-primary mt-3 text-sm"
}
const _hoisted_20 = {
  key: 6,
  class: "text-[#f24236] mt-3 text-sm mb-3"
}
const _hoisted_21 = {
  key: 7,
  class: "text-[#f24236] mt-3 text-sm mb-3"
}
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.loggedInUser.sme.subscription_plan)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[5] || (_cache[5] = [
              _createElementVNode("div", null, [
                _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " No Active Subscription "),
                _createElementVNode("p", { class: "mt-3 text-sm" }, " You have no active subscription. Consider activating a subscription to get access to most of the pages ")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.pendingSubscriptionPlans.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingSubscriptionPlans, (pendingSubscription) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: pendingSubscription.id
                }, [
                  _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " Pending Subscription Change ", -1)),
                  _createElementVNode("p", _hoisted_5, " From: " + _toDisplayString(pendingSubscription.current_subscription_plan.name), 1),
                  _createElementVNode("p", _hoisted_6, " To: " + _toDisplayString(pendingSubscription.new_subscription_plan.name), 1),
                  _createElementVNode("p", _hoisted_7, [
                    _cache[6] || (_cache[6] = _createTextVNode(" The ")),
                    _createElementVNode("strong", null, _toDisplayString(pendingSubscription.new_subscription_plan.name), 1),
                    _cache[7] || (_cache[7] = _createTextVNode(" subscription will be applied on the next billing cycle on: ")),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.nextBillingDate), 1),
                    _cache[8] || (_cache[8] = _createTextVNode(" UTC+3 (EAT) Time Zone. "))
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.loggedInUser.sme.is_in_trial_period)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _cache[12] || (_cache[12] = _createElementVNode("h3", { class: "font-bold text-xl mb-2 text-primary" }, "Free Trial Period", -1)),
                _createElementVNode("p", _hoisted_9, [
                  _cache[10] || (_cache[10] = _createTextVNode(" You are currently in a 30-day trial period. Your selected subscription plan will come into effect on ")),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.formatDate(_ctx.loggedInUser.sme.billings[0].due_date)), 1),
                  _cache[11] || (_cache[11] = _createTextVNode(", and you will be billed on that date after completing the payment for the subscription. "))
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[13] || (_cache[13] = [
              _createElementVNode("div", null, [
                _createElementVNode("h3", { class: "font-bold text-xl mb-2 text-[#f24236]" }, " Pending Payment for 1 Billing "),
                _createElementVNode("p", { class: "text-gray-600" }, " You have not paid for the last billing. Please go to the billings page and make the payment. ")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (!!_ctx.loggedInUser.sme.subscription_plan)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_11, " Base subscription "))
          : _createCommentVNode("", true),
        (!_ctx.loggedInUser.sme.subscription_plan)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_12, " Activate Subscription "))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionPlanPackages, (plan) => {
          return (_openBlock(), _createElementBlock("div", {
            key: plan.name,
            class: _normalizeClass(["mb-4 cursor-pointer", _ctx.loggedInUser.sme.subscription_plan?.name === plan.name
          ? 'border-primary border-[1px] rounded-md p-4'
          : ''
          ]),
            onClick: ($event: any) => (_ctx.selectedSubscription = plan.name)
          }, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                id: plan.name,
                value: plan.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSubscription) = $event)),
                class: "mt-1 mr-2"
              }, null, 8, _hoisted_15), [
                [_vModelRadio, _ctx.selectedSubscription]
              ]),
              _createElementVNode("label", {
                for: plan.name,
                class: "font-bold"
              }, _toDisplayString(plan.name), 9, _hoisted_16)
            ]),
            _createElementVNode("p", _hoisted_17, _toDisplayString(plan.price), 1),
            _createElementVNode("p", _hoisted_18, _toDisplayString(plan.features), 1),
            (_ctx.loggedInUser.sme.subscription_plan?.name === plan.name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, " Current subscription plan "))
              : _createCommentVNode("", true)
          ], 10, _hoisted_13))
        }), 128)),
        (_ctx.pendingSubscriptionPlans.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_20, " Warning: You have a pending subscription plan change. Thus, you cannot upgrade or downgrade your subscription plan till the pending one is applied on the next billing cycle. "))
          : _createCommentVNode("", true),
        (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
          ? (_openBlock(), _createElementBlock("p", _hoisted_21, " Warning: You have pending billing payment. Thus, you cannot upgrade or downgrade your subscription plan till you pay for the outstanding billings "))
          : _createCommentVNode("", true),
        (!_ctx.processingSubscription && !!_ctx.loggedInUser.sme.subscription_plan)
          ? (_openBlock(), _createElementBlock("button", {
              key: 8,
              type: "button",
              class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-[15px] text-[#f24236] rounded-[0.432rem] p-3 font-medium border mt-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCancellationDialog = true))
            }, " Cancel "))
          : _createCommentVNode("", true),
        (!_ctx.processingSubscription && !_ctx.loggedInUser.sme.subscription_plan)
          ? (_openBlock(), _createElementBlock("button", {
              key: 9,
              type: "button",
              class: _normalizeClass(["w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2", {
          'cursor-not-allowed': !_ctx.selectedSubscription,
        }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.activate && _ctx.activate(...args))),
              disabled: !_ctx.selectedSubscription
            }, " Activate ", 10, _hoisted_22))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_confirmation_dialog, {
      show: _ctx.showCancellationDialog,
      title: "Are you sure you want to cancel your subscription?",
      message: "You will lose access to any active addons and most of the pages",
      actionLabel: "Confirm",
      loading: _ctx.loadingCancellation,
      onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCancellationDialog = false)),
      onConfirm: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelSubscription()))
    }, null, 8, ["show", "loading"])
  ], 64))
}