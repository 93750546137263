import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative"
}
const _hoisted_7 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_8 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { id: "TabContent" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "mt-5 flex items-center" }
const _hoisted_14 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { class: "bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center" }
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = { class: "flex flex-col ml-3" }
const _hoisted_19 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_20 = {
  key: 0,
  class: "dotonline ml-2"
}
const _hoisted_21 = {
  key: 1,
  class: "dotoffline ml-2"
}
const _hoisted_22 = { class: "text-[#464E5F]" }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = {
  key: 0,
  class: "text-[#464E5F]"
}
const _hoisted_25 = { class: "text-[#464E5F]" }
const _hoisted_26 = { class: "text-[#464E5F]" }
const _hoisted_27 = { class: "flex flex-col" }
const _hoisted_28 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_29 = { class: "text-[#464E5F]" }
const _hoisted_30 = { class: "flex items-center justify-end" }
const _hoisted_31 = { class: "bg-[#F3F6F9] w-[4rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2 p-2" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["src"]
const _hoisted_34 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_35 = {
  key: 1,
  class: "spinner-container"
}
const _hoisted_36 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_payment_required_message = _resolveComponent("payment-required-message")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_AnalyticsPage = _resolveComponent("AnalyticsPage")!
  const _component_edit_form = _resolveComponent("edit-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Allocated Routers",
      icon: _ctx.icon.router
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[6] || (_cache[6] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_payment_required_message)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", null, [
                    _createElementVNode("ul", _hoisted_8, [
                      _createElementVNode("li", _hoisted_9, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'router'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                          id: "microfranchisee",
                          "data-tabs-target": "#microfranchisee",
                          type: "button",
                          role: "tab",
                          "aria-controls": "microfranchisee",
                          "aria-selected": "false",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetActiveTab('router')))
                        }, " Routers ", 2)
                      ]),
                      _createElementVNode("li", _hoisted_10, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'analytics'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                          id: "routers",
                          "data-tabs-target": "#routers",
                          type: "button",
                          role: "tab",
                          "aria-controls": "routers",
                          "aria-selected": "false",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetActiveTab('analytics')))
                        }, " Analytics ", 2)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.activeTab === 'router')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (_ctx.propLoaded)
                            ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                                key: 0,
                                perPageItems: _ctx.perSize,
                                pageNumber: _ctx.page,
                                ApiData: _ctx.apiData,
                                onHandleNext: _ctx.handlleNext,
                                onHandlePrev: _ctx.handlePrev
                              }, {
                                section: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("div", _hoisted_13, [
                                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                                      _createElementVNode("span", _hoisted_14, [
                                        _withDirectives(_createElementVNode("select", {
                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                        }, _cache[8] || (_cache[8] = [
                                          _createElementVNode("option", { value: "10" }, "10", -1),
                                          _createElementVNode("option", { value: "20" }, "20", -1),
                                          _createElementVNode("option", { value: "30" }, "30", -1),
                                          _createElementVNode("option", { value: "40" }, "40", -1),
                                          _createElementVNode("option", { value: "50" }, "50", -1)
                                        ]), 544), [
                                          [_vModelSelect, _ctx.selectedOption]
                                        ])
                                      ])
                                    ])
                                  ])
                                ]),
                                _: 1
                              }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_SmeTable, {
                            tableHeaderData: _ctx.routerTableHeaders,
                            "table-name": _ctx.tableName
                          }, {
                            tableBodyData: _withCtx(() => [
                              (_ctx.paginatedTableData.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (router) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: router.id,
                                      class: "table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                    }, [
                                      _createElementVNode("td", null, [
                                        _createElementVNode("div", _hoisted_15, [
                                          _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("img", {
                                              src: router.location === 'In Transit'
                            ? require('../../assets/router.png')
                            : router.location === 'Manufacturer'
                              ? require('../../assets/transit_router.png')
                              : require('../../assets/active_router.png')
                            ,
                                              alt: router.nasidentifier,
                                              class: "w-[1.89rem] h-[1.779rem]"
                                            }, null, 8, _hoisted_17)
                                          ]),
                                          _createElementVNode("div", _hoisted_18, [
                                            _createElementVNode("span", _hoisted_19, _toDisplayString(router.serial_number), 1),
                                            (router.is_online)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_20))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_21))
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("span", _hoisted_22, _toDisplayString(router.ssid), 1)
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("div", _hoisted_23, [
                                          (router.os_version_id)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_24, "v" + _toDisplayString(router.os_version_id), 1))
                                            : _createCommentVNode("", true)
                                        ])
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("span", _hoisted_25, _toDisplayString(router.model_number), 1)
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("span", _hoisted_26, _toDisplayString(router.location_name), 1)
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createElementVNode("span", _hoisted_28, _toDisplayString(router.ip_address), 1),
                                          _createElementVNode("span", null, _toDisplayString(router.last_contact_ip), 1)
                                        ])
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.createdAt(router.created)), 1)
                                      ]),
                                      _createElementVNode("td", null, [
                                        _createElementVNode("div", _hoisted_30, [
                                          _createElementVNode("div", _hoisted_31, [
                                            _createElementVNode("button", {
                                              onClick: ($event: any) => (_ctx.editRouter(router)),
                                              class: "flex"
                                            }, [
                                              _createElementVNode("img", {
                                                src: _ctx.routerTableIcons[1],
                                                alt: "africa",
                                                class: "w-[1.125rem] h-[1.067rem]"
                                              }, null, 8, _hoisted_33),
                                              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ml-2" }, "Edit", -1))
                                            ], 8, _hoisted_32)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  }), 128))
                                : (_openBlock(), _createElementBlock("div", _hoisted_34, _cache[11] || (_cache[11] = [
                                    _createElementVNode("b", null, "No Routers Found", -1)
                                  ])))
                            ]),
                            _: 1
                          }, 8, ["tableHeaderData", "table-name"]),
                          (_ctx.isLoading)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[12] || (_cache[12] = [
                                _createElementVNode("div", { class: "spinner" }, null, -1)
                              ])))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.activeTab === 'analytics')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          _createVNode(_component_AnalyticsPage)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
        ])),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 2,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModal = false)),
          onRouterUpdated: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchRouters())),
          router: _ctx.currentRouter
        }, null, 8, ["router"]))
      : _createCommentVNode("", true)
  ], 64))
}