<template>
  <dashboard-header
    title="Accoutn Info"
    icon="fa-regular fa-user"
    iconType="class"
  >
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[0.8rem] h-[0.8rem] rounded ml-1"
        />
      </div>
    </template>
  </dashboard-header>

  <suspended-page-message
    v-if="!loggedInUser.sme?.is_active"
  ></suspended-page-message>

  <!-- TABS -->
  <div v-else class="w-full my-3">
    <div class="md:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16">
      <div
        class="lg:basis-2/5 relative flex overflow-x-auto pb-4 sm:mx-0 sm:block sm:overflow-visible shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl"
      >
        <div
          class="lg:grid auto-cols-auto grid-flow-col justify-start whitespace-nowrap px-4 sm:mx-auto sm:max-w-2xl sm:grid-cols-3 sm:px-0 sm:text-center lg:grid-flow-row lg:grid-cols-1 lg:text-left"
        >
          <h3 class="font-medium mb-6 px-3 mt-3">Your Profile</h3>
          <div
            :class="[activeTab == 'profile' ? 'bg-white ' : '']"
            class="relative px-3 py-3 listhover rounded-md cursor-pointer"
            @click="handleSetActiveTab('profile')"
          >
            <img
              class="inline-block"
              src="../../assets/user-profile.svg"
              alt=""
            />
            <div class="relative inline-block">
              <div class="text-md text-slate-500">
                <button
                  class="text-[#464E5F] ml-3 font-medium"
                  role="tab"
                  type="button"
                >
                  Your Profile
                </button>
              </div>
            </div>
          </div>
          <div
            :class="[activeTab == 'change-password' ? 'bg-white ' : '']"
            class="relative px-3 py-3 listhover rounded-md cursor-pointer"
            @click="handleSetActiveTab('change-password')"
          >
            <img class="inline-block" src="../../assets/lock.svg" alt="" />
            <div class="relative inline-block">
              <div class="text-md text-slate-500">
                <button class="text-[#464E5F] ml-3 font-medium">
                  Change Password
                </button>
              </div>
            </div>
          </div>
          <div
            :class="[activeTab == 'subscription' ? 'bg-white ' : '']"
            class="relative px-3 py-3 listhover rounded-md cursor-pointer"
            @click="handleSetActiveTab('subscription')"
          >
            <img
              class="inline-block"
              src="../../assets/money.svg"
              width="22"
              height="22"
              alt=""
            />
            <div class="relative inline-block">
              <div class="text-md text-slate-500">
                <button class="text-[#464E5F] ml-3 font-medium">
                  Subscriptions
                </button>
              </div>
            </div>
          </div>
          <div
            class="relative px-3 py-3 listhover rounded-md mb-3 cursor-pointer"
            @click="handleLogout"
          >
            <img class="inline-block" src="../../assets/sign-in.svg" alt="" />
            <div class="relative inline-block">
              <div class="text-md text-blue-600">
                <button class="text-[#464E5F] ml-3 font-medium">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TABS CONTENT -->
      <!-- PROFILE -->
      <div
        class="lg:col-span-3 asis-1/2 full-width"
        :class="[activeTab == 'profile' ? 'block ' : 'hidden']"
      >
        <form @submit.prevent="submitForm">
          <div class="profile-sec full-width">
            <h2 class="font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 mt-5">
              Profile
            </h2>
            <div
              class="mt-6 gap-x-8 gap-y-10 shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl relative full-width grid grid-cols-1 gap-x-25 xl:grid-cols-2"
            >
              <div class="block">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  First Name
                </label>
                <input
                  type="name"
                  name="firstName"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Enter First Name"
                  v-model="firstName"
                />
              </div>
              <div class="block">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Last Name
                </label>
                <input
                  type="name"
                  name="lastName"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Enter Your LastName"
                  v-model="lastName"
                />
              </div>
              <div class="block">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Phone
                </label>
                <input
                  type="name"
                  name="phone"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Enter Phone "
                  v-model="phone"
                />
              </div>
              <div class="block">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Gender
                </label>
                <select
                  id="gender"
                  type="name"
                  class="text-[14px] w-full h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.625rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="gender"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div class="block">
                <div v-if="!newProfilePicture">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                  >
                    Profile Picture
                  </label>
                  <img
                    v-if="profilePicture"
                    :src="profilePicture"
                    alt="profile picture"
                    class="mb-5 object-cover w-[200px] h-[200px]"
                  />
                </div>
                <div v-if="newProfilePicture">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    New Profile Picture
                  </label>
                  <p class="text-xs text-primary mb-2">
                    Click on Save Changes to apply the image
                  </p>
                  <img
                    :src="newProfilePicture"
                    alt="new profile picture"
                    class="mb-5 object-cover w-[200px] h-[200px]"
                  />
                </div>
                <input
                  type="file"
                  ref="profilePicture"
                  @change="onFileChange"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                />
              </div>

              <div class="block">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Enter Your Date of birth"
                  v-model="date_of_birth"
                />
              </div>
            </div>

            <button
              v-if="!updatingProfile"
              class="bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
            >
              Save Changes
            </button>
            <button
              disabled
              v-else
              class="bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
            >
              Updating...
            </button>
          </div>
        </form>

        <div
          class="email-sec mt-8 full-width"
          :class="[activeTab == 'change-password' ? 'd-block ' : 'd-none']"
        >
          <h2
            class="font-medium mt-6 inline-block md:text-[1.8rem] text-[1.4rem]"
          >
            Change Email Address
          </h2>
          <div
            class="mt-6 shadow-lg p-6 gap-x-8 gap-y-10 shadow-gray-900/5 bg-white rounded-2xl relative grid full-width grid-cols-1 gap-x-25 xl:grid-cols-2"
          >
            <label class="block">
              <span
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
              >
                Email Address
              </span>
              <input
                type="email"
                name="email"
                readonly
                class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Enter Email Address"
                v-model="email"
              />
            </label>
          </div>
          <button
            type="submit"
            class="bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
          >
            Save Changes
          </button>
        </div>
      </div>

      <!-- CHANGE PASSWORD -->
      <div
        class="lg:col-span-3 asis-1/2"
        :class="[activeTab == 'change-password' ? 'block ' : 'hidden']"
      >
        <form @submit.prevent="changePasswordForm">
          <div class="profile-sec">
            <h2 class="font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 mt-5">
              Change Password
            </h2>
            <div
              class="mt-6 gap-x-8 md:gap-y-10 gap-y-5 shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl relative full-width grid grid-cols-1 gap-x-25 xl:grid-cols-1"
            >
              <label class="block">
                <span
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Existing Password
                </span>
                <input
                  type="password"
                  name="old_password"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="old_password"
                  placeholder=""
                />
              </label>
              <label class="block">
                <span
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  New Password
                </span>
                <input
                  type="password"
                  name="new_password"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="new_password"
                  placeholder=""
                />
              </label>
              <label class="block">
                <span
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-3"
                >
                  Confirm Password
                </span>
                <input
                  type="password"
                  name="confirm_password"
                  class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="confirm_password"
                  placeholder=""
                />
                <p v-if="confirmPasswordError" class="error">
                  {{ confirmPasswordError }}
                </p>
              </label>
            </div>
            <button
              type="submit"
              class="bg-violet-500 py-3 px-12 mt-8 font-semibold font-poppins"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>

      <!-- PROFILE -->
      <div
        class="lg:col-span-3 asis-1/2 full-width"
        :class="[activeTab == 'subscription' ? 'block ' : 'hidden']"
      >
        <div class="profile-sec full-width">
          <h2 class="font-medium md:text-[1.8rem] text-[1.4rem] md:mt-0 my-5 uppercase">
            Subscriptions
          </h2>

          <UpgradeSubscriptionPlan
            :pendingSubscriptions="pendingSubscriptions"
            :processingSubscription="processingSubscription"
            @change-subscription="changeSubscription"
            @activate-subscription="activateSubscription"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import router from '@/router';
import { mapGetters, mapMutations } from 'vuex';
import axios from '@/axios-interceptor';
import { defineComponent, ref } from 'vue';

import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import UpgradeSubscriptionPlan from '@/components/Common/UpgradeSubscription.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import { PendingSubscriptionPlan } from '@/interfaces/subscription';

export default defineComponent({
  name: 'account-info',
  components: {
    DashboardHeader,
    UpgradeSubscriptionPlan,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const activeId = ref(1);
    const activeTab = ref('profile');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    return {
      activeId,
      center,
      markers,
      isOpenModal,
      activeTab,
      icon,
    };
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      gender: '',
      phone: '',
      email: '',
      date_of_birth: '',
      new_password: '',
      old_password: '',
      confirm_password: '',
      confirmPasswordError: '',
      profilePicture: '' as any,
      newProfilePicture: '' as any,
      updatingProfile: false,
      processingSubscription: false,
      pendingSubscriptions: [] as PendingSubscriptionPlan[],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  methods: {
    ...mapMutations(['setAuthenticated', 'setLoggedInUser']),
    handleLogout() {
      this.setLoggedInUser({});
      this.setAuthenticated();
      localStorage.clear();
      router.push({ path: '/' });
    },
    onFileChange() {
      const fileInput = this.$refs.profilePicture as any;
      const file = fileInput.files[0];
      this.newProfilePicture = URL.createObjectURL(file);
    },
    handleSetActive(id: number) {
      this.activeId = id;
    },
    handleSetActiveTab(name: string) {
      this.activeTab = name;
      router.push({ query: { tab: name } });
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    async submitForm() {
      this.updatingProfile = true;

      const fileInput = this.$refs.profilePicture as any;
      const file = fileInput.files[0];

      const formData = new FormData();

      formData.append('first_name', this.firstName);
      formData.append('last_name', this.lastName);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('gender', this.gender);

      if (this.date_of_birth) {
        formData.append('date_of_birth', this.date_of_birth);
      }

      if (file) {
        formData.append('profile_pic', file);
      }

      try {
        const response = await axios.patch('auth/users/me/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.updatingProfile = false;

        this.$toast.success('Profile updated successfully', {
          position: 'top-right',
        });

        this.setLoggedInUser(response.data);

        this.newProfilePicture = '';

        this.firstName = response.data.first_name;
        this.lastName = response.data.last_name;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.gender = response.data.gender;
        this.date_of_birth = response.data.date_of_birth;
        this.profilePicture = response.data.profile_pic;
      } catch (error) {
        this.updatingProfile = false;
        console.error(error);
        this.$toast.error('Could not update the profile', {
          position: 'top-right',
        });
      }
    },

    changePasswordForm() {
      if (this.new_password === this.confirm_password) {
        this.confirmPasswordError = '';
        this.changePassword();
      } else {
        this.confirmPasswordError = 'Passwords do not match';
      }
    },
    async changePassword() {
      const formData = {
        new_password: this.new_password,
        current_password: this.old_password,
      };
      try {
        const response = await axios.post('auth/users/set_password/', formData);
        if (response) {
          this.$toast.success('Password reset has been sent to your inbox.', {
            position: 'top-right',
          });
        }
      } catch (error: any) {
        this.$toast.error('Could not update password at this time');
      }
    },
    updateUserInfo() {
      this.firstName = this.loggedInUser.first_name;
      this.lastName = this.loggedInUser.last_name;
      this.email = this.loggedInUser.email;
      this.phone = this.loggedInUser.phone;
      this.gender = this.loggedInUser.gender;
      this.date_of_birth = this.loggedInUser.date_of_birth;
      this.profilePicture = this.loggedInUser.profile_pic;
    },
    fetchPendingSubscriptionPlans() {
      axios
        .get(`partner/subscription/sme-change/?sme=${this.loggedInUser.sme.id}`)
        .then((response: any) => {
          console.log('PENDING SUBSCRIPTIONS CHANGE: ', response.data);
          this.pendingSubscriptions = response.data;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    changeSubscription(subscription: number) {
      this.processingSubscription = true;
      const payload = {
        sme: this.loggedInUser.sme.id,
        subscription,
      };

      axios
        .post('partner/subscription/sme-change/', payload)
        .then(() => {
          this.$toast.success('Subscription change request successful', {
            position: 'top-right',
          });
          this.processingSubscription = false;
          this.fetchPendingSubscriptionPlans();
        })
        .catch(() => {
          this.processingSubscription = false;
          this.$toast.error('Subscription change request failed', {
            position: 'top-right',
          });
        });
    },
    activateSubscription(subscription: number) {
      this.processingSubscription = true;
      const payload = {
        sme: this.loggedInUser.sme.id,
        subscription,
      };

      axios
        .post('partner/subscription/sme-activate/', payload)
        .then(() => {
          this.$toast.success('Subscription activated successfully', {
            position: 'top-right',
          });
          this.processingSubscription = false;
          setTimeout(() => window.location.reload(), 3000);
        })
        .catch(() => {
          this.processingSubscription = false;
          this.$toast.error('Could not activate subscription', {
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.updateUserInfo();
    this.fetchPendingSubscriptionPlans();

    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.activeTab = tab;
    }
  },
});
</script>
