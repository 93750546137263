<template>
    <v-dialog max-width="600">
        <AudioPlayer v-if="file.type === 'audio'" :file="this.file"/>
        <VideoPlayer v-else-if="file.type === 'video'" :file="this.file" />
        <ImageViewer v-else-if="file.type === 'image'" :file="this.file"/>
        <DocumentViewer v-else :file="this.file" />
    </v-dialog>
</template>
<script>
import AudioPlayer from "./AudioPlayer.vue"
import VideoPlayer from "./VideoPlayer.vue"
import ImageViewer from "./ImageViewer.vue"
import DocumentViewer from "./DocumentViewer.vue";

export default{
    name: 'OpenFile',
    props: {
        file: Object,
    },
    components: {
        AudioPlayer,
        VideoPlayer, 
        ImageViewer,
        DocumentViewer,
    },
    data() {
        return {  
        };
    },
}
</script>