<template>
    <div class="grid grid-cols-3 gap-2">
      <div
        v-for="(file, index) in files"
        :key="index"
        class="bg-white-400 shadow-lg rounded-lg p-2 w-64 h-62 hover:bg-white cursor-pointer"
        @click="viewFolder(file, index)"
      >
        <!-- File Name -->
        <div class="text-gray-400 text-lg font-semibold text-lg ">
          {{ file.fileName }}
        </div>

        <!-- File Icon -->
          <!-- Replace 'your-file-icon.svg' with your own SVG file or use an inline SVG -->
          <img :src="file.thumbnail" alt="File Icon" class="w-64 h-40">

        <!-- Cartegory -->
        <!-- Recently Opened Text -->
        <div class="text-gray text-sm mt-2">
          {{ file.fileText }}
        </div>
      </div>
    </div>

</template>
<script>
export default{
  name: 'FileSuggestions',
  data(){
    return{
      // Recent doc data
      files: [
      {
        fileName: 'copyright-notice.pdf',
        fileText: 'Recently edited',
        type: 'pdf',
        thumbnail: 'https://acrobatusers.com/assets/uploads/actions/pdf_accessibility1_0.jpeg',
        url: 'https://onlinetestcase.com/wp-content/uploads/2023/06/1-MB.pdf',
      },
      {
        fileName: 'meeting-minutes.pdf',
        fileText: 'Recently shared',
        type: 'pdf',
        thumbnail: 'https://legaltemplates.net/wp-content/uploads/documents/terms-and-conditions/terms-of-use.png',
        url: 'https://onlinetestcase.com/wp-content/uploads/2023/06/1-MB.pdf',
      },
      {
        fileName: 'lens.jpg',
        fileText: 'Recently opened',
        type: 'image',
        thumbnail: 'https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2016/10/camera-photo-lens-stock-images.jpg',
        url: 'https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2016/10/camera-photo-lens-stock-images.jpg',
      },
      ],
    }
  },
  methods: {
    viewFolder(folder, index) {
      this.$emit("viewFolder", folder, index);
    }
  }
}
</script>