<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <!-- PAYMENT REQUEST SECTION -->
          <div v-if="activeSection === 'before-payment-request'">
            <h3
              class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
              SMS Payment
            </h3>

            <p class="text-primary text-sm mb-6">
              {{ smsPackage.sms_per_month }} SMS messages per month for {{ smsPackage.monthly_cost.toLocaleString() }}
              RWF

            </p>

            <form>
              <div class="mb-2">
                <label for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Payment
                  Method:</label>
                <select id="small-input"
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                  v-model="paymentMethod" @change="handleChange" required>
                  <option value="momo">MoMo</option>
                </select>
              </div>
              <div class="mb-2">
                <label for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Phone
                  Number:</label>
                <input placeholder="Enter phone number" v-model="phone" required type="text" id="small-input"
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
              </div>
              <div class="mb-2">
                <label for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Amount in
                  RWF:</label>
                <input :value="Number(smsBilling.amount)" type="number" min="100" id="small-input" readonly
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder" />
              </div>

              <div class="w-full flex lg:justify-end justify-start items-center mt-3">
                <button v-if="!loading" @click="processPayment()" type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                  PAY
                </button>
                <button v-else type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                  PROCESSING...
                </button>
              </div>
            </form>
          </div>


          <!-- AFTER PAYMENT REQUEST SECTION -->
          <div v-if="activeSection === 'after-payment-request'">
            <h3 class="text-primary text-center mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem]">
              PAYMENT REQUEST PROMPT SENT
            </h3>

            <div class="text-routersTextColor">

              <p class="mt-3 text-sm">

                We have sent a payment request to your MTN Mobile Money number: {{ this.phone }}.
              </p>

              <p class="mt-3 text-sm">


                1. Approve the payment request if you have sufficient balance.

              </p>

              <p class="mt-3 text-sm">


                2. If no prompt received, dial *182*7# to check for pending requests. Choose 1, enter
                your PIN, and choose 1 to approve the payment. If you don't find any, restart the process.

              </p>

              <p class="mt-3 text-sm">

                3. Approve within 10 minutes. If it expires, restart the process on the billing page.
              </p>


              <p class="mt-3 text-sm">

                4. After approval, refresh the page after 3 minutes to restore access.
              </p>


              <p class="mt-3 text-sm">

                You will receive email and SMS notifications about your payment status.

              </p>



            </div>

            <div class="flex justify-center">
              <button type="button" @click="$emit('close')"
                class="w-1/4 font-['poppins'] text-[15px] bg-routersTextColor text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center mt-6">
                NOTED
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import axios from '@/axios-interceptor';

import { SmsPackageBilling, SMSPackage } from '@/interfaces/billing';


export default defineComponent({
  name: 'SMSPaymentModal',
  props: {
    smsPackage: {
      type: SMSPackage,
    },
    smsBilling: {
      type: SmsPackageBilling
    },
  },
  data() {
    return {
      paymentMethod: 'momo',
      phone: '',
      loading: false,
      activeSection: 'before-payment-request'
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    isValidMTNPhoneNumber(phoneNumber) {
      // Regular expression pattern to match the required MTN Rwanda phone number formats
      const mtnPattern = /^2507[89]\d{7}$/;

      // Test the phone number against the pattern
      return mtnPattern.test(phoneNumber);

    },
    processPayment() {
      console.log("SMS BILLING: ", this.smsBilling);

      if (!this.phone) {
        return this.$toast.warning("Enter your momo phone number for payment", {
          position: 'top-right',
        });
      }

      if (!this.isValidMTNPhoneNumber(this.phone)) {
        return this.$toast.error("Invalid MTN Rwanda phone number. Please enter a valid 12-digit number starting with 25078 or 25079.", {
          position: 'top-right',
          duration: 10000,
        });
      }

      this.error = '';
      this.loading = true;
      let payload = {
        sms_billing: this.smsBilling.id,
        amount: Number(this.smsBilling.amount),
        phone_number: this.phone,
        request_type: "SMS BILLING"
      };

      axios
        .post('partner/billing/momo-payment-request/', payload)
        .then(() => {
          this.$toast.success('Payment request sent to your mobile money number', {
            position: 'top-right',
          });
          this.loading = false;
          this.activeSection = "after-payment-request"
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error('Could not send mobile money payment request to your phone number', {
            position: 'top-right',
          });

        });
    },
  },
});
</script>
