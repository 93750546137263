import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%] h-100vh md:overflow-auto lg:py-10" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = {
  key: 0,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_8 = {
  key: 1,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_9 = {
  key: 2,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_10 = {
  key: 3,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_11 = {
  key: 4,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_12 = {
  key: 5,
  class: "text-[#ff0000] mb-5"
}
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 7,
  type: "button",
  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
}
const _hoisted_15 = { class: "w-full flex justify-center items-center my-6" }
const _hoisted_16 = { class: "font-poppins font-normal text-[#838282] text-[12.9px]" }
const _hoisted_17 = { class: "lg:w-[50%]" }
const _hoisted_18 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!
  const _component_SmeSnackbar = _resolveComponent("SmeSnackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[15] || (_cache[15] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, " Sign Up ", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("h1", { class: "font-poppins lg:font-bold font-normal lg:text-[19px] text-[15px] mt-2 tracking-widest text-primary text-center" }, " ARED SME Platform ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                name: "smeName",
                placeholder: "Name",
                type: "text",
                modelValue: _ctx.v$.smeName.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.smeName.$model) = $event)),
                showErrors: _ctx.v$.smeName.$errors.length ? true : false,
                errors: _ctx.v$.smeName.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.responseErrors.name)
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.responseErrors.name[0]), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_TextField, {
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                name: "smeCity",
                placeholder: "City",
                type: "text",
                modelValue: _ctx.v$.city.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.city.$model) = $event)),
                showErrors: _ctx.v$.city.$errors.length ? true : false,
                errors: _ctx.v$.city.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.responseErrors.location)
                ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.responseErrors.location[0]), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_SelectField, {
                name: "country",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select Country",
                modelValue: _ctx.v$.country.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.country.$model) = $event)),
                showErrors: _ctx.v$.country.$errors.length ? true : false,
                errors: _ctx.v$.country.$errors,
                data: _ctx.countries
              }, null, 8, ["modelValue", "showErrors", "errors", "data"]),
              _createVNode(_component_TextField, {
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                name: "smeAdminName",
                placeholder: "Admin Name",
                type: "text",
                modelValue: _ctx.v$.adminName.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.adminName.$model) = $event)),
                showErrors: _ctx.v$.adminName.$errors.length ? true : false,
                errors: _ctx.v$.adminName.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.responseErrors.admin_name)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.responseErrors.admin_name[0]), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_TextField, {
                name: "smeEmail",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Admin Email",
                type: "email",
                modelValue: _ctx.v$.email.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
                showErrors: _ctx.v$.email.$errors.length ? true : false,
                errors: _ctx.v$.email.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.responseErrors.admin_email)
                ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.responseErrors.admin_email[0]), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_TextField, {
                name: "phone",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Phone Number",
                type: "text",
                modelValue: _ctx.v$.phone.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.phone.$model) = $event)),
                showErrors: _ctx.v$.phone.$errors.length ? true : false,
                errors: _ctx.v$.phone.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              (_ctx.responseErrors.admin_phone)
                ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.responseErrors.admin_phone[0]), 1))
                : _createCommentVNode("", true),
              (_ctx.responseErrors.admin_phone)
                ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.responseErrors.admin_phone[0]), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_SelectField, {
                name: "type",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select SME Type",
                modelValue: _ctx.v$.smeType.$model,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.smeType.$model) = $event)),
                showErrors: _ctx.v$.smeType.$errors.length ? true : false,
                errors: _ctx.v$.smeType.$errors,
                data: _ctx.smeTypes
              }, null, 8, ["modelValue", "showErrors", "errors", "data"]),
              _createVNode(_component_SelectField, {
                name: "partner",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select Partner",
                modelValue: _ctx.v$.partner.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.v$.partner.$model) = $event)),
                showErrors: _ctx.v$.partner.$errors.length ? true : false,
                errors: _ctx.v$.partner.$errors,
                data: _ctx.partners,
                onValueSelected: _ctx.onSelectedPartner
              }, null, 8, ["modelValue", "showErrors", "errors", "data", "onValueSelected"]),
              _createVNode(_component_SelectField, {
                name: "subscription",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select Subscription",
                modelValue: _ctx.v$.subscription.$model,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.v$.subscription.$model) = $event)),
                showErrors: _ctx.v$.subscription.$errors.length ? true : false,
                errors: _ctx.v$.subscription.$errors,
                data: _ctx.subscriptions
              }, null, 8, ["modelValue", "showErrors", "errors", "data"]),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-xs text-primary mb-6" }, " Subscription will be applied after 30 days of free trial ", -1)),
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 6,
                    type: "button",
                    class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium",
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleSignup && _ctx.handleSignup(...args))),
                    disabled: _ctx.v$.$invalid
                  }, " Register ", 8, _hoisted_13))
                : (_openBlock(), _createElementBlock("button", _hoisted_14, " Processing... ")),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, [
                  _cache[11] || (_cache[11] = _createTextVNode("Already have an account? ")),
                  _createElementVNode("button", {
                    type: "button",
                    class: "text-success font-semibold lg:text-[#4D99FF] text-[#4E8D6E]",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleSignIn && _ctx.handleSignIn(...args)))
                  }, " Sign In ")
                ])
              ])
            ])
          ])
        ]),
        _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"max-form w-full bottom-5 lg:flex hidden justify-between items-center\" data-v-1081aee9><ul class=\"flex\" data-v-1081aee9><li data-v-1081aee9><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-1081aee9><a href=\"javascript:void(0)\" data-v-1081aee9>Terms</a></span></li><li data-v-1081aee9><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-1081aee9><a href=\"javascript:void(0)\" data-v-1081aee9>Plans</a></span></li><li data-v-1081aee9><span class=\"text-success font-poppins font-medium text-[0.778rem]\" data-v-1081aee9><a href=\"javascript:void(0)\" data-v-1081aee9>Contact Us</a></span></li></ul></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_auth_right_image)
      ]),
      (_ctx.showSnackbar)
        ? (_openBlock(), _createBlock(_component_SmeSnackbar, {
            key: 0,
            message: "Successfully registered as sme, kindly use credentials sent to your email to login to your dashboard account.",
            onButtonClick: _ctx.handleCloseSnackbar
          }, null, 8, ["onButtonClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}