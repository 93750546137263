<template>
  <div class="popup-modal">
    <div class="popup-modal-content portal-model rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="bg-white">
        <div
          class="mx-auto mt-3 grid max-w-2xl grid-cols-1 items-top gap-x-8 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:p-5"
        >
          <div>
            <h3
              class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
            >
              Customize Captive Portal
            </h3>

            <dl class="grid grid-cols-1">
              <!-- NAME -->
              <label for="name" class="mb-2">Name</label>
              <div class="mb-2">
                <input
                  v-model="captivePortalData.name"
                  type="text"
                  min="5"
                  maxlength="15"
                  id="small-input"
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                />
              </div>

              <!-- PRIMARY MESSAGE -->
              <label for="primary-message" class="mb-2">Primary Message</label>
              <div class="mb-2">
                <input
                  v-model="captivePortalData.primary_message"
                  type="text"
                  min="5"
                  maxlength="30"
                  id="small-input"
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                />
              </div>

              <!-- BACKGROUND COLOR -->
              <label for="background-color" class="mb-2"
                >Background Color</label
              >
              <div class="mb-2">
                <color-picker
                  v-model:pureColor="pureColor"
                  v-model:gradientColor="gradientColor"
                  format="hex"
                />
              </div>

              <!-- LOGO -->
              <label for="primary-message" class="mb-2">Logo</label>
              <div class="mb-2 relative">
                <input
                  id="file"
                  ref="fileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                  @change="previewLogo"
                />
              </div>

              <div class="w-full mt-8">
                <button
                  type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] px-3 py-2 font-medium"
                  @click="updateCaptivePortal()"
                  v-if="!loadingSave && !loadingReset"
                >
                  Save
                </button>
                <button
                  v-if="loadingSave"
                  type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] px-3 py-2 font-medium"
                >
                  <clip-loader color="#fff"></clip-loader>
                </button>

                <button
                  type="button"
                  class="mt-4 w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] px-3 py-2 font-medium"
                  @click="resetCaptivePortal()"
                  v-if="!loadingReset && !loadingSave"
                >
                  Reset the Changes
                </button>
                <button
                  type="button"
                  class="mt-4 w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] px-3 py-2 font-medium"
                  @click="resetCaptivePortal()"
                  v-if="loadingReset"
                >
                  <clip-loader color="#fff"></clip-loader>
                </button>
              </div>
            </dl>
          </div>
          <div class="hidden lg:block">
            <!-- PREVIEW SECTION -->
            <div>
              <div class="flex items-center w-full justify-center mb-2">
                <div>
                  <h3
                    class="text-left mb-0 font-poppins font-semibold md:text-[15px] text-[0.90rem] text-routersTextColor"
                  >
                    Preview Portal
                  </h3>
                  <div class="flex justify-center items-center my-3">
                    <img
                      @click="changePreview($event, 'phone')"
                      src="phone-icon.png"
                      class="inline-block mr-2 p-1"
                      :style="{ background: preview.phone ? '#ccc' : '' }"
                      alt=""
                    />
                    <img
                      @click="changePreview($event, 'tab')"
                      src="tab-icon.png"
                      class="inline-block mr-2 p-1"
                      :style="{ background: preview.tab ? '#ccc' : '' }"
                      alt=""
                    />
                    <img
                      @click="changePreview($event, 'monitor')"
                      src="monitor-icon.png"
                      class="inline-block p-1"
                      :style="{ background: preview.desktop ? '#ccc' : '' }"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Phone -->
            <div class="flex justify-center items-center" v-if="preview.phone">
              <div
                class="p-1"
                style="
                  width: 228px;
                  height: 468px;
                  background: url('phone.png') no-repeat;
                "
              >
                <div class="overflow-y-hidden" style="max-height: 450px">
                  <div
                    class="mt-2 mx-auto"
                    :style="{
                      width: '212px',
                      height: '35px',
                      backgroundColor: pureColor,
                    }"
                  >
                    <div class="flex">
                      <div class="w-1/5">
                        <img src="menu-icon.png" class="p-2" alt="" />
                      </div>
                      <div class="w-3/5">
                        <p class="text-center text-white pt-1">Home</p>
                      </div>
                      <div class="w-1/5">
                        <img
                          src="globe-icon.png"
                          class="p-2 mx-auto block"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    src="wifi-transparent.png"
                    class="mx-auto mt-3"
                    :style="{ backgroundColor: pureColor }"
                    alt=""
                  />
                  <img
                    v-if="previewLogoURL"
                    :src="previewLogoURL"
                    alt="Logo"
                    class="mt-3 mx-auto h-auto w-auto"
                    style="max-height: 140px"
                  />
                  <p class="px-1 mt-1 text-lg text-dark text-center">
                    {{ captivePortalData.primary_message }}
                  </p>
                  <h3 class="px-2 text-sm text-dark text-center">
                    You need to connect to
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                    to get internet.
                  </h3>
                  <button
                    type="button"
                    class="w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2"
                    :style="{ backgroundColor: pureColor }"
                  >
                    CONNECT TO
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Tablet -->
            <div class="flex justify-center items-center" v-if="preview.tab">
              <div
                class="p-1"
                style="
                  width: 300px;
                  height: 460px;
                  background: url('tab.png') no-repeat;
                "
              >
                <div class="overflow-y-hidden" style="max-height: 450px">
                  <div
                    class="mx-auto"
                    :style="{
                      width: '278px',
                      height: '35px',
                      marginTop: '7px',
                      backgroundColor: pureColor,
                    }"
                  >
                    <div class="flex">
                      <div class="w-1/5">
                        <img src="menu-icon.png" class="p-2" alt="" />
                      </div>
                      <div class="w-3/5">
                        <p class="text-center text-white pt-1">Home</p>
                      </div>
                      <div class="w-1/5">
                        <img
                          src="globe-icon.png"
                          class="p-2 mx-auto block"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    src="wifi-transparent.png"
                    class="mx-auto mt-3"
                    :style="{ backgroundColor: pureColor }"
                    alt=""
                  />
                  <img
                    v-if="previewLogoURL"
                    :src="previewLogoURL"
                    alt="Logo"
                    class="mt-3 mx-auto h-auto w-auto"
                    style="max-height: 140px"
                  />
                  <p class="px-1 mt-1 text-lg text-dark text-center">
                    {{ captivePortalData.primary_message }}
                  </p>
                  <h3 class="px-2 text-sm text-dark text-center">
                    You need to connect to
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                    to get internet.
                  </h3>
                  <button
                    type="button"
                    class="w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2"
                    :style="{ backgroundColor: pureColor }"
                  >
                    CONNECT TO
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                  </button>
                </div>
              </div>
            </div>

            <!-- large monitor -->
            <div
              class="flex justify-center items-center"
              v-if="preview.desktop"
            >
              <div
                class="p-1"
                style="
                  width: 350px;
                  height: 460px;
                  background: url('monitor.png') no-repeat;
                "
              >
                <div class="overflow-y-hidden" style="max-height: 326px">
                  <div
                    class="mx-auto"
                    :style="{
                      width: '324px',
                      marginTop: '17px',
                      backgroundColor: '#ffffff',
                    }"
                  >
                    <div class="flex">
                      <div class="w-1/5">
                        <div
                          :style="{
                            height: '100vh',
                            paddingTop: '14vh',
                            backgroundColor: pureColor,
                          }"
                        >
                          <h1 class="text-white text-xs text-center">
                            {{
                              captivePortalData.name
                                ? captivePortalData.name
                                : 'ARED'
                            }}
                          </h1>
                          <ul
                            class="nav-menu text-white mt-4 p-1"
                            style="font-size: 5px"
                          >
                            <li>
                              <a
                                data-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  style="
                                    fill: rgba(255, 255, 255, 1);
                                    display: inline-block;
                                  "
                                >
                                  <path
                                    d="M11 18.791V20H9v2h6v-2h-2v-1.845a9.934 9.934 0 0 0 3.071-2.084c3.898-3.898 3.898-10.243 0-14.143l-1.414 1.414c3.119 3.12 3.119 8.195 0 11.314-3.119 3.118-8.195 3.12-11.314 0L1.929 16.07A9.971 9.971 0 0 0 9 18.994a9.98 9.98 0 0 0 2-.203z"
                                  ></path>
                                  <path
                                    d="M3 9c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6zm10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4z"
                                  ></path>
                                </svg>
                                Offline Hub
                              </a>
                            </li>
                            <li>
                              <ul class="pl-3 m-0">
                                <li>
                                  <a>Audio</a>
                                </li>
                                <li>
                                  <a>Video</a>
                                </li>
                                <li>
                                  <a>Nature</a>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <a>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  style="
                                    fill: rgba(255, 255, 255, 1);
                                    display: inline-block;
                                  "
                                >
                                  <path
                                    d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z"
                                  ></path>
                                </svg>
                                Get Shiriki WiFi App
                              </a>
                            </li>
                            <li>
                              <a>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  style="
                                    fill: rgba(255, 255, 255, 1);
                                    display: inline-block;
                                  "
                                >
                                  <path
                                    d="M7 11h7v2H7zm0-4h10.97v2H7zm0 8h13v2H7zM4 4h2v16H4z"
                                  ></path>
                                </svg>
                                Surveys
                              </a>
                            </li>
                            <li>
                              <a>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 512 512"
                                  style="
                                    fill: rgba(255, 255, 255, 1);
                                    display: inline-block;
                                  "
                                >
                                  <path
                                    d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z"
                                  ></path>
                                </svg>
                                &nbsp; Feedback
                              </a>
                            </li>
                            <li>
                              <hr class="my-2" />
                            </li>
                            <li>
                              <a>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  style="
                                    fill: rgba(255, 255, 255, 1);
                                    display: inline-block;
                                  "
                                >
                                  <path
                                    d="M12 6c3.537 0 6.837 1.353 9.293 3.809l1.414-1.414C19.874 5.561 16.071 4 12 4c-4.071.001-7.874 1.561-10.707 4.395l1.414 1.414C5.163 7.353 8.463 6 12 6zm5.671 8.307c-3.074-3.074-8.268-3.074-11.342 0l1.414 1.414c2.307-2.307 6.207-2.307 8.514 0l1.414-1.414z"
                                  ></path>
                                  <path
                                    d="M20.437 11.293c-4.572-4.574-12.301-4.574-16.873 0l1.414 1.414c3.807-3.807 10.238-3.807 14.045 0l1.414-1.414z"
                                  ></path>
                                  <circle cx="12" cy="18" r="2"></circle>
                                </svg>
                                Internet Access
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="w-4/5">
                        <div class="flex items-center justify-end p-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            :style="{ fill: pureColor }"
                            height="1em"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M177.8 63.2l10 17.4c2.8 4.8 4.2 10.3 4.2 15.9v41.4c0 3.9 1.6 7.7 4.3 10.4c6.2 6.2 16.5 5.7 22-1.2l13.6-17c4.7-5.9 12.9-7.7 19.6-4.3l15.2 7.6c3.4 1.7 7.2 2.6 11 2.6c6.5 0 12.8-2.6 17.4-7.2l3.9-3.9c2.9-2.9 7.3-3.6 11-1.8l29.2 14.6c7.8 3.9 12.6 11.8 12.6 20.5c0 10.5-7.1 19.6-17.3 22.2l-35.4 8.8c-7.4 1.8-15.1 1.5-22.4-.9l-32-10.7c-3.3-1.1-6.7-1.7-10.2-1.7c-7 0-13.8 2.3-19.4 6.5L176 212c-10.1 7.6-16 19.4-16 32v28c0 26.5 21.5 48 48 48h32c8.8 0 16 7.2 16 16v48c0 17.7 14.3 32 32 32c10.1 0 19.6-4.7 25.6-12.8l25.6-34.1c8.3-11.1 12.8-24.6 12.8-38.4V318.6c0-3.9 2.6-7.3 6.4-8.2l5.3-1.3c11.9-3 20.3-13.7 20.3-26c0-7.1-2.8-13.9-7.8-18.9l-33.5-33.5c-3.7-3.7-3.7-9.7 0-13.4c5.7-5.7 14.1-7.7 21.8-5.1l14.1 4.7c12.3 4.1 25.7-1.5 31.5-13c3.5-7 11.2-10.8 18.9-9.2l27.4 5.5C432 112.4 351.5 48 256 48c-27.7 0-54 5.4-78.2 15.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                            ></path>
                          </svg>
                        </div>
                        <img
                          src="wifi-transparent.png"
                          class="mx-auto mt-3"
                          :style="{
                            width: 'auto',
                            height: '30px',
                            backgroundColor: pureColor,
                          }"
                          alt=""
                        />
                        <img
                          v-if="previewLogoURL"
                          :src="previewLogoURL"
                          alt="Logo"
                          class="mt-3 mx-auto h-auto w-auto"
                          style="max-height: 60px"
                        />
                        <p class="px-1 mt-1 text-lg text-dark text-center">
                          {{ captivePortalData.primary_message }}
                        </p>
                        <h3 class="px-2 text-sm text-dark text-center">
                          You need to connect to
                          {{
                            captivePortalData.name
                              ? captivePortalData.name
                              : 'ARED'
                          }}
                          to get internet.
                        </h3>
                        <button
                          type="button"
                          class="w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2"
                          :style="{ backgroundColor: pureColor }"
                        >
                          CONNECT TO
                          {{
                            captivePortalData.name
                              ? captivePortalData.name
                              : 'ARED'
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <img
                    src="wifi-transparent.png"
                    class="mx-auto mt-3"
                    :style="{ backgroundColor: pureColor }"
                    alt=""
                  />
                  <img
                    v-if="previewLogoURL"
                    :src="previewLogoURL"
                    alt="Logo"
                    class="mt-3 mx-auto h-auto w-40"
                    style="max-height: 140px"
                  />
                  <p class="px-1 mt-1 text-lg text-dark text-center">
                    {{ captivePortalData.primary_message }}
                  </p>
                  <h3 class="px-2 text-sm text-dark text-center">
                    You need to connect to
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                    to get internet.
                  </h3>
                  <button
                    type="button"
                    class="w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2"
                    :style="{ backgroundColor: pureColor }"
                  >
                    CONNECT TO
                    {{
                      captivePortalData.name ? captivePortalData.name : 'ARED'
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

import { CaptivePortal } from '../../interfaces/captive-portal';

export default defineComponent({
  name: 'CustomizeCaptivePortalModal',
  props: {
    modal: {
      type: Boolean,
    },
    captivePortal: {
      type: Object as () => CaptivePortal,
      required: true,
    },
    loadingSave: {
      type: Boolean,
      required: true,
    },
    loadingReset: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ClipLoader,
  },
  setup() {
    const pureColor = ref<string>('#7ea00e');
    const gradientColor = ref(
      'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)'
    );

    return { pureColor, gradientColor };
  },
  data() {
    return {
      activeDevice: 'desktop',
      captivePortalData: {} as CaptivePortal,
      previewLogoURL: '' as string,
      preview: { phone: true, tab: false, desktop: false },
    };
  },
  mounted() {
    this.captivePortalData = this.captivePortal;
    const logo = this.captivePortalData.logo;
    const baseURL = 'http://localhost:8000';

    if (logo && !logo.startsWith('http')) {
      this.previewLogoURL = `${baseURL}${logo}`;
    }

    if (logo && logo.startsWith('http')) {
      this.previewLogoURL = logo;
    }

    if (this.captivePortal.background_color) {
      this.pureColor = this.captivePortal.background_color;
    }
  },

  methods: {
    isActive(device: string) {
      return device === this.activeDevice;
    },
    toggleActive(device: string) {
      this.activeDevice = this.activeDevice === device ? '' : device;
    },
    previewLogo(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
          this.previewLogoURL = e.target?.result as string;
        };

        reader.readAsDataURL(file);
      } else {
        this.previewLogoURL = '';
      }
    },
    updateCaptivePortal() {
      const fileInput = this.$refs.fileInput as any;
      const file = fileInput.files[0];

      let payload = {
        name: this.captivePortalData.name,
        background_color: this.pureColor,
        primary_message: this.captivePortalData.primary_message,
        logo: file,
      };

      this.$emit('update-captive-portal', payload);
    },
    resetCaptivePortal() {
      this.$emit('reset-captive-portal');
    },
    changePreview(e: Event, device: string) {
      switch (device) {
        case 'phone':
          this.preview = { phone: true, tab: false, desktop: false };
          break;
        case 'tab':
          this.preview = { phone: false, tab: true, desktop: false };
          break;
        case 'monitor':
          this.preview = { phone: false, tab: false, desktop: true };
          break;
      }
    },
  },
});
</script>
  