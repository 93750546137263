<template>
  <DashboardHeader title="All Media" description="" :icon="icon.fileIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span
          class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[1.099rem] h-[1.062rem] rounded ml-1"
        />
      </div>

      <div v-if="loggedInUser.sme?.is_active">
        <button
          type="button"
          class="bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center"
          @click="showModal = true"
          v-if="
            shouldDisplayButton()
          "
        >
          <span
            class="font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex"
          >
            <img
              src="../../assets/plus-icon.png"
              class="md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2"
              alt=""
            />
            Add Media</span
          >
        </button>
      </div>

      <upload-media v-if="showModal" @close="closeModal()" :files="files" />
    </template>
  </DashboardHeader>

  <suspended-page-message
    v-if="!loggedInUser.sme?.is_active"
  ></suspended-page-message>

  <div v-else class="w-full my-3">
    <div v-if="loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago">
      <payment-required-message />
    </div>

    <div
      v-else
      class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto"
    >
      <div class="tabs-block" id="tabs-block">
        <div class="mb-3">
          <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li class="mr-2">
              <button
                class="inline-block p-4 py-2 rounded-md w-full"
                id="profile-tab"
                data-tabs-target="#offline-files"
                type="button"
                role="tab"
                aria-controls="offline-files"
                aria-selected="false"
                v-on:click="handleSetActiveTab('offline-files')"
                v-bind:class="[
                  activeTab === 'offline-files'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]"
              >
                Offline Files
              </button>
            </li>
            <li class="mr-2">
              <button
                class="inline-block p-4 py-2 rounded-md w-full"
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                v-on:click="handleSetActiveTab('realm-categories')"
                v-bind:class="[
                  activeTab === 'realm-categories'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]"
              >
                Realm Categories
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div id="myTabContent">
        <DashboardTableTitle
          v-if="propLoaded"
          :perPageItems="perSize"
          :pageNumber="page"
          :ApiData="files"
          @handleNext="handlleNext"
          @handlePrev="handlePrev"
        >
          <template v-slot:section>
            <div class="md:w-[30%] w-[50%]">
              <label class="relative block">
                <span class="sr-only">Search</span>
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <img
                    src="../../assets/search-icon.png"
                    alt=""
                    class="w-[1rem] h-[1rem]"
                  />
                </span>
                <input
                  v-model="searchQuery"
                  class="font-normal rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Search media"
                  type="text"
                  name="search"
                />
              </label>
            </div>
          </template>
        </DashboardTableTitle>

        <SmeTable
          :tableHeaderData="routerTableHeaders"
          v-if="activeTab !== 'realm-categories'"
        >
          <template v-slot:tableBodyData>
            <template v-if="paginatedTableData.length">
              <div
                v-for="item in paginatedTableData"
                :key="item.id"
                class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
              >
                <td>
                  <div class="flex items-center">
                    <div class="flex flex-col ml-3">
                      <span
                        class="b-avatar-img capitalize text-[#464E5F] mb-0.5"
                        ><img
                          :src="item?.thumbnail"
                          class="rounded-full inline-block h-10 w-10"
                      /></span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item?.sme?.name
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.realm_name
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.category
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.file_type
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      createdAt(item.created)
                    }}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span
                      @click="downloadFile(item.file)"
                      class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                      >Download<i class="ml-1.5 fa-solid fa-download"></i
                    ></span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <i
                      v-if="!deleting || currentDeletionFileID !== item.id"
                      @click="deleteMediaFile(item.id)"
                      class="fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                    ></i>
                    <ScaleLoader
                      v-if="deleting && currentDeletionFileID === item.id"
                      color="#d7191c"
                    ></ScaleLoader>
                  </div>
                </td>
              </div>
            </template>
            <div
              v-else
              class="w-full text-center text-[12px] text-[#afafaf] mt-4"
            >
              <b>No Record found</b>
            </div>
          </template>
        </SmeTable>

        <!-- REALM CATEGORIES TABLE -->

        <SmeTable
          v-if="activeTab === 'realm-categories'"
          :tableHeaderData="realmCategoriesTableHeader"
        >
          <template v-slot:tableBodyData>
            <span
              v-if="!categories.length"
              class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
            >
              No records
            </span>
            <div
              v-else
              v-for="category in categories"
              :key="category.id"
              class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
            >
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    category.linked_realm.name
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    category.name
                  }}</span>
                </div>
              </td>

              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    createdAt(category.created)
                  }}</span>
                </div>
              </td>

              <td>
                <div class="flex flex-col">
                  <i
                    v-if="
                      !deletingCategory ||
                      currentDeletionCategoryID !== category.id
                    "
                    @click="deleteCategory(category.id)"
                    class="fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                  ></i>
                  <ScaleLoader
                    v-if="
                      deletingCategory &&
                      currentDeletionCategoryID === category.id
                    "
                    color="#d7191c"
                  ></ScaleLoader>
                </div>
              </td>
            </div>
          </template>
        </SmeTable>

        <div class="spinner-container">
          <div v-if="isLoading" class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

import instance from '@/axios-interceptor';
import UploadMedia from '../../components/Content/UploadMedia.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import {
  fileMedia as _fileMedia,
  fileMediaTableHeaders as _fileMediaTableHeaders,
  fileMediaTableIcons as _fileMediaTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import { Category } from '@/interfaces/router';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    DashboardHeader,
    ScaleLoader,
    UploadMedia,
  },
  setup() {
    const routers = ref([..._fileMedia]);
    const icon = ref({ ...dashboardHeadericon });
    const routerTableIcons = ref([..._fileMediaTableIcons]);
    const routerTableHeaders = ref([..._fileMediaTableHeaders]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const deleting = ref(false);
    const isOpenModal = ref(false);
    const currentDeletionFileID = ref(0);

    const realmCategoriesTableHeader = ref([
      'Realm',
      'Category',
      'Created',
      'Delete',
    ]);

    return {
      icon,
      center,
      routers,
      markers,
      deleting,
      isOpenModal,
      routerTableIcons,
      routerTableHeaders,
      currentDeletionFileID,
      realmCategoriesTableHeader,
    };
  },

  data() {
    return {
      isLoading: false,
      showModal: false,
      searchQuery: '',
      propLoaded: false,
      page: 1,
      perSize: 10,
      files: [
        {
          id: '',
          file_type: '',
          realm: '',
          realm_name: '',
          file: '',
          status: '',
          icon: '',
        },
      ],
      activeTab: 'offline-files',
      categories: [] as Category[],
      deletingCategory: false,
      currentDeletionCategoryID: 0,
    };
  },
  methods: {
    handlePrev(data: any) {
      this.page--;
      this.fetchFiles();
    },
    handlleNext(data: any) {
      this.page++;
      this.fetchFiles();
    },
    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      if (tab === 'offline-files') {
        this.fetchFiles();
      } else {
        this.fetchCategories();
      }
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    fetchFiles() {
      if (!this.categories.length) {
        this.isLoading = true;
      }

      instance
        .get(`files/?realm__sme=${this.loggedInUser.sme.id}&status=Enabled`)
        .then((response) => {
          this.files = response.data;
          this.propLoaded = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    closeModal() {
      this.showModal = false;
      this.fetchFiles();
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    downloadFile(file: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    async deleteMediaFile(fileID: number) {
      try {
        this.currentDeletionFileID = fileID;
        this.deleting = true;
        await instance.delete(`files/${fileID}/`).then(() => {
          this.$toast.success('Media has been deleted successfully', {
            position: 'top-right',
          });
          this.deleting = false;
          this.currentDeletionFileID = 0;
          this.fetchFiles();
        });
      } catch (e: any) {
        this.deleting = false;
        this.currentDeletionFileID = 0;
        if (e.response.data.detail) {
          this.$toast.error(e.response.data.detail, {
            position: 'top-right',
          });
        } else {
          this.$toast.error('Could not delete the media', {
            position: 'top-right',
          });
        }
      }
    },
    async deleteCategory(categoryId: number) {
      try {
        this.currentDeletionCategoryID = categoryId;
        this.deletingCategory = true;
        await instance.delete(`categories/${categoryId}/`).then(() => {
          this.$toast.success(
            'Category and its associated offline files deleted',
            {
              position: 'top-right',
            }
          );
          this.deletingCategory = false;
          this.currentDeletionCategoryID = 0;
          this.handleSetActiveTab(this.activeTab);
        });
      } catch (e: any) {
        this.deletingCategory = false;
        this.currentDeletionCategoryID = 0;
        if (e.response.data.detail) {
          this.$toast.error(e.response.data.detail, {
            position: 'top-right',
          });
        } else {
          this.$toast.error('Could not delete category at this time', {
            position: 'top-right',
          });
        }
      }
    },
    fetchCategories() {
      if (!this.categories.length) {
        this.isLoading = true;
      }

      instance
        .get(`categories/?realm__sme=${this.loggedInUser.sme.id}`)
        .then((response) => {
          this.isLoading = false;
          this.categories = response.data;
          console.log('CATEGORIES: ', this.categories);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    shouldDisplayButton() {
      const sme = this.loggedInUser.sme;

      // Check if there is an active subscription plan
      const hasActiveSubscription = sme.subscription_plan !== null && sme.subscription_plan !== undefined;

      // Check if the SME is in the trial period
      const isInTrialPeriod = sme.is_in_trial_period === true;

      // Check if the SME has not paid for one billing five days ago
      const hasNotPaidForOneBillingFiveDaysAgo = sme.has_not_paid_for_one_billing_five_days_ago !== true;

      // Evaluate the final condition
      return hasActiveSubscription && (isInTrialPeriod || hasNotPaidForOneBillingFiveDaysAgo);
    },
  },
  mounted() {
    this.fetchFiles();
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.files;
      }

      // Filter table data based on search query
      const filteredData = this.files.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const file_type = item.file_type.toString().toLowerCase();
        const realm = item.realm.toString().toLowerCase();
        const realm_name = item.realm_name.toString().toLowerCase();
        const status = item.status.toString().toLowerCase();
        const query = this.searchQuery.toLowerCase();

        // Check if name or age contain the search query
        return (
          file_type.includes(query) ||
          realm.includes(query) ||
          realm_name.includes(query) ||
          status.includes(query)
        );
      });
      return filteredData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.filteredTableData.slice(startIndex, endIndex);
    },
  },
});
</script>
