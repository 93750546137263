<template>
  <div class="flex justify-center items-center bg-gray-100">
    <div class="max-w-lg p-6 bg-white rounded-lg shadow">
      <h2 class="text-2xl font-bold mb-4">Activate Subscription</h2>
      <p class="text-gray-600 mb-4">
        You have no active subscription plan. To access the page, you need to activate a subscription plan.
      </p>
      <button type="button"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
        @click="redirectToSettings">
        Go to Settings
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import router from '@/router';

export default defineComponent({
  name: 'ActivateSubscriptionMessage',
  methods: {
    redirectToSettings() {
      router.push({ path: '/account-info', query: { tab: 'subscription' } });
    },
  },
});
</script>

<style></style>
