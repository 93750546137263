<template>
  <DashboardHeader title="Billing" description="" :icon="icon.Billing">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>

  <suspended-page-message v-if="!loggedInUser.sme?.is_active"></suspended-page-message>

  <div v-else class="w-full my-3">
    <div class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
      <div class="tabs-block" id="tabs-block">
        <div>
          <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
            role="tablist">
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="microfranchisee"
                data-tabs-target="#subscription" type="button" role="tab" aria-controls="subscription"
                aria-selected="false" v-on:click="handleSetActiveTab('subscription')" v-bind:class="[
                  activeTab === 'subscription'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Subscription Plan + Add-Ons
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="routers" data-tabs-target="#sms" type="button"
                role="tab" aria-controls="routers" aria-selected="false" v-on:click="handleSetActiveTab('sms')"
                v-bind:class="[
                  activeTab === 'sms'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                SMS Package
              </button>
            </li>
          </ul>
        </div>
        <div id="TabContent">
          <div class="w-full md:flex my-6 justify-between">
            <!-- STARTER SUBSCRIPTION -->
            <div v-if="activeTab == 'subscription'"
              class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto">
              <DashboardTableTitle :perPageItems="perSize" :pageNumber="page" :ApiData="[]" @handleNext="() => { }"
                @handlePrev="() => { }">
                <template v-slot:section>
                  <div>
                    <div class="mt-5 flex items-center">
                      <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                        class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                        <select>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select></span>
                    </div>
                  </div>
                </template>
              </DashboardTableTitle>

              <SmeTable :tableHeaderData="subscriptionBillingTableHeaders">
                <template v-slot:tableBodyData>
                  <template v-if="subscriptionBillings.length">
                    <div v-for="billing in subscriptionBillings" :key="billing.id"
                      class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between font-poppins text-[0.673rem]">
                      <td>
                        <div class="flex flex-col">
                          <span class="mb-0.5 text-primary font-bold" v-if="billing.is_paid">{{
                            'Yes, you paid for this billing'
                          }}</span>
                          <span class="mb-0.5 text-[#f24236] font-bold" v-if="!billing.is_paid">{{
                            'No, you have not paid for this billing'
                          }}</span>

                          <span class="text-[#464E5F] text-xs mt-2 font-normal" v-if="!billing.is_paid && billing.due_date <= today
                          ">
                            <p>Please pay for this billing</p>
                          </span>
                        </div>
                      </td>
                      <td>
                        <span class="capitalize text-[#464E5F] mb-0.5"><strong>{{
                          moment(billing.due_date).format('D-MMM-YYYY')
                            }}</strong></span>
                      </td>
                      <td v-if="!billing.is_paid">
                        <strong>{{ billingStartDate }}</strong> to <strong>{{ billingEndDate }}</strong>
                      </td>
                      <td v-if="billing.is_paid">
                        <strong>{{ moment(billing.start_date).format('D-MMM-YYYY') }}</strong> to <strong>{{
                          moment(billing.end_date).format('D-MMM-YYYY') }}</strong>
                      </td>
                      <td>
                        <span v-if="!billing.is_paid" class="text-[#464E5F]">{{
                          Number(billing.final_amount).toLocaleString() }} RWF</span>
                        <span v-else-if="billing.is_paid" class="text-[#464E5F]">{{
                          Number(billing.final_charged_amount).toLocaleString() }} RWF</span>
                        <span v-else class="text-[#464E5F]">{{
                          Number(billing.amount).toLocaleString() }} RWF</span>
                      </td>

                      <td>
                        <div class="flex flex-col">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            billing.subscription_plan.name
                          }}</span>
                        </div>
                      </td>

                      <td>
                        <div class="flex flex-col">
                          <span v-if="billing.billed_addons?.length" class="capitalize text-[#464E5F] mb-0.5">{{
                            billing.billed_addons.map(billedAddon => billedAddon.addon.name).join(', ') }}
                          </span>
                          <span v-else>
                            None
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="flex flex-col">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            billing.sme.partner.name
                          }}</span>
                        </div>
                      </td>

                      <td>
                        <div class="flex flex-col">
                          <span class="capitalize text-[#2274a5] mb-0.5">
                            <a :href="billing.billing_invoices[0]?.invoice_ninja_link
                              " target="_blank">View Invoice</a>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div v-if="!billing.is_paid" @click="initiatePayment(billing)">
                          <span
                            class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer">Pay</span>
                        </div>
                        <div v-else @click="viewBillingDetails(billing)">
                          <span
                            class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer">View</span>
                        </div>
                      </td>
                    </div>
                  </template>
                  <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                    <b>No Starter Subscription Billings found</b>
                  </div>
                </template>
              </SmeTable>
            </div>


            <!-- SMS BILLINGS -->
            <div v-if="activeTab == 'sms'"
              class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto">
              <DashboardTableTitle :perPageItems="perSize" :pageNumber="page" :ApiData="[]" @handleNext="() => { }"
                @handlePrev="() => { }">
                <template v-slot:section>
                  <div>
                    <div class="mt-5 flex items-center">
                      <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                        class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                        <select>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select></span>
                    </div>
                  </div>
                </template>
              </DashboardTableTitle>

              <SmeTable :tableHeaderData="smsBillingTableHeaders">
                <template v-slot:tableBodyData>
                  <template v-if="smsBillings.length">
                    <div v-for="billing in smsBillings" :key="billing.id"
                      class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">

                      <td>
                        <div class="flex flex-col">
                          <span class="mb-0.5 text-primary font-bold" v-if="billing.is_paid">{{
                            'Yes, you paid for this billing'
                          }}</span>
                          <span class="mb-0.5 text-[#f24236] font-bold" v-if="!billing.is_paid">{{
                            'No, you have not paid for this billing'
                          }}</span>

                          <span class="text-[#464E5F] text-xs mt-2 font-normal" v-if="!billing.is_paid && billing.due_date <= today
                          ">
                            <p>Please pay for this billing</p>
                          </span>
                        </div>
                      </td>

                      <td>
                        <span class="capitalize text-[#464E5F] mb-0.5">{{
                          moment(billing.due_date).format('D-MMM-YYYY')
                        }}</span>
                      </td>


                      <td v-if="!billing.is_paid" class="capitalize text-[#464E5F] mb-0.5">
                        {{ billingStartDate }} to {{ billingEndDate }}
                      </td>
                      <td v-else class="capitalize text-[#464E5F] mb-0.5">
                        {{ moment(billing.start_date).format('D-MMM-YYYY') }} to {{
                          moment(billing.end_date).format('D-MMM-YYYY') }}
                      </td>


                      <td>
                        <div class="flex flex-col">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            billing.sms_package.name.split('_')[0]
                          }}
                            SMS</span>
                        </div>
                      </td>



                      <td>
                        <span class="text-[#464E5F]">{{ billing.amount }} RWF</span>
                      </td>


                      <td>
                        <div class="flex flex-col">
                          <span class="capitalize text-[#2274a5] mb-0.5">
                            <a :href="billing.sms_package_billing_invoices[0]
                              ?.invoice_ninja_link
                              " target="_blank">View Invoice</a>
                          </span>
                        </div>
                      </td>

                      <td>
                        <div v-if="!billing.is_paid" @click="displaySMSPaymentModal(billing)">
                          <span
                            class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer">Pay</span>
                        </div>
                        <div v-else @click="viewBillingDetails(billing)">
                          <span
                            class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer">View</span>
                        </div>
                      </td>
                    </div>
                  </template>
                  <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
                    <b>No SMS Billings found</b>
                  </div>
                </template>
              </SmeTable>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <billing-payment-modal :billing="selectedBilling" :processing="processingPayment" v-if="showBillingPaymentModal"
    @close="showBillingPaymentModal = !showBillingPaymentModal"></billing-payment-modal>

  <sms-payment-modal :loading="processingSMSBillingPayment" :smsPackage="selectedSMSPackage"
    @pay-for-sms="payForSMSBilling" :smsBilling="selectedSMSBilling" v-if="showSMSPaymentModal"
    @close="showSMSPaymentModal = !showSMSPaymentModal"></sms-payment-modal>
</template>

<script lang="ts">
import moment from 'moment';
import router from '@/router';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import axios from '@/axios-interceptor';
import { Billing, SMSPackage, SmsPackageBilling } from '@/interfaces/billing';
import BillingPaymentModal from '@/components/Billing/Payment.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import DashboardTableTitle from '@/components/markup/DashboardTableTitle.vue';
import SmsPaymentModal from '@/components/AddOn/SMSPayment.vue';

import {
  support as _support,
  subscriptionBillingTableHeadings as _subscriptionBillingTableHeadings,
  smsBillingTableHeadings as _smsBillingTableHeadings,
  supportTableIcons as _supportTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'BillingPage',
  components: {
    DashboardHeader,
    SmeTable,
    DashboardTableTitle,
    BillingPaymentModal,
    SmsPaymentModal,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Subscription Billings');
    const routers = ref([..._support]);
    const subscriptionBillingTableHeaders = ref([
      ..._subscriptionBillingTableHeadings,
    ]);
    const smsBillingTableHeaders = ref([..._smsBillingTableHeadings]);
    const routerTableIcons = ref([..._supportTableIcons]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);

    const subscriptionBillings = ref([] as Billing[]);
    const smsBillings = ref([] as SmsPackageBilling[]);
    const page = ref(1);
    const perSize = ref(10);
    const activeTab = ref('subscription');
    const selectedBilling = ref({} as Billing);
    const showBillingPaymentModal = ref(false);
    const processingPayment = ref(false);
    const processingSMSBillingPayment = ref(false);
    const showSMSPaymentModal = ref(false);
    const selectedSMSPackage = ref({} as SMSPackage);
    const selectedSMSBilling = ref({} as SmsPackageBilling)
    const error = ref('');

    return {
      routers,
      subscriptionBillingTableHeaders,
      routerTableIcons,
      center,
      markers,
      tableName,
      icon,
      subscriptionBillings,
      page,
      perSize,
      activeTab,
      smsBillingTableHeaders,
      smsBillings,
      selectedBilling,
      showBillingPaymentModal,
      processingPayment,
      processingSMSBillingPayment,
      showSMSPaymentModal,
      selectedSMSPackage,
      selectedSMSBilling,
      error,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    billingStartDate() {
      const startDate = new Date();

      return moment(startDate).format('D-MMM-YYYY');
    },
    billingEndDate() {
      // Set the start date as the current date
      const startDate = new Date();

      // Calculate the end date by adding 29 days to the start date
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 29);

      return moment(endDate).format('D-MMM-YYYY');

    },
  },
  methods: {
    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    fetchSubscriptionBillings() {
      axios
        .get(`partner/billing?sme=${this.loggedInUser.sme.id}`)
        .then(({ data }: { data: Billing[] }) => {
          this.subscriptionBillings = data;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    fetchSMSPackageBillings() {
      axios
        .get(`partner/sms-package/billing/?sme=${this.loggedInUser.sme.id}`)
        .then(({ data }: { data: Billing[] }) => {
          this.smsBillings = data;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
    viewBillingDetails(billing: Billing | SmsPackageBilling) {
      console.log("READY TO VIEW BILLING DETAILS: ", billing);

    },
    initiatePayment(billing: Billing) {
      this.selectedBilling = billing;
      this.showBillingPaymentModal = true;
    },
    displaySMSPaymentModal(billing: SmsPackageBilling) {
      this.selectedSMSBilling = billing;
      this.selectedSMSPackage = billing.sms_package;
      this.showSMSPaymentModal = true;
    },
    moment: moment,
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.activeTab = tab;
    }

    this.fetchSubscriptionBillings();
    this.fetchSMSPackageBillings();
  },
});
</script>
