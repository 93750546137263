import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "table-responsive w-full mt-3" }
const _hoisted_2 = { class: "overflow-auto table w-full" }
const _hoisted_3 = { class: "bg-[#F3F6F9] rounded-[0.337rem] items-center text-[#B5B5C3] font-poppins font-semibold text-[0.673rem] table-layout-tr table-th" }
const _hoisted_4 = {
  key: 0,
  class: "text-[#464E5F] capitalize"
}
const _hoisted_5 = { class: "whitespace-nowrap" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 1,
  class: "text-[#464E5F] capitalize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.inputShow)
          ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
              _createElementVNode("th", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "mr-2 shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight relative top-1",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectAllInternal) = $event)),
                  onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectAllRows && _ctx.selectAllRows(...args))),
                  disabled: _ctx.deletingVouchers
                }, null, 40, _hoisted_6), [
                  [_vModelCheckbox, _ctx.selectAllInternal]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.tableName)
          ? (_openBlock(), _createElementBlock("td", _hoisted_7, _toDisplayString(_ctx.tableName), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaderData, (item) => {
          return (_openBlock(), _createElementBlock("td", { key: item }, _toDisplayString(item), 1))
        }), 128))
      ]),
      _renderSlot(_ctx.$slots, "tableBodyData")
    ])
  ]))
}