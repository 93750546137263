import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/user-img.svg'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium text-[0.8rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "lg:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16" }
const _hoisted_5 = { class: "lg:basis-2/6 relative overflow-x-auto pb-4 sm:mx-0 block sm:overflow-visible shadow-lg px-5 pt-5 pb-6 shadow-gray-900/5 bg-white rounded-2xl lg:mb-0 mb-6" }
const _hoisted_6 = { class: "text-center pt-2" }
const _hoisted_7 = { class: "font-bold font-lg" }
const _hoisted_8 = { class: "text-sm" }
const _hoisted_9 = { class: "edit-profile flex justify-center pt-3" }
const _hoisted_10 = { class: "user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width" }
const _hoisted_11 = { class: "user-profile-innr w-full" }
const _hoisted_12 = { class: "w-full table-fixed table-border" }
const _hoisted_13 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_14 = { class: "bg-light" }
const _hoisted_15 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_16 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_17 = { class: "bg-light" }
const _hoisted_18 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_19 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_20 = { class: "bg-light" }
const _hoisted_21 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_22 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }
const _hoisted_23 = { class: "bg-light" }
const _hoisted_24 = { class: "font-medium sm:text-[1rem] text-[0.8rem] whitespace-normal break-words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_header = _resolveComponent("dashboard-header")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_dashboard_header, {
      title: "Accoutn Info",
      icon: "fa-regular fa-user",
      iconType: "class"
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[3] || (_cache[3] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[0.8rem] h-[0.8rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto" }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "w-full h-full",
              alt: "user"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.first_name) + " " + _toDisplayString(_ctx.last_name), 1),
            _createElementVNode("span", _hoisted_8, "Role: " + _toDisplayString(_ctx.role), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editDetails && _ctx.editDetails(...args))),
              class: "bg-primary font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
            }, " Edit Profile "),
            (_ctx.status === 'active' && _ctx.loggedInUser.id !== Number(_ctx.userId))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.disableUser && _ctx.disableUser(...args))),
                  type: "button",
                  class: "bg-[#d7191c] font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
                }, " Suspend "))
              : _createCommentVNode("", true),
            (_ctx.status !== 'active' && _ctx.loggedInUser.id !== Number(_ctx.userId))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.activateUser && _ctx.activateUser(...args))),
                  type: "button",
                  class: "bg-primary font-['poppins'] text-[10px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-2 border"
                }, " Activate "))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("table", _hoisted_12, [
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Name ", -1)),
                  _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.first_name) + " " + _toDisplayString(_ctx.last_name), 1)
                ]),
                _createElementVNode("tr", _hoisted_14, [
                  _cache[7] || (_cache[7] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Email ", -1)),
                  _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.email), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Phone ", -1)),
                  _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.phone), 1)
                ]),
                _createElementVNode("tr", _hoisted_17, [
                  _cache[9] || (_cache[9] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Country ", -1)),
                  _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.country), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[10] || (_cache[10] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Role ", -1)),
                  _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.role), 1)
                ]),
                _createElementVNode("tr", _hoisted_20, [
                  _cache[11] || (_cache[11] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Type ", -1)),
                  _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.userType), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Status ", -1)),
                  _createElementVNode("td", _hoisted_22, [
                    _createElementVNode("span", {
                      class: _normalizeClass([[
                      _ctx.status === 'suspended'
                        ? 'text-white bg-[#d7191c]'
                        : 'text-[#4e8d6e] bg-[#DCE9E3]',
                    ], "font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"])
                    }, _toDisplayString(_ctx.status), 3)
                  ])
                ]),
                _createElementVNode("tr", _hoisted_23, [
                  _cache[13] || (_cache[13] = _createElementVNode("td", { class: "font-poppin sm:text-[1rem] text-[0.8rem] whitespace-normal break-words text-[#888889] p-3" }, " Date Joined ", -1)),
                  _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.date_joined), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}