import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["name"]
const _hoisted_4 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_5 = ["value"]
const _hoisted_6 = ["name"]
const _hoisted_7 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.name === 'subscription')
        ? (_openBlock(), _createElementBlock("select", {
            key: 0,
            name: _ctx.name,
            class: _normalizeClass(["w-full h-[2.688rem] border-[0.063rem] rounded-[0.625rem] px-3 py-2 placeholder-textFieldColor text-textFieldColor outline-none focus:outline-none", [
          _ctx.showErrors ? 'border-red' : 'border-textFieldBorder',
          _ctx.classes ? _ctx.classes : '',
        ]]),
            onInput: _cache[0] || (_cache[0] = ($event) => _ctx.handleEmitUpdateValue($event))
          }, [
            _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.label), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (opt) => {
              return (_openBlock(), _createElementBlock("option", {
                key: opt.id,
                value: opt.id
              }, _toDisplayString(opt.name) + " - $" + _toDisplayString(opt.price), 9, _hoisted_5))
            }), 128))
          ], 42, _hoisted_3))
        : (_openBlock(), _createElementBlock("select", {
            key: 1,
            name: _ctx.name,
            class: _normalizeClass(["w-full h-[2.688rem] border-[0.063rem] rounded-[0.625rem] px-3 py-2 placeholder-textFieldColor text-textFieldColor outline-none focus:outline-none", [
          _ctx.showErrors ? 'border-red' : 'border-textFieldBorder',
          _ctx.classes ? _ctx.classes : '',
        ]]),
            onInput: _cache[1] || (_cache[1] = ($event) => _ctx.handleEmitUpdateValue($event))
          }, [
            _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.label), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (opt) => {
              return (_openBlock(), _createElementBlock("option", {
                key: opt.id,
                value: opt.id
              }, _toDisplayString(opt.name), 9, _hoisted_8))
            }), 128))
          ], 42, _hoisted_6))
    ]),
    (_ctx.showErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "text-red text-[12px]",
              key: index
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}