<template>
    <div class="popup-modal">
        <div class="popup-modal-content rounded-xl" style="width: 50%;overflow-x: scroll;">
            <div class="row" style="height: 80vh;">
                <div class="col-md-12 mx-auto text-routersTextColor">

                    <p class="text-xs mb-6">
                        Read and Accept the EULA to activate the Add-On
                    </p>

                    <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem">
                        ARED End User License Agreement (EULA)
                    </h3>

                    <p class="text-sm mb-6">
                        Effective Date: 9/23/2023
                    </p>

                    <div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">1. Introduction:</h4>
                            <p>
                                This End User License Agreement ("EULA") governs the use of software ("Software")
                                developed using the ARED SDK. By installing, copying, or otherwise using the Software,
                                you
                                agree to be bound by the terms of this EULA.
                            </p>
                        </div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">2. Grant of License:</h4>
                            <p>
                                2.1 Subject to your compliance with this EULA, ARED grants you a limited, non-exclusive,
                                non-
                                transferable license to use the Software on devices owned or controlled by you.
                            </p>
                            <p>
                                2.2 You may not distribute, sublicense, or make the Software available over a network
                                where
                                it
                                could be used by multiple devices simultaneously.
                            </p>
                        </div>


                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                3. Restrictions on Use:
                            </h4>

                            <p>
                                3.1 You may not reverse engineer, decompile, or disassemble the Software.
                            </p>


                            <p>
                                3.2 You may not remove, alter, or obscure any copyright, trademark, or other proprietary
                                rights
                                notice on or in the Software.
                            </p>

                            <p>
                                3.3 You may not modify, adapt, or create derivative works of the Software.
                            </p>

                        </div>


                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                4. Intellectual Property Rights:
                            </h4>

                            <p>
                                All rights, titles, and interests in and to the Software, including all intellectual
                                property rights
                                therein, are owned by ARED or its licensors. The Software is licensed, not sold, to you
                                under
                                this EULA.
                            </p>
                        </div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                5. Termination:
                            </h4>
                        </div>

                        <p>
                            5.1 This EULA is effective until terminated by you or ARED.
                        </p>

                        <p>
                            5.2 Your rights under this EULA will terminate automatically without notice from ARED if you
                            fail
                            to comply with any of its terms.
                        </p>

                        <p>
                            5.3 Upon termination, you shall cease all use of the Software and destroy all copies
                            thereof.
                        </p>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                6. Warranty Disclaimers:
                            </h4>

                            <p>
                                6.1 The Software is provided "as is" and "as available" without any warranties of any
                                kind,
                                either
                                express or implied.
                            </p>

                            <p>
                                6.2 ARED disclaims all warranties, express or implied, including but not limited to,
                                implied
                                warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            </p>
                        </div>



                        <div class="mb-6">
                            <h4 class="font-bold mb-2">
                                7. Limitation of Liability:
                            </h4>

                            <p>
                                In no event shall ARED be liable for any indirect, incidental, special, punitive, or
                                consequential
                                damages, or any loss of profits, revenue, data, or data use, arising out of or related
                                to
                                your use
                                of the Software, even if ARED has been advised of the possibility of such damages.
                            </p>
                        </div>


                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                8. Governing Law:
                            </h4>

                            <p>
                                This EULA is governed by and construed in accordance with the laws of [Insert
                                Jurisdiction],
                                without regard to its conflict of laws principles.
                            </p>
                        </div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                9. Entire Agreement:
                            </h4>

                            <p>
                                This EULA constitutes the entire agreement between you and ARED concerning the Software
                                and supersedes all prior or contemporaneous oral or written communications, proposals,
                                and
                                representations with respect to its subject matter.
                            </p>
                        </div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                10. Amendments:
                            </h4>

                            <p>
                                ARED reserves the right to modify this EULA at any time by providing such revised EULA
                                to
                                you
                                or posting the revised EULA on its website. Your continued use of the Software shall
                                constitute
                                your acceptance of such revised EULA.
                            </p>

                        </div>

                        <div class="mb-4">
                            <h4 class="font-bold mb-2">
                                11. Contact:
                            </h4>
                            <p>
                                For any questions regarding this EULA, please contact ARED at info@aredgroup.com.
                            </p>
                        </div>


                        <div v-if="!loading" class="w-full flex justify-center pb-8 mt-8">
                            <button @click="$emit('close')" type="button"
                                class="w-60 font-['poppins'] text-[15px] bg-white border border-textDark text-textDark rounded-[0.432rem] p-3 font-medium flex items-center justify-center mr-3">
                                Decline
                            </button>

                            <button @click="() => $emit('accept')" type="button"
                                class="w-60 font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                Accept
                            </button>
                        </div>

                        <div v-else class="w-full flex justify-center pb-8 mt-8">
                            <button disabled="true" type="button"
                                class="w-60 font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                                Processing...
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'EULA',
    props: {
        loading: Boolean,
    },
});
</script>