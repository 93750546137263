<template>
    <div>
      <table class="">
        <tr class=""   id="layoutColumnsRow">
          <td class=""><span class="text-sm">Layout Columns</span></td>
          <td class="">
            <input type="range" min="1" max="4" snap="1" ticksnum="4" decimals="0" class="" id="layoutColumnsSlider">
          </td>
          <td>
            <i class="fa-regular fa-circle-question" style="color: #1f5127;">
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                configures the number of columns used to lay out the camera frames
              </v-tooltip>
            </i>
          </td>
        </tr>
        <tr class="">
          <td class=""><span class="text-sm">Fit Frames Vertically</span></td>
          <td class="">
            <input type="checkbox" class="">
          </td>
          <td>
            <i class="fa-regular fa-circle-question" style="color: #1f5127;">
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                controls whether frame sizes can be reduced to vertically fit the window or not
              </v-tooltip>
            </i>
          </td>
        </tr>
        <tr class="" :min="1" :max="4" snap="1" ticksnum="4" decimals="0" id="layoutRows" depends="fitFramesVertically">
          <td class=""><span class="text-sm">Layout Rows</span></td>
          <td class="">
            <input type="range" class="" id="layoutRowsSlider">
          </td>
          <td>
            <i class="fa-regular fa-circle-question" style="color: #1f5127;">
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                configures the number of rows used to lay out the camera frames
              </v-tooltip>
            </i>
          </td>
        </tr>
        <tr class="" :min="0" :max="100" snap="2" ticksnum="6" decimals="0">
          <td class=""><span class="text-sm">Frame Rate Dimmer</span></td>
          <td class="">
            <input type="range" class="" id="framerateDimmerSlider">
          </td>
          <td>
            <i class="fa-regular fa-circle-question" style="color: #1f5127;">
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                dims the global frame rate to save network bandwidth and traffic (doesn't work on simple MJPEG cameras)
              </v-tooltip>
            </i>
          </td>
        </tr>
        <tr class="" :min="1" :max="100" snap="2" ticks="1|20|40|60|80|100" decimals="0">
          <td class=""><span class="text-sm">Resolution Dimmer</span></td>
          <td class="">
            <input type="range" class="" id="resolutionDimmerSlider">
          </td>
          <td>
            <i class="fa-regular fa-circle-question" style="color: #1f5127;">
              <v-tooltip
                activator="parent"
                location="bottom"
              >
              dims the actual resolution of all cameras to save network bandwidth and traffic (doesn't work on simple MJPEG cameras)dims the actual resolution of all cameras to save network bandwidth and traffic (doesn't work on simple MJPEG cameras)
              </v-tooltip>
            </i>
          </td>
        </tr>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'settingsPreferences',
    props: {
      // You can define any props that you need here
    },
  };
  </script>
  