<template>
    <div class="inline-flex justify-end space-x-4">
        <!-- Component for mini menu for directory data -->
        <v-menu >
            <template v-slot:activator="{ props }">
                <button v-bind="props" class="p-2 transparent relative">
                    <i class="fa-solid fa-ellipsis-vertical" style="color: #1f5137;"></i>
                    <v-tooltip
                    activator="parent"
                    location="bottom"
                    >Menu</v-tooltip>
                </button>
            </template>

            <v-list>
                <v-list-item>
                    <button class="block w-full text-left flex justify-between items-center">
                        <div>
                            <i class="fa-solid fa-maximize mr-2" style="color: #1f5137;"></i>Open with
                        </div>
                        <!-- nested menu -->
                        <v-menu open-on-hover>
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" class="p-2 transparent relative">
                                    <i class="fa-solid fa-caret-down fa-rotate-270" style="color: #1f5137;"></i>
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <i class="fa-solid fa-plus mr-2" style="color: #1f5137;"></i> Connect with more apps
                                </v-list-item>
                            </v-list>
                        </v-menu> 
                    </button>
                </v-list-item>
                <div class="border-t"></div>
                <v-list-item>
                        <button class="block w-full text-left" @click="favouriteItem(index)">
                            <i class="fa-solid fa-star mr-2" style="color: #1f5137;"></i>Favourite
                        </button>
                    </v-list-item>
                <v-list-item>
                    <button class="block w-full text-left">
                        <i class="fa-solid fa-download mr-2" style="color: #1f5137;"></i>Download
                    </button>
                </v-list-item>
                <v-list-item>
                    <button class="block w-full text-left" @click="openDialog(index)">
                        <i class="fa-solid fa-file-pen mr-2" style="color: #1f5137;"
                        @click="openDialog"
                        ></i>Rename
                    </button>
                </v-list-item>
                <div class="border-t"></div>
                <v-list-item>
                    <button class="block w-full text-left flex justify-between items-center">
                        <div>
                            <i class="fa-solid fa-share mr-2" style="color: #1f5137;"></i>Share
                        </div>
                        <!-- nested menu -->
                         <v-menu open-on-hover>
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" class="p-2 transparent relative">
                                    <i class="fa-solid fa-caret-down fa-rotate-270 ml-2" style="color: #1f5137;"></i>
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <i class="fa-solid fa-user-plus mr-2" style="color: #1f5137;"></i> Share
                                </v-list-item>
                                <v-list-item>
                                    <i class="fa-solid fa-link mr-2" style="color: #1f5137;"></i> Copy link
                                </v-list-item>
                            </v-list>
                        </v-menu> 
                        
                    </button>
                </v-list-item>
                <v-list-item>
                    <button class="block w-full text-left flex justify-between items-center">
                        <div>
                            <i class="fa-regular fa-folder-open mr-2" style="color: #1f5137;"></i>Organize
                        </div>
                        <!-- nested menu -->
                        <v-menu open-on-hover>
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" class="p-2 transparent relative">
                                    <i class="fa-solid fa-caret-down fa-rotate-270" style="color: #1f5137;"></i>
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <i class="fa-solid fa-share-from-square mr-2" style="color: #1f5137;"></i> Move
                                </v-list-item>
                                <v-list-item>
                                    <i class="fa-solid fa-up-right-from-square mr-2" style="color: #1f5137;"></i> Add shortcut
                                </v-list-item>
                            </v-list>
                        </v-menu> 
                    </button>
                </v-list-item>
                <v-list-item>
                    <button class="block w-full text-left flex justify-between items-center">
                        <div>
                            <i class="fa-solid fa-circle-info mr-2" style="color: #1f5137;"></i>File information
                        </div>
                        <!-- nested menu -->
                        <v-menu open-on-hover>
                            <template v-slot:activator="{ props }">
                                <button v-bind="props" class="p-2 transparent relative">
                                    <i class="fa-solid fa-caret-down fa-rotate-270" style="color: #1f5137;"></i>
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <i class="fa-solid fa-circle-info mr-2" style="color: #1f5137;"></i> Details
                                </v-list-item>
                                <v-list-item>
                                    <i class="fa-solid fa-chart-line mr-2" style="color: #1f5137;"></i> Activity
                                </v-list-item>
                                <v-list-item>
                                    <i class="fa-brands fa-searchengin mr-2" style="color: #1f5137;"></i> Search within
                                </v-list-item>
                            </v-list>
                        </v-menu> 
                    </button>
                </v-list-item>
                <div class="border-t"></div>
                <v-list-item>
                    <button class="block w-full text-left" @click="deleteItem(index)">
                        <i class="fa-solid fa-trash-can mr-2" style="color: #1f5137;"></i>Move to trash
                    </button>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "FolderMiniMenu",
    
    props: {
        index: Number,
    },
    methods: {
        deleteItem(index) {
            this.$emit('delete', index);
        },
        favouriteItem(index) {
            this.$emit('favourite', index);
        },
        openDialog(index) {
            this.$emit('openDialog', index);
        },
    }

}
</script>
