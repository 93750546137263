import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center bg-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "max-w-lg p-6 bg-white rounded-lg shadow" }, [
      _createElementVNode("h2", { class: "text-2xl font-bold mb-4 text-[#f24236]" }, "Suspended Access"),
      _createElementVNode("p", { class: "text-gray-600 mb-4" }, " You cannot access this page because your partner suspended access to it. Contact your partner for more information ")
    ], -1)
  ])))
}