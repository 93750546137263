<template>
  <div class="flex justify-start items-center bg-gray-100">
    <div class="w-[500px] p-6 bg-white rounded-lg shadow">
      <!-- Display no active Add-On message -->
      <div class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">
            {{ noActiveAddOnTitle }}
          </h3>
          <p class="mt-3 text-sm">
            {{ noActiveAddOnMessage }}
          </p>

          <p class="mt-3 text-sm" v-if="price > 0">
            Price: {{ price.toLocaleString() }} RWF per month (30 days) <span v-if="loggedInUser.sme.is_in_trial_period"
              class="mt-3 text-sm text-primary font-bold">
              | Free In Trial Period
            </span>
          </p>

          <p class="mt-3 text-sm" v-else>
            Always
            <span class="mt-3 text-sm text-primary font-bold">
              | Free
            </span>
          </p>

        </div>
      </div>

      <!-- Activate Add-On button -->
      <button type="button"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
        @click="activate" v-if="!processingAddOnActivation">
        Activate for <span v-if="loggedInUser.sme.is_in_trial_period">Free</span> <span v-else> {{ price.toLocaleString()
        }} RWF</span>
      </button>

      <!-- Activating button -->
      <button v-else type="button"
        class="w-full cursor-not-allowed font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
        Processing...
      </button>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: 'ActivateAddOn',
  props: {
    noActiveAddOnTitle: {
      type: String,
      required: true,
    },
    noActiveAddOnMessage: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    processingAddOnActivation: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    activate() {
      this.$emit('activate-add-on');
    },
  },
});
</script>

<style></style>
