<template>
  <div class="w-full lg:flex justify-between">
    <div class="lg:w-[50%]">
      <div class="flex flex-col justify-center items-center h-full relative">
        <div class="max-form w-full lg:py-0 py-10">
          <img
            src="../../assets/ared-logo.svg"
            class="w-[8rem] lg:hidden block m-auto mb-5"
            alt="right view"
          />
          <div class="bg-white rounded-[0.432rem] lg:p-0 p-4">
            <h1 class="font-['poppins'] font-bold text-[26px] text-center">
              Change Password
            </h1>

            <p
              class="font-poppins font-normal text-[14px] mt-2 lg:text-[#838282] text-[#4E8D6E] text-center"
            >
              You are required to change the default password you received in
              your email after the registration
            </p>

            <form class="mt-10">
              <TextField
                name="oldPassword"
                class="mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Current Password"
                type="password"
                v-model="v$.currentPassword.$model"
                :showErrors="v$.currentPassword.$errors.length ? true : false"
                :errors="v$.currentPassword.$errors"
              />
              <TextField
                name="newPassword"
                class="mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="New Password"
                type="password"
                v-model="v$.newPassword.$model"
                :showErrors="v$.newPassword.$errors.length ? true : false"
                :errors="v$.newPassword.$errors"
              />
              <TextField
                name="confirmPassword"
                class="font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Confirm New Password"
                type="password"
                v-model="v$.confirmNewPassword.$model"
                :showErrors="
                  v$.confirmNewPassword.$errors.length ? true : false
                "
                :errors="v$.confirmNewPassword.$errors"
              />
              <div v-if="passwordsNotMatch">
                <span class="text-red text-[12px] mt-2"
                  >Passwords must match</span
                >
              </div>
              <div v-if="passwordErrors.length">
                <p
                  v-for="error in passwordErrors"
                  :key="error"
                  class="text-red text-[12px] mt-2"
                >
                  {{ error }}
                </p>
              </div>
              <div class="flex">
                <button
                  v-if="!loading"
                  :disabled="v$.$invalid"
                  type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2 mt-10"
                  @click="changePassword"
                >
                  {{ buttonText }}
                </button>
                <button
                  v-else
                  :disabled="v$.$invalid"
                  type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2 mt-10"
                >
                  Processing...
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-[50%]">
      <div class="flex justify-center items-center">
        <auth-right-image />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters, mapMutations } from 'vuex';
import { required, minLength, maxLength } from '@vuelidate/validators';

import router from '@/router';
import instance from '@/axios-interceptor';
import TextField from '../../components/reusable/TextField.vue';
import AuthRightImage from '../../components/markup/AuthRightImage.vue';

export default defineComponent({
  name: 'PasswordResetPage',
  components: {
    AuthRightImage,
    TextField,
  },
  setup() {
    const formData = ref({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });

    const loading = ref(false);
    const passwordsNotMatch = ref(false);
    const passwordErrors = ref([]);
    const buttonText = ref('Change & Continue');

    const rules = {
      newPassword: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      currentPassword: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      confirmNewPassword: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formData);

    return {
      formData,
      v$,
      loading,
      passwordsNotMatch,
      passwordErrors,
      buttonText,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'hasReceivedOTP', 'isAuthenticated']),
  },
  methods: {
    ...mapMutations(['setHasReceivedOTP', 'setLoginBtn']),
    async changePassword() {
      if (
        this.formData.newPassword &&
        this.formData.newPassword !== this.formData.confirmNewPassword
      ) {
        this.passwordErrors = [];
        this.passwordsNotMatch = true;
        return;
      }

      this.passwordsNotMatch = false;

      const formData = {
        new_password: this.formData.newPassword,
        current_password: this.formData.currentPassword,
      };

      this.loading = true;

      instance
        .post('users/update-password/', formData)
        .then(() => {
          this.$toast.success('Password changed successfully', {
            position: 'top-right',
            duration: 8000,
          });

          if (!this.hasReceivedOTP) {
            this.loading = false;
            this.buttonText = 'Sending OTP Token to your email...';

            instance
              .post(`2fa/`)
              .then((res) => {
                this.$toast.success(res.data.detail, {
                  position: 'top-right',
                  duration: 8000,
                });
                this.setHasReceivedOTP();
                this.setLoginBtn({ text: 'Log In' });
                this.buttonText = 'Change & Continue';
                console.log('FORM SUBMITTED AND ROUTING TO THE OTP PAGE');
                router.push({ path: '/otp' });
              })
              .catch((error) => {
                this.loading = false;
                this.$toast.error(error.response.data.detail, {
                  position: 'top-right',
                });
              });
          } else {
            router.push({ path: '/dashboard' });
          }
        })
        .catch((error: any) => {
          this.loading = false;
          console.log('ERROR RESPONSE: ', error.response.data);
          if (error.response.data.message) {
            return this.$toast.error(error.response.data.message, {
              position: 'top-right',
            });
          }

          this.$toast.error('Could not update the password. Try again later', {
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    if (
      this.loggedInUser.changed_password_after_initial_login &&
      this.isAuthenticated
    ) {
      router.push({ path: '/dashboard' });
    }
  },
});
</script>


<style scoped>
@media screen and (max-width: 1023px) {
  .max-form {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    background: linear-gradient(180deg, #4c8a6c 0%, #0b1c14 100%);
  }
}
</style>