import router from '@/router';
import { createStore } from 'vuex';
import axios from '@/axios-interceptor';
import { useToast } from 'vue-toast-notification';
import CreatePersistedState from 'vuex-persistedstate';

const $toast = useToast();

export default createStore({
  state: {
    refresh_token: '',
    access_token: '',
    loggedInUser: {},
    isAuthenticated: false,
    hasReceivedOTP: false,
    hasFetchedUserInfo: false,
    loginBtn: {
      text: 'Log In',
      disabled: false,
    },
  },
  mutations: {
    setLoggedInUser: function (state, user) {
      state.loggedInUser = user;
    },
    setAuthenticated: function (state) {
      state.isAuthenticated = !state.isAuthenticated;
    },
    setHasReceivedOTP: function (state: any) {
      state.hasReceivedOTP = !state.hasReceivedOTP;
    },
    setHasFetchedUserInfo: function (state: any, payload: boolean) {
      state.hasFetchedUserInfo = payload;
    },
    setLoginBtn(state, payload) {
      state.loginBtn.text = payload.text;
      state.loginBtn.disabled = payload.text !== 'Log In' ? true : false;
    },
    setRefreshToken: function (state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function (state, accessToken) {
      state.access_token = accessToken;
    },
    clearUserData: function (state) {
      state.refresh_token = '';
      state.access_token = '';
      state.loggedInUser = {};
      state.isAuthenticated = false;
      state.hasReceivedOTP = false;
      state.hasFetchedUserInfo = false;
    },
  },
  actions: {
    fetchUser: async ({ commit, state }) => {
      try {
        await axios.get(`auth/users/me/`).then((response) => {
          const user = response.data;

          if (user.role === 'sme_admin' || user.role === 'sme_staff') {
            console.log("USER DATA FOR PLACIDE TECH RETRIEVED: ", user);
            if (user.sme.is_terminated) {
              commit('clearUserData');
              commit('setLoginBtn', { text: 'Log In' });
  
              $toast.error(
                'Your account was terminated by the Admin. Reach out to ARED for more information',
                {
                  position: 'top-right',
                duration: 8000,
                }
              );
  
              router.replace('/');
            } else if (!user.changed_password_after_initial_login) {
              if (state.isAuthenticated) {
                localStorage.clear();
                commit('clearUserData');
                router.push({ path: '/' });
                window.location.reload();
                $toast.info('You may login again', {
                  position: 'top-right',
                  duration: 8000,
                });
              } else {
                commit('setAuthenticated');
                router.replace('/change-password');
              }
            } else {
              commit('setLoggedInUser', response.data);
              commit('setHasFetchedUserInfo', true);
              localStorage.setItem('smeId', response.data.sme.id);
              localStorage.setItem('activeId', `${0}`);
            }
          } else {
            commit('clearUserData');
            commit('setLoginBtn', { text: 'Log In' });

            $toast.error('No sme account associated with this user', {
              position: 'top-right',
            });

            router.replace('/');
          }
        });
      } catch (e: any) {
        console.log(e);
      }
    },
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = 'auth/jwt/refresh/';
      try {
        await axios
          .post(refreshUrl, { refresh: state.refresh_token })
          .then((response) => {
            if (response.status === 200) {
              commit('setAccessToken', response.data.access);
            }
          });
      } catch (e: any) {
        if (e.response.status === 401) {
          $toast.info('Your session has expired. Login again', {
            position: 'top-right',
          });
        } else {
          console.log(e.response);
        }
      }
    },
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    isAuthenticated: (state) => state.isAuthenticated,
    loginBtn: (state) => state.loginBtn,
    accessToken: (state) => state.access_token,
    refreshToken: (state) => state.refresh_token,
    hasReceivedOTP: (state: any) => state.hasReceivedOTP,
    hasFetchedUserInfo: (state: any) => state.hasFetchedUserInfo,
  },
  plugins: [CreatePersistedState()],
});
