import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/plus-icon.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = {
  key: 1,
  class: "w-full my-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "w-full my-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative"
}
const _hoisted_10 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_11 = { class: "flex justify-between" }
const _hoisted_12 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_13 = { class: "mr-2" }
const _hoisted_14 = { class: "mr-2" }
const _hoisted_15 = { class: "mr-2" }
const _hoisted_16 = { class: "mr-2" }
const _hoisted_17 = { class: "mr-2" }
const _hoisted_18 = { class: "mr-2" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_25 = { key: 2 }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_28 = { key: 3 }
const _hoisted_29 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_30 = { key: 4 }
const _hoisted_31 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_32 = { key: 5 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = {
  key: 1,
  class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
  type: "button",
  disabled: ""
}
const _hoisted_35 = { id: "TabContent" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 0,
  class: "text-[#f24236] text-xs mb-3 w-1/2"
}
const _hoisted_38 = { class: "w-full flex" }
const _hoisted_39 = { class: "w-1/2" }
const _hoisted_40 = {
  key: 0,
  class: "bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative"
}
const _hoisted_41 = { class: "flex justify-start" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = {
  key: 1,
  type: "button",
  class: "font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}
const _hoisted_44 = {
  key: 1,
  class: "w-full"
}
const _hoisted_45 = { key: 0 }
const _hoisted_46 = {
  key: 0,
  class: "text-[#f24236] text-xs mb-3 w-1/2"
}
const _hoisted_47 = { class: "mt-5 flex items-center" }
const _hoisted_48 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_49 = { class: "text-[#464E5F] capitalize" }
const _hoisted_50 = { class: "text-[#464E5F]" }
const _hoisted_51 = { class: "text-[#464E5F]" }
const _hoisted_52 = { class: "text-[#464E5F]" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { class: "flex items-center justify-end" }
const _hoisted_55 = ["onClick"]
const _hoisted_56 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_57 = { key: 1 }
const _hoisted_58 = {
  key: 2,
  class: "spinner-container"
}
const _hoisted_59 = { key: 2 }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = {
  key: 0,
  class: "text-[#f24236] text-xs mb-3 w-1/2"
}
const _hoisted_62 = { class: "flex items-center" }
const _hoisted_63 = { class: "flex flex-col ml-3" }
const _hoisted_64 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_65 = { class: "text-[#464E5F]" }
const _hoisted_66 = { class: "flex flex-col" }
const _hoisted_67 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_68 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_69 = { class: "spinner-container" }
const _hoisted_70 = {
  key: 0,
  class: "spinner"
}
const _hoisted_71 = { key: 1 }
const _hoisted_72 = { key: 3 }
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { class: "bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative" }
const _hoisted_75 = { class: "flex justify-start" }
const _hoisted_76 = { class: "flex items-center mt-4" }
const _hoisted_77 = {
  key: 1,
  type: "button",
  class: "font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}
const _hoisted_78 = { class: "spinner-container" }
const _hoisted_79 = {
  key: 0,
  class: "spinner"
}
const _hoisted_80 = { key: 1 }
const _hoisted_81 = { key: 4 }
const _hoisted_82 = {
  key: 0,
  class: "flex"
}
const _hoisted_83 = { class: "bg-white w-1/2 ml-8 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative" }
const _hoisted_84 = { key: 0 }
const _hoisted_85 = ["onUpdate:modelValue"]
const _hoisted_86 = ["onUpdate:modelValue"]
const _hoisted_87 = {
  key: 1,
  type: "button",
  class: "font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}
const _hoisted_88 = { key: 1 }
const _hoisted_89 = ["value"]
const _hoisted_90 = ["value"]
const _hoisted_91 = { class: "spinner-container" }
const _hoisted_92 = {
  key: 0,
  class: "spinner"
}
const _hoisted_93 = { key: 1 }
const _hoisted_94 = { key: 5 }
const _hoisted_95 = { key: 0 }
const _hoisted_96 = {
  key: 0,
  class: "text-[#f24236] text-xs mb-3 w-1/2"
}
const _hoisted_97 = { class: "spinner-container" }
const _hoisted_98 = {
  key: 0,
  class: "spinner"
}
const _hoisted_99 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_suspended_page_message = _resolveComponent("suspended-page-message")!
  const _component_payment_required_message = _resolveComponent("payment-required-message")!
  const _component_activate_subscription_message = _resolveComponent("activate-subscription-message")!
  const _component_UpgradeSMSPackage = _resolveComponent("UpgradeSMSPackage")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_activate_add_on = _resolveComponent("activate-add-on")!
  const _component_FeedbackDashboard = _resolveComponent("FeedbackDashboard")!
  const _component_upload_menu = _resolveComponent("upload-menu")!
  const _component_sms_payment_modal = _resolveComponent("sms-payment-modal")!
  const _component_ared_eula_modal = _resolveComponent("ared-eula-modal")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Shiriki Add-On",
      icon: "fa-solid fa-puzzle-piece",
      iconType: "class"
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[33] || (_cache[33] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[34] || (_cache[34] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ]),
        (_ctx.activeTab === 'food-drink-menu' &&
          _ctx.loggedInUser.sme.has_food_drink_menu_feature_enabled
        )
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUploadMenuModal = true))
            }, _cache[35] || (_cache[35] = [
              _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex" }, [
                _createElementVNode("img", {
                  src: _imports_1,
                  class: "md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2",
                  alt: ""
                }),
                _createTextVNode(" Add Menu")
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.loggedInUser.sme?.is_active)
      ? (_openBlock(), _createBlock(_component_suspended_page_message, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_payment_required_message)
              ]))
            : (!_ctx.loggedInUser.sme.subscription_plan)
              ? (_openBlock(), _createBlock(_component_activate_subscription_message, { key: 1 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.loggedInUser.sme?.has_not_paid_for_one_billing_five_days_ago)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_payment_required_message)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("ul", _hoisted_12, [
                                _createElementVNode("li", _hoisted_13, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'sms-package'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "routers",
                                    "data-tabs-target": "#routers",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "routers",
                                    "aria-selected": "false",
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTab('sms-package')))
                                  }, " SMS Package ", 2)
                                ]),
                                _createElementVNode("li", _hoisted_14, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'food-drink-menu'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "food-drink-menu",
                                    "data-tabs-target": "#food-drink-menu",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "food-drink-menu",
                                    "aria-selected": "false",
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActiveTab('food-drink-menu')))
                                  }, " Food & Drinks Menu ", 2)
                                ]),
                                _createElementVNode("li", _hoisted_15, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'feedback'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "food-drink-menu",
                                    "data-tabs-target": "#food-drink-menu",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "food-drink-menu",
                                    "aria-selected": "false",
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setActiveTab('feedback')))
                                  }, " Feedback ", 2)
                                ]),
                                _createElementVNode("li", _hoisted_16, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'autoconnect'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "food-drink-menu",
                                    "data-tabs-target": "#food-drink-menu",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "food-drink-menu",
                                    "aria-selected": "false",
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setActiveTab('autoconnect')))
                                  }, " Auto-Connect ", 2)
                                ]),
                                _createElementVNode("li", _hoisted_17, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'token-purchase'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "token-purchase",
                                    "data-tabs-target": "#token-purchase",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "token-purchase",
                                    "aria-selected": "false",
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setActiveTab('token-purchase')))
                                  }, " Token Purchase ", 2)
                                ]),
                                _createElementVNode("li", _hoisted_18, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                      _ctx.activeTab === 'table-reservation'
                        ? 'bg-light text-[#4e8d6e]'
                        : 'bg-[transparent]',
                    ]]),
                                    id: "food-drink-menu",
                                    "data-tabs-target": "#food-drink-menu",
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": "food-drink-menu",
                                    "aria-selected": "false",
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setActiveTab('table-reservation')))
                                  }, " Table Reservation ", 2)
                                ])
                              ]),
                              (_ctx.activeTab === 'sms-package' &&
                _ctx.loggedInUser.sme.has_voucher_sms_feature_enabled && !_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    (!_ctx.isPendingSMSAddOnCancellation())
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          (!_ctx.deactivating)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                                type: "button",
                                                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deactivateAddOn('sms-addon')))
                                              }, " Deactivate SMS Add-On "))
                                            : (_openBlock(), _createElementBlock("button", _hoisted_21, " Deactivating... "))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'food-drink-menu' &&
                _ctx.loggedInUser.sme.has_food_drink_menu_feature_enabled
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    (!_ctx.isPendingAddOnCancellation('menu'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                          (!_ctx.deactivating)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                                type: "button",
                                                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.deactivateAddOn('menu-addon')))
                                              }, " Deactivate Menu Add-On "))
                                            : (_openBlock(), _createElementBlock("button", _hoisted_24, " Deactivating... "))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'feedback' &&
                _ctx.loggedInUser.sme.has_rating_feature_enabled
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                    (!_ctx.isPendingAddOnCancellation('feedback'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                          (!_ctx.deactivating)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                                type: "button",
                                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.deactivateAddOn('feedback-addon')))
                                              }, " Deactivate Feedback Add-On "))
                                            : (_openBlock(), _createElementBlock("button", _hoisted_27, " Deactivating... "))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'autoconnect' &&
                _ctx.loggedInUser.sme.has_autoconnect_addon_enabled
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                    (!_ctx.deactivating)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                          type: "button",
                                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deactivateAddOn('autoconnect-addon')))
                                        }, " Deactivate Auto-Connect Add-On "))
                                      : (_openBlock(), _createElementBlock("button", _hoisted_29, " Deactivating... "))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'token-purchase' &&
                _ctx.loggedInUser.sme.has_token_purchase_addon_enabled
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                    (!_ctx.deactivating)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                          type: "button",
                                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.deactivateAddOn('token-purchase-addon')))
                                        }, " Deactivate Token Purchase Add-On "))
                                      : (_openBlock(), _createElementBlock("button", _hoisted_31, " Deactivating... "))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'table-reservation' &&
                _ctx.loggedInUser.sme.has_table_reservation_addon_enabled
              )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                    (!_ctx.isPendingAddOnCancellation('table'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                          (!_ctx.deactivating)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: "w-full cursor-pointer bg-white border-[#f24236] font-['poppins'] text-xs text-[#f24236] rounded-[0.432rem] p-2 font-medium border",
                                                type: "button",
                                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deactivateAddOn('table-reservation-addon')))
                                              }, " Deactivate Table Reservation Add-On "))
                                            : (_openBlock(), _createElementBlock("button", _hoisted_34, " Deactivating... "))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_35, [
                              (_ctx.activeTab === 'sms-package')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                    (_ctx.isPendingSMSAddOnCancellation())
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_37, " You've recently requested to deactivate your SMS Add-On. Just a reminder that this change will take effect at the end of your current billing cycle. You'll continue to have access to the add-on until then. "))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("div", _hoisted_39, [
                                        _createVNode(_component_UpgradeSMSPackage, {
                                          defaultSMSPackages: _ctx.smsPackages,
                                          processingSMSPackage: _ctx.processingSMSPackage,
                                          pendingSMSPackages: _ctx.pendingSMSPackages,
                                          "show-cancellation-dialog": _ctx.showCancellationDialog,
                                          "loading-cancellation": _ctx.loadingCancellation,
                                          onActivateSmsPackage: _ctx.displaySMSPaymentModal,
                                          onChangeSmsPackage: _ctx.changeSMSPackage,
                                          onToggleCancellationDialog: _ctx.toggleCancellationDialog,
                                          onCancelSmsPackage: _ctx.requestForAddOnDeactivation
                                        }, null, 8, ["defaultSMSPackages", "processingSMSPackage", "pendingSMSPackages", "show-cancellation-dialog", "loading-cancellation", "onActivateSmsPackage", "onChangeSmsPackage", "onToggleCancellationDialog", "onCancelSmsPackage"])
                                      ]),
                                      (_ctx.loggedInUser.sme.has_voucher_sms_feature_enabled && !_ctx.loggedInUser.sme?.has_not_paid_for_one_sms_billing)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                            _cache[38] || (_cache[38] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " SMS TOKEN DURATION ", -1)),
                                            _cache[39] || (_cache[39] = _createElementVNode("p", { class: "mb-4" }, " The voucher SMS feature lets users request a WiFi token via SMS, instantly connecting them without needing manual token distribution. It allows users to request internet access tokens via SMS on the box's captive portal, eliminating the need for admins to create tokens manually in the dashboard. ", -1)),
                                            _createElementVNode("div", _hoisted_41, [
                                              _cache[37] || (_cache[37] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3" }, " Token Duration: ", -1)),
                                              _withDirectives(_createElementVNode("select", {
                                                id: "duration",
                                                type: "duration",
                                                class: "text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1",
                                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.duration) = $event))
                                              }, _cache[36] || (_cache[36] = [
                                                _createStaticVNode("<option value=\"Select Default Duration\" disabled selected> Select Default Duration </option><option value=\"1_hour\">1 Hour</option><option value=\"2_hours\">2 Hours</option><option value=\"5_hours\">5 Hours</option><option value=\"10_hours\">10 Hours</option><option value=\"24_hours\">24 Hours</option><option value=\"custom\">Custom Minutes</option>", 7)
                                              ]), 512), [
                                                [_vModelSelect, _ctx.duration]
                                              ]),
                                              (_ctx.duration === 'custom')
                                                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                    key: 0,
                                                    class: "ml-4 text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1",
                                                    type: "number",
                                                    name: "custom_duration",
                                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customDuration) = $event)),
                                                    placeholder: "0"
                                                  }, null, 512)), [
                                                    [_vModelText, _ctx.customDuration]
                                                  ])
                                                : _createCommentVNode("", true)
                                            ]),
                                            (_ctx.loggedInUser.sme.has_voucher_sms_feature_enabled)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                                  (!_ctx.loadingVoucherDurationPreference)
                                                    ? (_openBlock(), _createElementBlock("button", {
                                                        key: 0,
                                                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setVoucherPreference())),
                                                        type: "button",
                                                        class: "font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
                                                      }, " Save "))
                                                    : (_openBlock(), _createElementBlock("button", _hoisted_43, " Processing... "))
                                                ]))
                                              : _createCommentVNode("", true)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'food-drink-menu')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                    (_ctx.loggedInUser.sme.has_food_drink_menu_feature_enabled)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                          (_ctx.isPendingAddOnCancellation('menu'))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_46, " You've recently requested to deactivate the Menu Add-On. Just a reminder that this change will take effect at the end of your current billing cycle. You'll continue to have access to the add-on until then. "))
                                            : _createCommentVNode("", true),
                                          (_ctx.propLoaded)
                                            ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                                                key: 1,
                                                perPageItems: _ctx.perSize,
                                                pageNumber: _ctx.page,
                                                ApiData: _ctx.foodDrinkMenus,
                                                onHandleNext: _ctx.handlleNext,
                                                onHandlePrev: _ctx.handlePrev
                                              }, {
                                                section: _withCtx(() => [
                                                  _createElementVNode("div", null, [
                                                    _createElementVNode("div", _hoisted_47, [
                                                      _cache[41] || (_cache[41] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                                                      _createElementVNode("span", _hoisted_48, [
                                                        _withDirectives(_createElementVNode("select", {
                                                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                                          onChange: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                                        }, _cache[40] || (_cache[40] = [
                                                          _createElementVNode("option", { value: "10" }, "10", -1),
                                                          _createElementVNode("option", { value: "20" }, "20", -1),
                                                          _createElementVNode("option", { value: "30" }, "30", -1),
                                                          _createElementVNode("option", { value: "40" }, "40", -1),
                                                          _createElementVNode("option", { value: "50" }, "50", -1)
                                                        ]), 544), [
                                                          [_vModelSelect, _ctx.selectedOption]
                                                        ])
                                                      ])
                                                    ])
                                                  ])
                                                ]),
                                                _: 1
                                              }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                                            : _createCommentVNode("", true),
                                          _createVNode(_component_SmeTable, { tableHeaderData: _ctx.foodDrinkMenuTableHeaders }, {
                                            tableBodyData: _withCtx(() => [
                                              (_ctx.foodDrinkMenus.length)
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.foodDrinkMenus, (menu) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                      key: menu.id,
                                                      class: "table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                                    }, [
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("span", _hoisted_49, _toDisplayString(menu.menu_type), 1)
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("span", _hoisted_50, _toDisplayString(menu.realm.name), 1)
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("span", _hoisted_51, _toDisplayString(menu.original_file_name), 1)
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.createdAt(menu.created)), 1)
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("div", null, [
                                                          _createElementVNode("span", {
                                                            onClick: ($event: any) => (_ctx.downloadFile(menu.file)),
                                                            class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                                                          }, _cache[42] || (_cache[42] = [
                                                            _createTextVNode("Download"),
                                                            _createElementVNode("i", { class: "ml-1.5 fa-solid fa-download" }, null, -1)
                                                          ]), 8, _hoisted_53)
                                                        ])
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("div", _hoisted_54, [
                                                          _createElementVNode("div", null, [
                                                            (!_ctx.deleting ||
                                  _ctx.currentDeletionFileID !== menu.id
                                )
                                                              ? (_openBlock(), _createElementBlock("span", {
                                                                  key: 0,
                                                                  onClick: ($event: any) => (_ctx.deleteMenu(menu.id)),
                                                                  class: "capitalize p-2 rounded bg-[#f24236] text-white mb-0.5 inline-block text-center cursor-pointer"
                                                                }, "Delete", 8, _hoisted_55))
                                                              : _createCommentVNode("", true),
                                                            (_ctx.deleting &&
                                  _ctx.currentDeletionFileID === menu.id
                                )
                                                              ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                                                  key: 1,
                                                                  color: "#d7191c"
                                                                }))
                                                              : _createCommentVNode("", true)
                                                          ])
                                                        ])
                                                      ])
                                                    ]))
                                                  }), 128))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_56, _cache[43] || (_cache[43] = [
                                                    _createElementVNode("b", null, "No Food & Drinks Menus Found. Click on the Add Menu button in the top-right corner to one", -1)
                                                  ])))
                                            ]),
                                            _: 1
                                          }, 8, ["tableHeaderData"])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_57, [
                                          _createVNode(_component_activate_add_on, {
                                            noActiveAddOnTitle: "Food & Drinks Menu Add-On Inactive",
                                            noActiveAddOnMessage: "The Food & Drinks Menu Add-On will help you add Food & Drinks menus  to your captive portal so that users can view them",
                                            price: 2500,
                                            processingAddOnActivation: _ctx.processingAddOnActivation,
                                            onActivateAddOn: _ctx.activateFoodMenuAddOn
                                          }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                                        ])),
                                    (_ctx.loading)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_58, _cache[44] || (_cache[44] = [
                                          _createElementVNode("div", { class: "spinner" }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'feedback')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                                    (_ctx.loggedInUser.sme.has_rating_feature_enabled)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                                          (_ctx.isPendingAddOnCancellation('feedback'))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_61, " You've recently requested to deactivate the Feedback Add-On. Just a reminder that this change will take effect at the end of your current billing cycle. You'll continue to have access to the add-on until then. "))
                                            : _createCommentVNode("", true),
                                          (_ctx.propLoaded)
                                            ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                                                key: 1,
                                                perPageItems: _ctx.perSize,
                                                pageNumber: _ctx.page,
                                                ApiData: _ctx.feedbackList,
                                                onHandleNext: _ctx.handlleNext,
                                                onHandlePrev: _ctx.handlePrev
                                              }, null, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                                            : _createCommentVNode("", true),
                                          _createVNode(_component_FeedbackDashboard, { onFilterData: _ctx.fetchFeedback }, null, 8, ["onFilterData"]),
                                          _createVNode(_component_SmeTable, { tableHeaderData: _ctx.feedbackTableHeaders }, {
                                            tableBodyData: _withCtx(() => [
                                              (_ctx.paginatedTableData.length)
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (feedback) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                      key: feedback.id,
                                                      class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                                                    }, [
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("div", _hoisted_62, [
                                                          _createElementVNode("div", _hoisted_63, [
                                                            _createElementVNode("span", _hoisted_64, _toDisplayString(feedback.rating), 1)
                                                          ])
                                                        ])
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("span", _hoisted_65, _toDisplayString(feedback.comment), 1)
                                                      ]),
                                                      _createElementVNode("td", null, [
                                                        _createElementVNode("div", _hoisted_66, [
                                                          _createElementVNode("span", _hoisted_67, _toDisplayString(feedback.created), 1)
                                                        ])
                                                      ])
                                                    ]))
                                                  }), 128))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_68, _cache[45] || (_cache[45] = [
                                                    _createElementVNode("b", null, "No feedback found", -1)
                                                  ])))
                                            ]),
                                            _: 1
                                          }, 8, ["tableHeaderData"]),
                                          _createElementVNode("div", _hoisted_69, [
                                            (_ctx.loading)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_70))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_71, [
                                          _createVNode(_component_activate_add_on, {
                                            noActiveAddOnTitle: "Feedback Add-On Inactive",
                                            noActiveAddOnMessage: "The Feedback Add-On will help you receive feedback and ratings from users connected to your captive portal",
                                            processingAddOnActivation: _ctx.processingAddOnActivation,
                                            price: 2500,
                                            onActivateAddOn: _ctx.activateFeedbackAddOn
                                          }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                                        ]))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'autoconnect')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                                    (_ctx.loggedInUser.sme.has_autoconnect_addon_enabled)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                                          _createElementVNode("div", _hoisted_74, [
                                            _cache[49] || (_cache[49] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " AUTO-CONNECT TOKEN DURATION ", -1)),
                                            _cache[50] || (_cache[50] = _createElementVNode("p", { class: "mb-4" }, " Our Auto-Connect Token Duration feature automatically generates a token when the user connects, ensuring seamless internet access. Administrators can set up the default duration for user internet access, ensuring uninterrupted connectivity without the hassle of manual token creation. ", -1)),
                                            _createElementVNode("div", _hoisted_75, [
                                              _cache[47] || (_cache[47] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3" }, " Token Duration: ", -1)),
                                              _withDirectives(_createElementVNode("select", {
                                                id: "duration",
                                                type: "duration",
                                                class: "text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1",
                                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.duration) = $event))
                                              }, _cache[46] || (_cache[46] = [
                                                _createStaticVNode("<option value=\"Select Default Duration\" disabled selected> Select Default Duration </option><option value=\"1_hour\">1 Hour</option><option value=\"2_hours\">2 Hours</option><option value=\"5_hours\">5 Hours</option><option value=\"10_hours\">10 Hours</option><option value=\"24_hours\">24 Hours</option><option value=\"custom\">Custom Minutes</option>", 7)
                                              ]), 512), [
                                                [_vModelSelect, _ctx.duration]
                                              ]),
                                              (_ctx.duration === 'custom')
                                                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                    key: 0,
                                                    class: "ml-4 text-[14px] h-[2.688rem] border-[0.063rem] rounded-[0.3rem] px-3 py-2 border-slate-300 placeholder-slate-400 focus:outline-none block focus:ring-1",
                                                    type: "number",
                                                    name: "custom_duration",
                                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.customDuration) = $event)),
                                                    placeholder: "0"
                                                  }, null, 512)), [
                                                    [_vModelText, _ctx.customDuration]
                                                  ])
                                                : _createCommentVNode("", true)
                                            ]),
                                            _createElementVNode("div", _hoisted_76, [
                                              _cache[48] || (_cache[48] = _createElementVNode("label", {
                                                for: "autoconnect-daily-usage",
                                                class: "block text-sm font-medium text-slate-700 mb-3 mr-3 mt-3"
                                              }, " Auto-Connect Daily Usage: ", -1)),
                                              _withDirectives(_createElementVNode("input", {
                                                type: "checkbox",
                                                id: "autoconnect-daily-usage",
                                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.autoconnectDailyUsage) = $event)),
                                                class: "toggle-checkbox"
                                              }, null, 512), [
                                                [_vModelCheckbox, _ctx.autoconnectDailyUsage]
                                              ])
                                            ]),
                                            _cache[51] || (_cache[51] = _createElementVNode("p", { class: "mb-4" }, " Enabling this option will limit the daily usage of free internet access to the specified token duration. Users will only be able to connect for the configured duration each day. ", -1)),
                                            (!_ctx.loadingVoucherDurationPreference)
                                              ? (_openBlock(), _createElementBlock("button", {
                                                  key: 0,
                                                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.setVoucherPreference())),
                                                  type: "button",
                                                  class: "font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
                                                }, " Save "))
                                              : (_openBlock(), _createElementBlock("button", _hoisted_77, " Processing... "))
                                          ]),
                                          _createElementVNode("div", _hoisted_78, [
                                            (_ctx.loading)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_79))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_80, [
                                          _createVNode(_component_activate_add_on, {
                                            noActiveAddOnTitle: "Auto-Connect Add-On Inactive",
                                            noActiveAddOnMessage: "Introducing the Auto-Connect Add-On, designed to streamline Internet connectivity. Say goodbye to manual token entry as users effortlessly connect to the Internet with just a click.",
                                            processingAddOnActivation: _ctx.processingAddOnActivation,
                                            price: 0,
                                            onActivateAddOn: _ctx.activateAutoConnectAddOn
                                          }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                                        ]))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'token-purchase')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                                    (_ctx.loggedInUser.sme.has_token_purchase_addon_enabled)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                          _cache[58] || (_cache[58] = _createStaticVNode("<div class=\"bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative\"><h3 class=\"font-bold text-lg mb-2 text-primary\"> TOKEN PURCHASE ADD-ON </h3><p class=\"mb-4\"> Enhance your captive portal with our Token Purchase Addon, offering flexible Internet access options for users. This feature allows administrators to configure free access time and the option to purchase tokens for extended Internet usage. </p><p class=\"mb-4\"><strong>Free Access Configuration: </strong>Administrators can set a default duration for free Internet access, ensuring users can connect seamlessly without initial payment. Whether free access is permitted and its duration can be customized in the addon settings.</p><p class=\"mb-4\"><strong>Daily Free Access Restriction: </strong>Free access is limited to a daily basis. Users can enjoy a limited time of free Internet access each day, but once the free period is over, they will need to wait until the next day for another free session.</p><p class=\"mb-4\"><strong>Token Purchase: </strong>Once the free access period expires, users will need to purchase tokens to continue using the Internet. Administrators can specify the token durations and prices, providing clear options for users.</p><p class=\"mb-4\"><strong>User Experience: </strong>When users connect to the WiFi network, the captive portal will open, displaying the free access details and token purchase options. This ensures a smooth transition from free access to paid access, enhancing user satisfaction and monetizing Internet access efficiently.</p></div>", 1)),
                                          _createElementVNode("div", _hoisted_83, [
                                            (_ctx.editMode)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                                                  _cache[54] || (_cache[54] = _createElementVNode("h3", { class: "font-bold text-lg mb-4 text-primary" }, " TOKEN DURATIONS AND PRICES ", -1)),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenPurchaseOptions, (item, index) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                      key: index,
                                                      class: "flex items-center mb-4"
                                                    }, [
                                                      _cache[52] || (_cache[52] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mb-2 mr-2" }, " Price (RWF): ", -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        type: "text",
                                                        "onUpdate:modelValue": ($event: any) => ((item.price) = $event),
                                                        class: "text-[14px] focus:outline-none h-[2.688rem] mr-4 rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block",
                                                        readonly: ""
                                                      }, null, 8, _hoisted_85), [
                                                        [_vModelText, item.price]
                                                      ]),
                                                      _cache[53] || (_cache[53] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mt-2 mb-2 mr-2" }, " Duration (minutes): ", -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        type: "number",
                                                        "onUpdate:modelValue": ($event: any) => ((item.duration_in_minutes) = $event),
                                                        class: "text-[14px] focus:outline-none focus:ring h-[2.688rem] rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block"
                                                      }, null, 8, _hoisted_86), [
                                                        [_vModelText, item.duration_in_minutes]
                                                      ])
                                                    ]))
                                                  }), 128)),
                                                  (!_ctx.loadingTokenPurchasePreference)
                                                    ? (_openBlock(), _createElementBlock("button", {
                                                        key: 0,
                                                        onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.saveTokenPurchasePreference())),
                                                        type: "button",
                                                        class: "font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
                                                      }, " Save "))
                                                    : (_openBlock(), _createElementBlock("button", _hoisted_87, " Processing... "))
                                                ]))
                                              : (_openBlock(), _createElementBlock("div", _hoisted_88, [
                                                  _cache[57] || (_cache[57] = _createElementVNode("h3", { class: "font-bold text-lg mb-4 text-primary" }, " TOKEN DURATIONS AND PRICES ", -1)),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenPurchaseOptions, (item, index) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                      key: index,
                                                      class: "flex items-center mb-4"
                                                    }, [
                                                      _cache[55] || (_cache[55] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mb-2 mr-2" }, " Price (RWF): ", -1)),
                                                      _createElementVNode("input", {
                                                        type: "text",
                                                        value: item.price,
                                                        class: "text-[14px] focus:outline-none h-[2.688rem] mr-4 rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block",
                                                        readonly: ""
                                                      }, null, 8, _hoisted_89),
                                                      _cache[56] || (_cache[56] = _createElementVNode("label", { class: "block text-sm font-medium text-slate-700 mt-2 mb-2 mr-2" }, " Duration: ", -1)),
                                                      _createElementVNode("input", {
                                                        type: "text",
                                                        value: item.display_duration,
                                                        class: "text-[14px] focus:outline-none h-[2.688rem] rounded-[0.3rem] px-3 py-2 placeholder-slate-400 block",
                                                        readonly: ""
                                                      }, null, 8, _hoisted_90)
                                                    ]))
                                                  }), 128)),
                                                  _createElementVNode("button", {
                                                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.editMode = true)),
                                                    type: "button",
                                                    class: "font-poppins text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
                                                  }, " Edit ")
                                                ]))
                                          ]),
                                          _createElementVNode("div", _hoisted_91, [
                                            (_ctx.loading)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_92))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_93, [
                                          _createVNode(_component_activate_add_on, {
                                            noActiveAddOnTitle: "Token Purchase Add-On Inactive",
                                            noActiveAddOnMessage: "Introducing the Token Purchase Addon, designed to simplify Internet access. Enjoy configurable free access and seamless token purchases for uninterrupted connectivity.",
                                            processingAddOnActivation: _ctx.processingAddOnActivation,
                                            price: 0,
                                            onActivateAddOn: _ctx.activateTokenPurchaseAddOn
                                          }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                                        ]))
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.activeTab === 'table-reservation')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                                    (_ctx.loggedInUser.sme.has_table_reservation_addon_enabled)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                                          (_ctx.isPendingAddOnCancellation('table'))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_96, " You've recently requested to deactivate the Table Reservation Add-On. Just a reminder that this change will take effect at the end of your current billing cycle. You'll continue to have access to the add-on until then. "))
                                            : _createCommentVNode("", true),
                                          _cache[59] || (_cache[59] = _createElementVNode("div", { class: "bg-white w-1/2 min-h-[25rem] rounded-[0.938rem] p-6 shadow overflow-auto relative" }, [
                                            _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " TABLE RESERVATION "),
                                            _createElementVNode("p", { class: "mb-4" }, " The addon is active. You can now access it when you connect to the box. ")
                                          ], -1)),
                                          _createElementVNode("div", _hoisted_97, [
                                            (_ctx.loading)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_98))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_99, [
                                          _createVNode(_component_activate_add_on, {
                                            noActiveAddOnTitle: "Table Reservation Add-On Inactive",
                                            noActiveAddOnMessage: "The Table Reservation Add-On enhances your box's capabilities by enabling seamless management of table reservations directly through the captive portal. Whether you run a restaurant, coffee shop, bar, or similar establishment, this feature empowers your waitstaff to efficiently handle table assignments and reservations.",
                                            processingAddOnActivation: _ctx.processingAddOnActivation,
                                            price: 20000,
                                            onActivateAddOn: _ctx.activateTableAddOn
                                          }, null, 8, ["processingAddOnActivation", "onActivateAddOn"])
                                        ]))
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]))
                  ])
                ]))
        ])),
    (_ctx.showUploadMenuModal)
      ? (_openBlock(), _createBlock(_component_upload_menu, {
          key: 2,
          onClose: _cache[24] || (_cache[24] = ($event: any) => (_ctx.closeModal()))
        }))
      : _createCommentVNode("", true),
    (_ctx.showSMSPaymentModal)
      ? (_openBlock(), _createBlock(_component_sms_payment_modal, {
          key: 3,
          loading: _ctx.processingAddOnActivation,
          smsPackage: _ctx.selectedSMSPackage,
          onPayForSms: _ctx.activateSMSPackage,
          onClose: _cache[25] || (_cache[25] = ($event: any) => (_ctx.showSMSPaymentModal = !_ctx.showSMSPaymentModal))
        }, null, 8, ["loading", "smsPackage", "onPayForSms"]))
      : _createCommentVNode("", true),
    (_ctx.showEulaModal)
      ? (_openBlock(), _createBlock(_component_ared_eula_modal, {
          key: 4,
          loading: _ctx.processingEuala,
          onAccept: _ctx.acceptEula,
          onClose: _cache[26] || (_cache[26] = ($event: any) => (_ctx.showEulaModal = !_ctx.showEulaModal))
        }, null, 8, ["loading", "onAccept"]))
      : _createCommentVNode("", true),
    (_ctx.addOnToDeactivate === 'menu')
      ? (_openBlock(), _createBlock(_component_confirmation_dialog, {
          key: 5,
          show: _ctx.showCancellationDialog,
          title: "Menu Add-On Deactivation",
          message: "\nDisabling the Menu Add-On will result in the removal of the food and drinks menu from the captive portal. \nCurrently, the menu addon enables your clients to conveniently access your menu directly from their phone or computer when they connect to your network. Please note that this change will take effect after the conclusion of your current billing cycle.\n Therefore, you will retain access until the end of the current billing period.",
          actionLabel: "Confirm",
          loading: _ctx.loadingCancellation,
          onCancel: _cache[27] || (_cache[27] = ($event: any) => (_ctx.toggleCancellationDialog(false))),
          onConfirm: _cache[28] || (_cache[28] = ($event: any) => (_ctx.requestForAddOnDeactivation()))
        }, null, 8, ["show", "loading"]))
      : _createCommentVNode("", true),
    (_ctx.addOnToDeactivate === 'feedback')
      ? (_openBlock(), _createBlock(_component_confirmation_dialog, {
          key: 6,
          show: _ctx.showCancellationDialog,
          title: "Feedback Add-On Deactivation",
          message: "\n    Disabling the Feedback Add-On will result in the removal of the feedback page from your captive portal.\n     Currently, the feedback addon empowers your clients to easily submit feedback and ratings directly from their phone or computer when they connect to your network.\n      Please be aware that this change will come into effect at the conclusion of your current billing cycle. Therefore, you will continue to have access to the feedback feature until the end of the current billing period.\n    ",
          actionLabel: "Confirm",
          loading: _ctx.loadingCancellation,
          onCancel: _cache[29] || (_cache[29] = ($event: any) => (_ctx.toggleCancellationDialog(false))),
          onConfirm: _cache[30] || (_cache[30] = ($event: any) => (_ctx.requestForAddOnDeactivation()))
        }, null, 8, ["show", "loading"]))
      : _createCommentVNode("", true),
    (_ctx.addOnToDeactivate === 'table')
      ? (_openBlock(), _createBlock(_component_confirmation_dialog, {
          key: 7,
          show: _ctx.showCancellationDialog,
          title: "Table Reservation Add-On Deactivation",
          message: "\n    Disabling the Table Reservation Add-On will result in the removal of the table reservation management functionality\n     from your captive portal. Currently, the reservation addon enables your staff to efficiently handle table assignments\n      and reservations directly through the captive portal. Please be aware that this change will come into effect at the conclusion of your current billing cycle. Therefore, you will continue to have access to the table reservation feature until the end of the current billing period.\n    ",
          actionLabel: "Confirm",
          loading: _ctx.loadingCancellation,
          onCancel: _cache[31] || (_cache[31] = ($event: any) => (_ctx.toggleCancellationDialog(false))),
          onConfirm: _cache[32] || (_cache[32] = ($event: any) => (_ctx.requestForAddOnDeactivation()))
        }, null, 8, ["show", "loading"]))
      : _createCommentVNode("", true)
  ], 64))
}