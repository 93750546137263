<template>
  <div class="flex justify-center items-center bg-gray-100">
    <div class="max-w-lg p-6 bg-white rounded-lg shadow">
      <h2 class="text-2xl font-bold mb-4 text-[#f24236]">Suspended Access</h2>
      <p class="text-gray-600 mb-4">
        You cannot access this page because your partner suspended access to it.
        Contact your partner for more information
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SuspendedPageMessage',
});
</script>

<style>
</style>
