import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

import store from '../store/index';
import LoginPage from '../views/auth/Login.vue';
import LoginPageOTP from '../views/auth/LoginOTP.vue';
import SignupPage from '../views/auth/Signup.vue';
import ForgotPasswordPage from '../views/auth/ForgotPassword.vue';
import ChangePasswordPage from '../views/auth/ChangePassword.vue';
import PasswordResetPage from '../views/auth/PasswordReset.vue';
import DashboardPage from '../views/dashboard/Dashboard.vue';
import AccountInfoPage from '../views/Account/account-info.vue';
import VouchersPage from '../views/Vouchers/vouchers.vue';
import CaptivePortalPage from '../views/captive/captive-portal.vue';
import FileMediaPage from '../views/File/file-media.vue';
import SupportTicketsPage from '../views/support/support-tickets.vue';
import BillingsPage from '../views/billings/billings.vue';
import HomePage from '../views/home/home.vue';
import AdvertsPage from '../views/Content/Advert.vue';
import AdvertDetailsPage from '../views/Content/AdvertDetail.vue';
import AdvertAddEditPage from '../views/Content/AddEditAdvert.vue';
import SystemUsersPage from '../views/Account/SystemUsers.vue';
import UserDetailsPage from '../views/Account/UserDetail.vue';
import UserAddEdit from '../views/Account/UserAddEditPage.vue';
import ShirikiEye from '@/views/Shiriki/Eye.vue';
import ShirikiFile from '@/views/Shiriki/File.vue';
import ShirikiAddOn from '@/views/Shiriki/AddOn.vue';

const title = 'Ared SME';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: {
      title: `${title} - Login`,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: LoginPageOTP,
    meta: {
      title: `${title} - OTP`,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: {
      title: `${title} - SIGNUP`,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordPage,
    meta: {
      title: `${title} - SIGNUP`,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordPage,
    meta: {
      title: `${title} - Change Password`,
      requireAuth: true,
    },
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: PasswordResetPage,
    meta: {
      title: `${title} - Password Reset`,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomePage,
    meta: {
      title: `${title} - Dashboard`,
      requireAuth: true,
    },
  },
  {
    path: '/routers',
    name: 'routers',
    component: DashboardPage,
    meta: {
      title: `${title} - Routers`,
      requireAuth: true,
    },
  },
  {
    path: '/account-info',
    name: 'account-info',
    component: AccountInfoPage,
    meta: {
      title: `${title} - AccountInfo`,
      requireAuth: true,
    },
  },
  {
    path: '/system-users',
    name: 'system-users',
    component: SystemUsersPage,
    meta: {
      title: `${title} - SystemUsers`,
      requireAuth: true,
    },
  },
  {
    path: '/user-detail/:id',
    name: 'user-details',
    component: UserDetailsPage,
    meta: {
      title: `${title} - UserDetails`,
      requireAuth: true,
    },
  },
  {
    path: '/user-add',
    name: 'user-add',
    component: UserAddEdit,
    meta: {
      title: `${title} - UserAdd`,
      requireAuth: true,
    },
  },
  {
    path: '/user-add-edit/:id',
    name: 'user-edit',
    component: UserAddEdit,
    meta: {
      title: `${title} - UserEdit`,
      requireAuth: true,
    },
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: VouchersPage,
    meta: {
      title: `${title} - Vouchers`,
      requireAuth: true,
    },
  },
  {
    path: '/captive-portal',
    name: 'captive-portal',
    component: CaptivePortalPage,
    meta: {
      title: `${title} - CaptivePortal`,
      requireAuth: true,
    },
  },
  {
    path: '/adverts',
    name: 'adverts',
    component: AdvertsPage,
    meta: {
      title: `${title} - Advert`,
      requireAuth: true,
    },
  },
  {
    path: '/advert-details/:id',
    name: 'advert-details',
    component: AdvertDetailsPage,
    meta: {
      title: `${title} - Advert Details`,
      requireAuth: true,
    },
  },
  {
    path: '/edit-advert/:id',
    name: 'edit-advert',
    component: AdvertAddEditPage,
    meta: {
      title: `${title} - Advert`,
      requireAuth: true,
    },
  },
  {
    path: '/add-advert/',
    name: 'add-advert',
    component: AdvertAddEditPage,
    meta: {
      title: `${title} - Advert`,
      requireAuth: true,
    },
  },
  {
    path: '/offline-files',
    name: 'offline-files',
    component: FileMediaPage,
    meta: {
      title: `${title} - OfflineMediaFile`,
      requireAuth: true,
    },
  },
  {
    path: '/support-tickets',
    name: 'support-tickets',
    component: SupportTicketsPage,
    meta: {
      title: `${title} - SupportTickets`,
      requireAuth: true,
    },
  },
  { 
    path: '/billings',
    name: 'billings',
    component: BillingsPage,
    meta: {
      title: `${title} - Billings`,
      requireAuth: true,
    },
  },
  {
    path: '/shiriki-eye',
    name: 'shiriki-eye',
    component: ShirikiEye,
    meta: {
      title: `${title} - Shiriki Eye`,
      requireAuth: true,
    },
  },
  {
    path: '/shiriki-file',
    name: 'shiriki-file',
    component: ShirikiFile,
    meta: {
      title: `${title} - Shiriki File`,
      requireAuth: true,
    },
  },
  {
    path: '/shiriki-add-on',
    name: 'shiriki-add-on',
    component: ShirikiAddOn,
    meta: {
      title: `${title} - Shiriki Add-On`,
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const titleFromParams = to.params?.pageTitle;
  const isAuthenticated = store.getters.isAuthenticated;

  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  } else {
    document.title = (to.meta?.title as string) ?? title;
  }

  if (
    to.matched.some((record) => record.meta.requireAuth) &&
    !isAuthenticated
  ) {
    store.commit('setLoginBtn', { text: 'Log In' });
    return next('/');
  }

  if (
    (to.path === '/' ||
      to.path === '/signup' ||
      to.path === '/forgot-password') &&
    isAuthenticated
  ) {
    return next('/dashboard');
  }

  next();
});

export default router;

export function useRoute(): RouteLocationNormalizedLoaded {
  return router.currentRoute.value;
}